import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useUser } from '../hooks/useUser';

function UserRestrictedRoute() {
  // Assume we have a function that checks if the user is logged in
  const {user} = useUser()


  return (
    user ?  <Outlet/> : <Navigate to="/login" />
    // <Navigate to="/" />
  );
}

export default UserRestrictedRoute;