import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';

export const useCurrentUser= () => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useUser();

  useEffect(() => {

    if (!user) {
      setError('User not logged in');
      return;
    }

    else {

      setLoading(true);
      axios
        .get('/api/authenticate/current_user/', {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        })
        .then(response => {
          if (!response.ok) {
            setError(response.statusText);
          }
          return response.data;
        })
        .then(data => {
          setCurrentUser(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, []);

  return { currentUser, loading, error };
};
