import React, { useEffect } from 'react';
import styles from './styles/Login.module.css';
import { Link, Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { useState } from 'react';
import { useLogin } from '../hooks/useLogin';
import { useUser } from '../hooks/useUser';
import LoginRegister from '../components/LoginRegister';
import { useProfile } from '../hooks/useProfile';
const Loginpage = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    // password2: '',
    emailtype: '@iitb.ac.in',
  });

  const { login, error, isLoading } = useLogin();
  const { user } = useUser();

  // const passwordcheck = credentials['password'] === credentials['password2'];

  function handleChange(e) {
    var { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await login(
      credentials['email'] + credentials['emailtype'],
      credentials['password'],
    );
    if(error !== null){
      window.location.reload();
    }
  }
  if (user) {
    return <Navigate to="/feed" />;
  } else {
    return (
      <>
        {!isLoading && (
          <div className={styles.logbg}>
            <p className={styles.welcome}>SARC PRESENTS</p>
            <h1 className={styles.year}>Yearbook</h1>
          <p style={{color: "white", margin: "0.2%"}}>Login page</p>
            <LoginRegister
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              error={error}
              passwordcheck={true}
              isLoading={isLoading}
              islogin={true}
            />
          </div>
        )}
      </>
    );
  }
};

export default Loginpage;
