import React from 'react';
import styles from './styles/Faq.module.css';
const Faq = () => {
  return (
    <>
      <div className={styles.faqContainer}>
        <p className={styles.head}>FAQs</p>
        <details>
          <summary className={styles.summary}>What is the concept of the YB portal?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Yearbook is developed by the Student Alumni Relations Cell to provide you with a platform to reflect on your college journey and cherish the memories you have made with your friends and our beloved insti which will stay with you forever after you graduate. The portal consists of the following major components:
              1.	Feed: Where you can read all the posts written by you and others
              2.	Snapshots: Where you can see and upload pictures which capture the memorable moments of your insti life
              3.	Polls: Where you can share your opinion by voting on different polls related to insti
              4.	Profile: Or your YB wall containing all details about you, a small gallery section and posts written for and by you

            </p>
          </div>
        </details>
        <details>
          <summary className={styles.summary}>Is the Yearbook only relevant to the graduating junta?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              NO, All IITB students and alumni are invited to Yearbook 2024 to write and share the memories they have with their seniors/batchmates/juniors in the graduating batch of 2024
            </p>
          </div>
        </details>{' '}
        <details>
          <summary className={styles.summary}>How do I register and create my Yearbook profile?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Signup on the portal using your roll number and password and click on verify. On verifying your account by clicking on the link sent to you on webmail, you will be able to log in using your roll number and password. Once logged in, you will be redirected to complete your profile. You will be able to access other pages once you complete your profile. <br></br>For alumni, select iitbombay.org from the dropdown in the sign up window and follow the same procedure to login


            </p>
          </div>
        </details>{' '}
        <details>
          <summary className={styles.summary}>How can I view anyone’s profile?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Search for the person through the search bar to access his/her profile. You can also choose to write a post for that person.
            </p>
          </div>
        </details>{' '}
        <details>
          <summary className={styles.summary}>Can I write for someone anonymously?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Yes, select the ‘Post Anonymously’ checkbox from the Write Post dialog box to write for someone anonymously
            </p>
          </div>
        </details>{' '}
        <details>
          <summary className={styles.summary}>How many times can I write for someone?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              You can write as many times posts as you want for someone by just choosing edit the post and even delete it.


            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>Are there any guidelines regarding the content of the post?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Your post has a characters limit of 2000 characters. Please refrain from using offensive or derogatory language in your posts. They will be censored in case you do.
            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>Can I tag my friends in a post/snapshot?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Yes, you can tag your friends in your post or the caption and comment of a snapshot by typing @ and choosing his/her name from the list that pops up
            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>How do filters work?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              There are 3 multiple choice filters. Let’s say that you want to see news-feed pertaining to friends from HSS and Mechanical, so you should select both of them.
              But if you want only those from your hostel (eg - Hostel 3), then select Hostel 3 in the Join Year and you’ll see posts for/by students from both HSS and Mechanical but limited to only those who are in Hostel 3

            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}> Can I vote for multiple choices in a poll?</summary>
          <div className="faq__content">
            <p className={styles.para}>
             Yes
            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>How do I view who liked a post?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Hover on the like button in any post/snapshot to see who liked it
            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>What is the concept of personalised feed?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Your personalised feed is created by filtering the posts that are written by or written for your friends who are engaged with you on the YB portal in some way
            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>What notifications do I receive?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              You will receive a notification everytime someone posts for you, likes your post, comments on your snapshot, likes your comment, tags you in a post/snapshot
            </p>
          </div>
        </details>{''}
        <details>
          <summary className={styles.summary}>Whom to contact in case of any queries?</summary>
          <div className="faq__content">
            <p className={styles.para}>
              Visit the Contacts page and scroll to the bottom to find relevant contacts


            </p>
          </div>
        </details>{''}

        
      </div>
    </>
  );
};

export default Faq;
