import React from 'react';
import styles from './styles/Login.module.css';
import { Link, Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { useState } from 'react';
import { useSignup } from '../hooks/useSignup';
import { useUser } from '../hooks/useUser';
import LoginRegister from '../components/LoginRegister';
const Loginpage = () => {
  const [Loadind, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    password2: '',
    emailtype: '@iitb.ac.in',
  });
  const { signup, error, isLoading } = useSignup();
  const { user } = useUser();

  const passwordcheck = credentials['password'] === credentials['password2'];

  function handleChange(e) {
    var { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    await signup(
      credentials['email'] + credentials['emailtype'],
      credentials['password'],
    );
    setLoading(false);
  }

  if (user) {
    return <Navigate to="/feed" />;
  } else {
    return (
      <>
        <div className={styles.logbg}>
          <p className={styles.welcome}>SARC PRESENTS</p>
          <h1 className={styles.year}>Yearbook</h1>
          <p style={{color: "white", margin: "0.2%"}}>Signup page</p>
          <LoginRegister
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            passwordcheck={passwordcheck}
            isLoading={isLoading}
            islogin={false}
          />
        </div>
      </>
    );
  }
};

export default Loginpage;
