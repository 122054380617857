import { useState } from 'react';
import { FcLike, FcLikePlaceholder } from 'react-icons/fc';

const CommentFooter = ({ likes, likeComment, currentUserID, likedBy }) => {
  const [likedOrNot, setLikedOrNot] = useState(
    likedBy.filter(like => like === currentUserID).length !== 0,
  );

  function handleLike() {
    setLikedOrNot(!likedOrNot);
    likeComment();
  }

  return (
    <div className="comment--footer">
      <button className="like-btn" onClick={handleLike}>
        {' '}
        {likedOrNot ? (
          <FcLike className="iconsfc" />
        ) : (
          <FcLikePlaceholder className="iconsfc" />
        )}{' '}
        {likes}
      </button>
    </div>
  );
};

export default CommentFooter;
