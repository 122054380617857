import React, { useEffect } from 'react';
import styles from './styles/Login.module.css';
import { Link, Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { useState } from 'react';
import { useLogin } from '../hooks/useLogin';
import LoginRegister from '../components/LoginRegister';
import { useProfile } from '../hooks/useProfile';
import styles2 from '../components/styles/LoginSignup.module.css';
import { useForgetPasswordEmail } from '../hooks/useForgetPasswordEmail';

const SendForgetPasswordEmail = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    emailtype: '@iitb.ac.in',
  });

  const { sendEmail, error, isLoading } = useForgetPasswordEmail();

  function handleChange(e) {
    var { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await sendEmail(credentials['email'] + credentials['emailtype']);
  }

  return (
    <>
      {!isLoading && (
        <div className={styles.logbg}>
          <p className={styles.welcome}>SARC PRESENTS</p>
          <h1 className={styles.year}>Yearbook</h1>
          <p style={{ color: 'white', margin: '0.2%' }}>Forget Password</p>
          <div>
            <form className={styles2.form} onSubmit={handleSubmit}>
              <div className={styles2.inputsetspecial}>
                <input
                  name="email"
                  type="text"
                  className={`${styles2.inp} ${styles2.email}`}
                  placeholder="Roll Number "
                  onChange={handleChange}></input>
                <select
                  name="emailtype"
                  id="emailtype"
                  className={`${styles2.emailtype} ${styles2.selectWrapper}`}
                  onChange={handleChange}>
                  <option value="@iitb.ac.in" default>
                    @iitb.ac.in
                  </option>
                  <option value="@iitbombay.org">@iitbombay.org</option>
                </select>
              </div>

              <div className={styles2.inputset}>
                {/* <button type='submit' className={styles2.btn} onClick={(e) => {e.preventDefault();}}>Verify</button> */}
                {
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={styles2.Btn}>
                    Send Email
                  </button>
                }
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SendForgetPasswordEmail;