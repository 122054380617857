import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';
import {
  updatePost,
  updatePostRequest,
  updatePostFailure,
} from '../store/actions/PostActions';
import { useDispatch } from 'react-redux';
var profanity = require('profanity-hindi');

export const useUpdatePost = (post) => {
  console.log('In update Posts');
  const { user } = useUser();
  const dispatch = useDispatch();
  const [textContent, setTextContent] = useState(post.content);
  const [isAnonymous, setIsAnonymous] = useState(post.is_anonymous);
  
  const handleCheckboxChange = e => {
    setIsAnonymous(e.target.checked) 
    console.log(isAnonymous, "this one is triggered")
  }

  const handleChange = value => {
    setTextContent(value)
  };

  const handleSubmit = async e => {
    console.log(e)
  
    // e.preventDefault();
    dispatch(updatePostRequest());
    // dispatch(uploadPostRequest());

    // if(textContent){
    //   var masking = profanity.maskBadWords(textContent);
    // }else{
    //   masking = ' ';
    // }


    try {
      const response = await axios.put(
        `/api/posts/${post.id}/`,
        {
          written_for: Number(post.written_for_id),
          content: textContent,
          is_anonymous: Boolean(isAnonymous)
        },
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
            'Content-Type': 'application/json',
          },
        },
      );
      console.log(response.data)
      dispatch(updatePost(response.data));
    } catch (error) {
      console.log(error);
      dispatch(updatePostFailure(error.message));
    }
  };

  return { textContent, handleChange, handleSubmit, isAnonymous, handleCheckboxChange };
};
