const deletePost = {
  post_id: 0,
  loading: false,
  error: null,
};

const deletePostReducer = (state = deletePost, action) => {
  switch (action.type) {
    case 'DELETE_POST_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'DELETE_POST':
      return {
        ...state,
        loading: false,
        error: null,
      };
    case 'DELETE_POST_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default deletePostReducer;
