import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';

export const usePolls = () => {
  const [polls, setPolls] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    if (!user) {
      return;
    }
console.log(user.access)
      axios
        .get('/api/polls/', {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        })
        .then(data => {
          setPolls(data.data);
          console.log(data.data)
        })
        .catch(error => {
          console.log(error)
        });
    
  }, []);

  return { polls };
};
