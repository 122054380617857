import react, { useEffect, useState } from 'react';
import styles from '../styles/PopularUsers.module.css';
import { useUser } from '../../hooks/useUser';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useProfile } from '../../hooks/useProfile';
import verified from '../../assets/verified.png'


function PopularUsers() {
  const [popularUsers, setPopularUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { user } = useUser();

  const userProfileData = useProfile();

  if (userProfileData && userProfileData.profile) {
    var profile = userProfileData.profile;
  }

  useEffect(() => {
    // console.log('Popular users loaded');
    if (!user) {
      setError('User not logged in');
      return;
    }
    // console.log('Popular users');
    if (popularUsers) {
      // console.log('Hi');
      setPopularUsers(popularUsers);
      return;
    } else {
      setLoading(true);
      axios
        .get('/api/Cors/PopularUsers/', {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        })
        .then(response => {
          // console.log(response, 'response here');
          return response.data;
        })
        .then(data => {
          // console.log(data, 'data from backend');
          setPopularUsers(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [popularUsers]);

  // console.log(popularUsers, 'working here');

  if (popularUsers === undefined) {
    return (
      <div className={styles.loading}>
        <h1>Loading...</h1>
      </div>
    );
  }

  if (profile === undefined) {
    return (
      <div className={styles.loading}>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <h3
          style={{
            fontSize: '20px',
            textAlign: 'center',
            marginTop: '10%',
            color: '#865DFF',
          }}
        >
          Popular Users
        </h3>
        <div className={styles.PostHeaderLeft}>
          <ul className={styles.userContainer}>
            {popularUsers.map(user => {
              return (
                <li className={styles.userListLi}>
                  <Link
                    to={
                      user.user !== profile.user
                        ? `/profile/${user.user}`
                        : '/profile'
                    }
                    className={styles.containUser}
                  >
                    <img
                      src={
                        'https://yearbook.sarc-iitb.org' +
                        user.profile_image
                      }
                      alt="profile pic"
                      className={styles.ProfilePic}
                    />

                    <div className={styles.PostHeaderLeftText}>
                      <h3 style={{ color: 'white', display: "flex", alignItems: "center" }}>{user.name}{user.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}</h3>
                      {!user.is_ib && <p style={{ color: '#865DFF' }}>{user.department}</p>}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default PopularUsers;
