import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MentionsInput, Mention } from 'react-mentions';
import { useUser } from '../../hooks/useUser';
import DisplayImpression from './DisplayImpression';
import FeedContainer from './FeedContainer';
import '../styles/uploadImpression.css';
// function maskBadWords()
var profanity = require('profanity-hindi');

const UploadImpression = props => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [textValue, setTextValue] = useState('');
  const [Uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const { user } = useUser();

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileInputChange = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileContents = reader.result;
      setPreviewImage(fileContents);
      setSelectedFile(file);
    };
  };

  const [tagFlag, setTagFlag] = useState(false);
  const [query, setQuery] = useState('');
  const [tagindex, setTagIndex] = useState(0);

  const handleTextChange = async event => {
    // const value = event.target.value || '';
    // const inputValue = value === '@' ? '@' : value;
    // setTextValue(inputValue);
    setTextValue(event.target.value);
    // console.log("evbent", event.target.value)
    const regex = /@(\S+)/g;
    const matches = [...event.target.value.matchAll(regex)];
    const queries1 = matches.map(match => match[1]);
    const searchTerm = queries1[queries1.length - 1];
    console.log('queries1', queries1[queries1.length - 1]);
    console.log('Tag flag here', tagFlag);
    //Below code is for searching users using elastic search
    if (tagFlag) {
      try {
        console.log('searchTerm', searchTerm);
        console.log('In searchUsers');
        const response = await axios.post(
          'https://yearbook.sarc-iitb.org/api/search/search/',
          {
            query: {
              multi_match: {
                fields: ['name', 'hostel', 'department', 'degree', 'program'],
                query: searchTerm,
                fuzziness: 'AUTO',
                type: 'best_fields',
              },
            },
          },
        );
        setTimeout(() => {
          const foundUsers = response.data.hits.hits.map(hit => hit._source);
          console.log('foundUsers now', foundUsers);
          // setUsers(foundUsers);
          const test = [];
          for (let i = 0; i < foundUsers.length; i++) {
            console.log('foundUsers[i].name', foundUsers[i].id);
            test.push({
              id: foundUsers[i].id,
              display: foundUsers[i].name,
            });
          }
          console.log('test', test);
          if (test.length > 0) {
            setUsers(test);
          }
        }, 0);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    }
    if (tagFlag) {
      setQuery(
        event.target.value.substring(
          tagindex + 1,
          event.target.value.length - 1,
        ),
      );
      console.log('query', query);
    }
    if (event.target.value[event.target.value.length - 1] === ' ') {
      console.log('In space');
      setTimeout(() => {
        setTagFlag(false);
        setQuery('');
        setTagIndex(0);
      }, 0);
    }
    if (event.target.value[event.target.value.length - 1] === '@') {
      console.log('In @');
      setTimeout(() => {
        setTagFlag(true);
        setTagIndex(event.target.value.length - 1);
        setQuery(
          event.target.value.substring(
            tagindex + 1,
            event.target.value.length - 1,
          ),
        );
      }, 0);
    }
  };

  const [users, setUsers] = useState([
    {
      display: 'Please Wait, Results are being loaded!!',
      id: '1',
    },
  ]);

  useEffect(() => {
    {
      console.log('Triggerring FeedContainer');
    }
    // <FeedContainer key={reloadKey}/>
  }, [Uploaded]);

  const handleSubmit = event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('imgfile', selectedFile);
    if(textValue){
      formData.append('content', profanity.maskBadWords(textValue));
    }else{
      formData.append('content', ' ');
    }
    setLoading(true);
    setUploaded(false);

    // Make the API request
    axios
      .post('/api/impression/', formData, {
        headers: {
          Authorization: `Bearer ${user.access}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        // Request was successful
        setLoading(false);
        setUploaded(true);
        props.onChildStateChange(Uploaded);
        console.log(response.data);
      })
      .catch(error => {
        // An error occurred
        setLoading(false);
        console.error(error);
      });

    props.handleCloseButton();
  };

  function handleCloseButton() {
    props.handleCloseButton();
  }

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
        rel="stylesheet"
      />

      <form onSubmit={handleSubmit} className="main-container-background">
        <div className="upload-files-container">
          <button onClick={handleCloseButton} className="close-btnn">
            Close
          </button>
          <h2 className="uploadHeading">Upload Here</h2>
          {previewImage && (
            <>
              <div className="secondStep">
                <div className="image-container">
                  <img
                    style={{
                      // height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                    className="image"
                    src={previewImage}
                    alt=""
                  />
                </div>
                <div className="caption-other">
                  Immortalize your meme with a caption!
                  <div class="talkbubble">
                    {/* <textarea onChange={handleTextChange} placeholder="Write here. Use @ to tag people"></textarea> */}
                    <MentionsInput
                      placeholder="Use '@' for mention"
                      value={textValue}
                      markup="@__display__(__id__)"
                      onChange={handleTextChange}
                      style={{ color: 'green', height: '100%' }}
                      a11ySuggestionsListLabel={
                        'Choose amng the following suggestions'
                      }>
                      <Mention
                        style={{ color: 'black' }}
                        data={users}
                        markup="@__display__(__id__)"
                      />
                    </MentionsInput>
                  </div>
                  <div className="button-container">
                    {/* <button className="edit-delete-button giveLeftMargin">
                      Edit
                    </button> */}
                    <button
                      onClick={() => setPreviewImage(null)}
                      className="edit-delete-button">
                      Change Snapshot
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {!previewImage && (
            <div className="drag-file-area">
              <span className="material-icons-outlined upload-icon">
                file_upload
              </span>
              

              <label className="label">
                  <input
                    style={{display: "none"}}
                    onChange={handleFileInputChange}
                    type="file"
                    className="default-file-input"
                  />
                  <span  className="browse-files-text">Click here to browse file</span>
                  <span >from device</span>
              </label>
            </div>
          )}
          <span className="cannot-upload-message">
            {' '}
            <span className="material-icons-outlined">error</span> Please select
            a file first{' '}
            <span className="material-icons-outlined cancel-alert-button">
              cancel
            </span>{' '}
          </span>
          <div className="file-block">
            <div className="file-info">
              {' '}
              <span className="material-icons-outlined file-icon">
                description
              </span>{' '}
              <span className="file-name"> </span> |{' '}
              <span className="file-size"></span>{' '}
            </div>
            <span className="material-icons remove-file-icon">delete</span>
            <div className="progress-bar"> </div>
          </div>
          {previewImage && (
            <button type="submit" className="upload-button">
              {' '}
              Upload{' '}
            </button>
          )}
        </div>
      </form>

      {/* <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="file">Select a file:</label>
        <input type="file" id="file" onChange={handleFileChange} />
      </div>
      <div>
        <label htmlFor="text">Enter some text:</label>
        <input
          type="text"
          id="text"
          value={textValue}
          onChange={handleTextChange}
        />
      </div>
      <button type="submit">Upload</button>
    </form> */}
    </>
  );
};

export default UploadImpression;
