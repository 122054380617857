import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/FeedContainer.module.css';
import DisplayPost from './DisplayPost';
import styled from 'styled-components';
// import { usePosts } from '../../hooks/useFetchPosts';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUser } from '../../hooks/useUser';
import { useSelector } from 'react-redux';
import { useFetchPosts } from '../../hooks/useFetchPosts';
import { useScrollPosts } from '../../hooks/useScrollPosts';
import BounceLoader from 'react-spinners/BounceLoader';
import { useContext } from 'react';
import SortContext from '../../context/SortContext';
import SwitchButton from '../../pages/SwitchButton';
import PersonalFeedContainer from './personalFeedContainer';
import { Link } from 'react-router-dom';
export default FeedContainer;

function FeedContainer() {

  const buttonStyle = {
    borderRadius: '38px',
    backgroundColor: 'purple',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
  };

  const handleClick = () => {
    window.location.href = './personalFeedContainer';
  };

  // const [posts, setPosts] = useState([]);
  // const [page, setPage] = useState(1);
  // const { user } = useAuthContext();

  // console.log("posts");

  // const fetchPosts = () => {
  //   axios.get(`/api/allposts/?page=${page}`,
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${user.access}`
  //         }
  //       })
  //     .then(response => {
  //       setPosts(prevPosts => [...prevPosts, ...response.data]);
  //       setPage(page => page + 1);
  //     })
  //     .catch(error => console.log(error));
  // };

  // useEffect(() => {
  //   fetchPosts();
  // }, []);

  // Code using redux by Nikhil😎
  const { status, togglePlayPause, departments, hostels, clubs } =
    useContext(SortContext);
  useFetchPosts(status, departments, hostels, clubs);
  const { handleScroll } = useScrollPosts();
  const { posts, loading, error } = useSelector(state => state.posts);
  const [posts1, setPosts1] = useState(posts);
  const [uploaded, setUploaded] = useState(0);

  function handleChildStateChange() {
    setUploaded(uploaded + 1);
  }

  useEffect(() => {
    setPosts1(posts);
  }, [status, handleScroll, departments, hostels, clubs, uploaded]);

 

  return (

    <><div className={styles.FeedContainer} style={{width:"100% !important"}}>
        {/* <InfiniteScroll
    dataLength={posts.length} //This is important field to render the next data
    next = {useFetchPosts()}
    hasMore={true}
    loader={<h4>Loading...</h4>}
    endMessage={
  <p style={{ textAlign: 'center' }}>
    <b>Yay! You have seen it all</b>
  </p>
}
  >
    {/* {posts} */}
        {/* {posts.map(post => (
      <DisplayPost key={post.id} post={post} />
    ))}
  </InfiniteScroll> */}
        {/* <div className={styles.btnContainer1}>

          <SwitchButton switchFeed={switchFeed} buttonValue={!showInstiFeed ? "Go to Insti Feed" : "Go to Personalized Feed"} />
        </div> */}
      {/* <div className = {styles.feed_button} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
        <a href="./PersonalFeedContainer"  style={buttonStyle}>
         Go to personalized feed
        </a>
      </div> */}
        <div className={styles.Title}>
          <h2 style={{ zIndex: '0' }} data-text="INSTI FEED">
            INSTI FEED
        </h2>
        
          <div className={styles.line1}>
            The Insti feed section is a treasure trove of sentimental messages and
            hilarious photos that will take you on a journey down memory lane.
            Relive the precious moments of your college years, stay connected with
            your beloved batchmates, and share your own updates with ease. Don't
            let those memories fade away - with the Insti feed section, they'll
            stay alive and vibrant forever!
          </div>
        </div>

        <div>
          {
            loading && <div className={styles.loader}></div>
          }

          {/* {error && <div>{error}</div>} */}
          {/* {console.log(posts1)} */}
          {posts1 &&
            posts1.map(post => <>
              {/* <div id={post.id}> */}
              (
              <DisplayPost
                handleChildStateChange={handleChildStateChange}
                key={post.id}
                post={post} />
            )
              {/* </div> */}
            </>)}
          <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}>
          {!loading && <button onClick={handleScroll} className={styles.nextButton}>
            Next Page {'->'}
          </button>}
        </div>
        </div>
      </div></>
  );
}
