import React from 'react';
import styles from '../styles/RightBar.module.css';
import { FcLike } from 'react-icons/fc';
import { BiTime } from 'react-icons/bi';
import Filter from './Filter';
import PopularUsers from '../Feed/PopularUsers';


function RightBar(props) {
  return (
    <div className={styles.RightBar}>
      <div className={styles.SortWrapper}>
        <div className={styles.wrapperhead}>
          <h3>Sort By</h3>
        </div>
        <div>
          <div className={styles.choice} onClick={() => props.changeStatus(true)}>
            <FcLike size="20px" />
            Sort by Likes
          </div>
          <div className={styles.choice} onClick={() => props.changeStatus(false)}>
            <BiTime size="20px" />
            Sort by Time
          </div>
        </div>
      </div>
      {/* <Filter/> */}
      <PopularUsers/>
    </div>
  );
}

export default RightBar;
