import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { useProfile } from '../hooks/useProfile';

function ProfileRestrictedRoute() {
  // Assume we have a function that checks if the user is logged in
  const { user } = useUser();
  const { profile, loading, error } = useProfile();

  if (loading) {
    return <div>Loading...</div>; // Render a loading indicator while loading
  }

  const auth = user && profile;

  return auth ? <Outlet /> : <Navigate to="/editprofile" />;
  // return <Navigate to="/" />
}



export default ProfileRestrictedRoute;
