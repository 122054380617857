import React, { useState } from 'react';

import '../../pages/styles/UploadImpressionButton.css';
import RecommendedUsers from '../Feed/RecommendedUsers';
import PopularUsers from '../Feed/PopularUsers';


function UploadImpressionButton({handleUploadImpressionClick, buttonValue}) {

  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <>
   <div style={{height: "70px"}}>
        <button onClick={handleUploadImpressionClick} className="learn-more">
          <span className="circle" aria-hidden="true">
            <span className="icon arrow" />
          </span>
          <span className="buttontext"><span className='textbtn'>{buttonValue}</span></span>
          </button>

          {showPopup && (
            <div className="popup">
              <button className="close-button" onClick={closePopup}>
                Close
              </button>
              <div className="popup-content">
                <RecommendedUsers />
                <PopularUsers/>
              </div>
            </div>
          )}
          <button className="circular-button" onClick={handleButtonClick}>Users</button>
    
        
      </div>

    </>
  );
}

export default UploadImpressionButton;
