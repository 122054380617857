import React from 'react';
// import './js/Homepage.module';
import styles from './styles/Homepage.module.css';
import Loginbutton from '../components/mycomponents/Loginbutton';
import '@fontsource/baloo-bhai-2';
import '@fontsource/inter';
import Faq from '../components/mycomponents/Faq';
import Navbar from '../components/Nav1';
import mainBuilding from '../assets/mainbuilding.png';
import Footers from '../components/Footer';
import SignupButton from '../components/mycomponents/SignupButton';
import { useUser } from '../hooks/useUser';

const Homepage = () => {
  const user = useUser();
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.both}>
          <div className={styles.about}>
            <p className={styles.welcome}>Welcome To</p>
            <div className={styles.Title}>
              <h2 style={{ zIndex: "0" }} data-text="YEARBOOK">YEARBOOK</h2></div>
            <p className={styles.abouttext}>
{/*               As you reach the end of your college journey, we reflect on the
              memories you've made, the friendships you've forged, and the
              futures that lie ahead. Yearbook is the place where you can post
              crazy pictures, write interesting captions and generate creative
              polls for yourselves and your friends. You may be moving on to
              different paths, but the bonds you've formed will stay forever.
              Give a shot to cherishing these priceless bonds and reliving the
              most iconic days of your college life through this initiative of
              SARC, curated just for you! */}
              It's time to reflect on the cherished memories, lasting friendships
              , and exciting possibilities that await you in the future as your 
              time in college draws to an end. Yearbook is a platform where you 
              can share fun and crazy photos, come up with interesting captions,
              and create unique polls for you and your friends. As you embark on 
              new journeys, the connections you've developed will be everlasting. 
              Experience the joy of cherishing these invaluable connections and 
              reliving the unforgettable moments of your college life with this 
              exclusive initiative by SARC, designed especially for you!
            </p>
            {user.user!==null ? (<></>):(<><Loginbutton name="Login" /></>)}
            {user.user!==null ? (<></>):(<><SignupButton name="Signup"/></>)}
          </div>
          <div className={styles.aboutimg}>
            <img className={styles.images} src={mainBuilding}></img>
          </div>
        </div>

        <Faq />
        <Footers />
      </div>
    </>
  );
};

export default Homepage;
