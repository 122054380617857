import { combineReducers } from '@reduxjs/toolkit';
import postReducer from './PostReducer';
import uploadPostReducer from './uploadPostReducer';
import deletePostReducer from './deletePostReducer';
import likePostsReducer from './likePostReducer';
import scrollPostReducer from './ScrollPostReducer';
import personalizePostReducer from './personalizePost';
import updatePostReducer from './updatePostReducer';

const rootReducer = combineReducers({
  posts: postReducer,
  uploadPost: uploadPostReducer,
  deletePost: deletePostReducer,
  likesPost: likePostsReducer,
  scrollPosts: scrollPostReducer,
  personalizePost: personalizePostReducer,
  updatedPost: updatePostReducer,
});

export default rootReducer;
