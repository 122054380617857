import React, { createContext, useContext, useState } from 'react';

const NotifContext = createContext({
  status: false,
  togglePlayPause: () => {},
});

export const NotifProvider = (props) => {
  const [status, setStatus] = useState(false);
  const togglePlayPause = () => setStatus(status === true ? false : true);
  return (
    <NotifContext.Provider value={{
      status,
      togglePlayPause,
    }}>
      {props.children}
    </NotifContext.Provider>
  );
};

// const useNotifContext = () => {
//   const context = useContext(NotifContext);

//   if (context === undefined) {
//     throw new Error("useTheme must be used within a ThemeProvider");
//   }
//   return context;
// };

export default NotifContext;
