import './styles/SwitchButton.css';

function SwitchButton({buttonValue, switchFeed}) {
  return (
    <>

        <button onClick={switchFeed} className="learn-more">
          <span className="circle" aria-hidden="true">
            <span className="icon arrow" />
          </span>
          <span className="button-text"><span className="textbtn">{buttonValue}</span></span>
        </button>

    </>
  );
}

export default SwitchButton;
