import { useState } from 'react';
import { useLogin } from './useLogin';

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { login, e, l } = useLogin();

  const signup = async (email, password) => {
    setIsLoading(true);

    try {
      const response = await fetch('/api/authenticate/users/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, password: password }),
      });

      const json = await response.json();

      if (!response.ok) {
        setError(json.error);
      }
      if (response.ok) {
        setError('Verification Email Sent on'+{email}+', Please Check your WebMail');
      }
    } catch (e) {
      setError('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };
  return { signup, isLoading, error };
};
