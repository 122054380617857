import React, { useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/FeedContainer1.module.css';
import DisplayImpression from './DisplayImpression';
import styled from 'styled-components';
import { useImpressions } from '../../hooks/useImpressions';
import HeaderImpression from './HeaderImpression';
import { useState } from 'react';


function FeedContainer({sortStatus, handleChildStateChange, page, handlePageChange }) {
  const { impressions, loading, error } = useImpressions(page, sortStatus);
  // console.log("here are the impressions", impressions)
  // const { handleScroll } = useScrollImpressions();
  function handleScroll(){
    handlePageChange()
    handleChildStateChange();
  }
  // console.log('Impressions in feed container', impressions);
  // console.log(page, "page here")

  
  return (
    <div className={styles.FeedContainer}>
      <HeaderImpression />
      {loading && <div className={styles.loader}></div>}
      {/* {error && <div>{error}</div>} */}
      {impressions &&
        impressions.map(impression => (
          <DisplayImpression
            handleChildStateChange={handleChildStateChange}
            key={impression.id}
            impression={impression}
          />
        ))} 

        {!loading && <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}><button className={styles.nextButton} onClick={handleScroll}>Next Page {'->'}</button></div>}
    </div>
  );
}

export default FeedContainer;
