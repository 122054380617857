import React from 'react';

const DeleteMyData = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#333' }}>
      <div style={{ textAlign: 'center', color: 'white', fontFamily: 'Arial, sans-serif' }}>
        <p>To delete your data, contact:</p>
        <p>Akshat Jain: +91 7990565567</p>
        <p>Ashwani Dubey: +91 6263173987</p>
      </div>
    </div>
  );
};

export default DeleteMyData;
