import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from './useAuthContext';
import { useUser } from './useUser';
import { useSelector } from 'react-redux';
export const useImpressions = (page, sortStatus) => {
  const [impressions, setImpressions] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //   const { user } = useAuthContext();
  const { user } = useUser();
  console.log('In Impression hooks');
  useEffect(() => {
    // const posts = localStorage.getItem('posts');
    console.log('Impressions in hooks', impressions);
    if (!user) {
      setError('User not logged in');
      return;
    }
    console.log('User access', user.access);
    console.log(page, "this is the page nubmer")
    if (impressions) {
      console.log('Impressions already set');
      console.log('Set impressions', impressions);
      setImpressions(impressions);
      return;
    } else {
      console.log('Fetching Impressions');
      setLoading(true);
      axios
        .get('/api/impression/all/', {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
          params: {
            page: Number(page),
            status: Boolean(sortStatus),
          },
        })
        .then(response => {
          if (!response.ok) {
            setError(response.statusText);
          }
          //   console.log("Response User from API", response.data[0].user)
          console.log('Response from API', response);
          return response.data;
        })
        .then(data => {
          //   localStorage.setItem('impressions', JSON.stringify(data));
          console.log('setting Impressions');
          setImpressions(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [impressions, page]);
  return { impressions, loading, error };
};
