import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/FeedContainerPosts.module.css';
import DisplayPost from './DisplayPost';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUser } from '../../hooks/useUser';
import { useSelector } from 'react-redux';
import { useFetchOurPosts } from '../../hooks/useFetchOurPosts';
import { useScrollPosts } from '../../hooks/useScrollPosts';

export default OurPosts;

function OurPosts({userID}) {
    useFetchOurPosts(userID);
    const { handleScroll } = useScrollPosts();
    const { posts, loading, error } = useSelector(state => state.posts);


    if(posts === undefined) {
        return <><div class={styles.loader}></div></>
    }

    return (

        <div className={styles.FeedContainer}>
            {loading && <div class={styles.loader}></div>}
            {error && <div>{error}</div>}
            {/* {console.log(posts)} */}
            {posts && posts.map(post => {
                return <>{post.is_anonymous === true ? (<></>):(<DisplayPost key={post.id} post={post} testOurPosts={true} />)}</>
            })}
        </div>

    );
}
