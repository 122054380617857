import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './index.css';
import { PostsContextProvider } from './context/PostsContext';
import { AuthContextProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
// import './firebase-messaging-sw.js';
import axios from 'axios';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/reducers/rootReducer';
import { NotifProvider } from './context/NotifContext';
import { useUser } from './hooks/useUser';

// These configurations are taken from the firebase console and should be better put in .env file
// @author Nikhil TIwari😎
// @date 2021-04-27 01:00:00
// @description Firebase configurations- used for push notifications [Hard time consuming to figure out]
const user = JSON.parse(localStorage.getItem('user'));
if (user) {
  const firebaseConfig = {
    apiKey: 'AIzaSyBrMnVDTPiU3sEOiLsV_WZXpoMhzUZKo8I',
    authDomain: 'yearbook-a970a.firebaseapp.com',
    projectId: 'yearbook-a970a',
    storageBucket: 'yearbook-a970a.appspot.com',
    messagingSenderId: '310736371186',
    appId: '1:310736371186:web:6e0499fed67adc8c743f6b',
    measurementId: 'G-3ER3BGGFM4',
  };

  firebase.initializeApp(firebaseConfig);
  const messaging = firebase.messaging();
  // messaging
  //   .getToken()
  //   .then(token => {
  //     console.log('Token:', token);
  //     console.log('User is signed in ', user.acess);
  //     axios
  //       .post(
  //         // 'http://yearbook.sarc-iitb.org/api/notifications/subscribe/'
  //         '/api/notifications/subscribe/',
  //         { token },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user.access}`,
  //           },
  //         },
  //       )
  //       .then(response => {
  //         console.log('Token stored successfully:', response.data);
  //       })
  //       .catch(error => {
  //         console.log('Error storing token:', error);
  //       });
  //   })
  //   .catch(error => {
  //     console.log('Error getting token:', error);
  //   });

  messaging.onMessage(payload => {
    // console.log('Message received:', payload);
  });

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(registration => {
        console.log(
          'Service Worker registered with scope:',
          registration.scope,
        );
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  }
}
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <PostsContextProvider>
        <NotifProvider>
          <BrowserRouter>
            <Provider store={store}>
              <App />
            </Provider>
          </BrowserRouter>
        </NotifProvider>
      </PostsContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
);

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function () {
//     navigator.serviceWorker.register('./sw.js').then(
//       function (registration) {
//         Notification.requestPermission(function (result) {
//           console.log('User choice', result);
//           if (result !== 'granted') {
//             console.log('No notification permission granted!');
//           }
//         });
//         console.log(
//           'ServiceWorker registration successful with scope: ',
//           registration.scope,
//         );
//       },
//       function (err) {
//         // registration failed :(
//         console.log('ServiceWorker registration failed: ', err);
//       },
//     );
//   });
// }
