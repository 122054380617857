const updatedPost = {
    updatedPost: null,
    loading: false,
    error: null,
  };
  
  const updatePostReducer = (state = updatedPost, action) => {
    switch (action.type) {
      case 'UPDATE_POST_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'UPDATE_POST':
        return {
          ...state,
          updatedPost: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_POST_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default updatePostReducer;
  