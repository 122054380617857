import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';
import {
    fetchPosts,
    fetchPostsRequest,
    fetchPostsFailure,
    deletePost,
    likePost,
} from '../store/actions/PostActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const useFetchOthersPosts = (userID = null) => {
    const { post_id, loading, error } = useSelector(state => state.deletePost);
    const { info, loadingLikes, errorLikes } = useSelector(
        state => state.likesPost,
    );
    console.log(
        'page',
        useSelector(state => state.scrollPosts.page),
    );
    const { page } = useSelector(state => state.scrollPosts);
    console.log('The actual value and type of page', page, typeof page);
    const { user } = useUser();
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchPostsRequest());
            try {
                const response = await axios.get(
                    userID === null ? ('/api/posts/others/'):(`/api/posts/others/${userID}`),
                    {
                        headers: {
                            Authorization: `Bearer ${user.access}`,
                        },
                    },
                );
                dispatch(fetchPosts(response.data));
            } catch (error) {
                dispatch(fetchPostsFailure(error.message));
            }
        };

        fetchData();
    }, [dispatch, loading, info, page]);
};
