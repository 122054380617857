import { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../../../hooks/useUser';
import './Styles/AddComment.css';
import { useAnyUserProfile } from '../../../hooks/useAnyUserProfile';
import { useProfile } from '../../../hooks/useProfile';
import { MentionsInput, Mention } from 'react-mentions';

// import akash from './Assets/images/Naman_new.jpg'

const AddComment = ({
  currentUserID,
  handleAddComment,
  buttonValue,
  impressionId,
  loadComments,
  seeMore,
}) => {
  const [comment, setComment] = useState(null);
  const { user } = useUser();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  var profanity = require('profanity-hindi');
  useEffect(() => {
    console.log('Triggerring show Comment again');
  }, [success]);

  const currentUser = useProfile();

  if (currentUser && currentUser.profile) {
    var currentUserProfile = currentUser.profile;
  }

  const [textValue, setTextValue] = useState('');
  const [tagFlag, setTagFlag] = useState(false);
  const [query, setQuery] = useState('');
  const [tagindex, setTagIndex] = useState(0);

  const handleTextChange = async event => {
    // const value = event.target.value || '';
    // const inputValue = value === '@' ? '@' : value;
    // setTextValue(inputValue);
    setTextValue(event.target.value);
    // console.log("evbent", event.target.value)
    const regex = /@(\S+)/g;
    const matches = [...event.target.value.matchAll(regex)];
    const queries1 = matches.map(match => match[1]);
    const searchTerm = queries1[queries1.length - 1];
    console.log('queries1', queries1[queries1.length - 1]);
    console.log('Tag flag here', tagFlag);
    //Below code is for searching users using elastic search
    if (tagFlag) {
      console.log('searchTerm', searchTerm);
      console.log('In searchUsers');

      axios.post('https://yearbook.sarc-iitb.org/api/search/search/', {
        query: {
          multi_match: {
            fields: ['name', 'hostel', 'department', 'degree', 'program'],
            query: searchTerm,
            fuzziness: 'AUTO',
            type: 'best_fields',
          },
        },
      })
        .then(response => {
          const foundUsers = response.data.hits.hits.map(hit => hit._source);
          console.log('foundUsers now', foundUsers);

          const test = [];
          for (let i = 0; i < foundUsers.length; i++) {
            console.log('foundUsers[i].name', foundUsers[i].id);
            test.push({
              id: foundUsers[i].id,
              display: foundUsers[i].name,
              department: foundUsers[i].department,
              profile_image: foundUsers[i].profile_image,
              hostel: foundUsers[i].hostel,
            });
          }
          console.log('test', test);
          if (test.length > 0) {
            setUsers(test);
            console.log(test, 'here are the users');
          }
        })
        .catch(error => {
          console.error('Error searching users:', error);
        });

    }
    if (tagFlag) {
      setQuery(
        event.target.value.substring(
          tagindex + 1,
          event.target.value.length - 1,
        ),
      );
      console.log('query', query);
    }
    if (event.target.value[event.target.value.length - 1] === ' ') {
      console.log('In space');
      setTimeout(() => {
        setTagFlag(false);
        setQuery('');
        setTagIndex(0);
      }, 0);
    }
    if (event.target.value[event.target.value.length - 1] === '@') {
      console.log('In @');
      setTimeout(() => {
        setTagFlag(true);
        setTagIndex(event.target.value.length - 1);
        setQuery(
          event.target.value.substring(
            tagindex + 1,
            event.target.value.length - 1,
          ),
        );
      }, 0);
    }
  };

  const [users, setUsers] = useState([
    {
      display: 'Please Wait, Results are being loaded!!',
      id: '1',
    },
  ]);

  if (!currentUserProfile) {
    return <div></div>;
  }

  return (
    <>
      <form
        className="add-comment"
        onSubmit={e => {
          e.preventDefault();
          if (textValue) {
            setLoading(true);
            setSuccess(false);
            axios
              .post(
                '/api/comments/impression/' +
                impressionId +
                '/',
                {
                  text: profanity.maskBadWords(textValue),
                },
                {
                  headers: {
                    Authorization: `Bearer ${user.access}`,
                  },
                },
              )
              .then(response => {
                console.log('Response from Comments post API', response.data);
                setLoading(false);
                setSuccess(true);
                return response.data;
              })
              .then(data => {
                console.log('setting Impressions Comments');
                setSuccess(true);
                console.log('comment posted', data);
                setTextValue('');
                handleAddComment();
              })
              .catch(error => {
                console.log(error);
                setLoading(false);
                setError(error);
              });
          }
          if (!seeMore) {
            loadComments();
          }
        }}>
        <div className="profile-pic">
          <img
            className="profile-pic"
            src={'https://yearbook.sarc-iitb.org' + currentUserProfile.profile_image}
          />
        </div>

        <div
          className="comment-input" style={{ width: '100%' }}>
          <MentionsInput
            placeholder="Add a Comment (Use '@' for mention)"
            value={textValue}
            markup="@__display__(__id__)"
            className="comment-text"
            onChange={handleTextChange}
            style={{ color: '#865DFF', height: '100%' }}
            a11ySuggestionsListLabel={'Choose amng the following suggestions'}>
            <Mention
              style={{ color: "gray" }}
              data={users}
              markup="@__display__(__id__)"
            />
          </MentionsInput>
        </div>

        <button type="submit" className="add-btn">
          {buttonValue}
        </button>
      </form>
    </>
  );
};

export default AddComment;
