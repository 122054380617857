import { useState } from 'react';
import { ReactComponent as IconDelete } from './Assets/images/icon-delete.svg';
import axios from 'axios';
import { useUser } from '../../../hooks/useUser';
import './Styles/CommentHeader.css';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useProfile } from '../../../hooks/useProfile';
import Swal from 'sweetalert2';
import verified from '../../../assets/verified.png'

const CommentHeader = ({handleDeleteComment, comment }) => {
  const [error, setError] = useState(null);
  const { user } = useUser();

  const {profile, loading, errorr} = useProfile()
  
  const currentProfile = useProfile()
  const currentUserID = currentProfile.profile.user;


  const creationDate = new Date(comment.created_at);
  const currentDate = new Date();
  const timeDifference = currentDate - creationDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days/31);

  if(seconds < 60){
    var showTime = seconds + " Seconds ago";
  }

  else if(minutes < 60){
    var showTime = minutes + (minutes == 1 ? (" Minute ago"): (" Minutes ago"));
  }

  else if(hours < 24){
    var showTime = hours + (hours == 1 ? (" hour ago"): (" Hours ago"));
  }

  else if(days < 32){
    var showTime = days + (days == 1 ? (" Day ago"): (" Days ago"));
  }

  else if(months < 12){
    var showTime = months + (months == 1 ? (" Month ago"): (" Months ago"));
  }

  const deleteComment = comment_id => {
    axios
      .delete(
        '/api/comments/delete_impression_comment/' +
          comment_id +
          '/',
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        },
      )
      .then(response => {
        console.log('Response from Delete API', response);
        setError(response.data['error']);
        return response.data
      })
      .then(data => {
        console.log(data)
        handleDeleteComment()
      })
      .catch(error => {
        console.log(error);      
      });
  };


  function handleDeleteClicked(id) {
    Swal.fire({
      title: 'Are you sure to delete this Comment?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      heightAuto: 'false',
    }).then(result => {
      if (result.isConfirmed) {
        deleteComment(id)
        Swal.fire('Deleted!', 'Your Comment has been deleted.', 'success');
      }
    });
  }


  return (
    <div className="comment--header">
      <div className="profileContainer">
          <img
            className="Profilepic"
            src={'https://yearbook.sarc-iitb.org' + comment.written_by.profile_image}
            alt="profilepic"
          />
        <p style={{display: 'flex', alignItems: "center"}} className="commenterName">{comment.written_by.name}{comment.written_by.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}</p>
        <p className="commentTime">{showTime}</p>
      </div>
      {currentUserID === comment.written_by.user && (
        <button
          onClick={() => handleDeleteClicked(comment.id)}
          className="delete-btn">
          <IconDelete />
        </button>
      )}
    </div>
  );
};

export default CommentHeader;
