import React, { useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/FeedContainerImpression.module.css';
import DisplayImpression from './DisplayImpression';
import styled from 'styled-components';
import { useImpressions } from '../../hooks/useMyImpressions';
import HeaderImpression from './HeaderImpression';
function useMyImpressions({ handleChildStateChange, userID }) {
    const { impressions, loading, error } = useImpressions(userID);
    console.log('Impressions in feed container', impressions);

    if(impressions === undefined) {
        return <><div class={styles.loader}></div></>
    }

    return (
        <div className={styles.FeedContainer}>
            
            {loading && <div className={styles.loader}></div>}
      {/* {error && <div>{error}</div>} */}
            {impressions &&
                impressions.map(impression => (
                    <DisplayImpression handleChildStateChange={handleChildStateChange} key={impression.id} impression={impression} />
                ))}
        </div>
    );
}

export default useMyImpressions;