import { useState } from 'react';
import { useProfile } from './useProfile';

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const login = async (username, password) => {
    setIsLoading(true);
    try{
      console.log(username)
      const response = await fetch('/api/authenticate/token/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password }),
      });

  
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.detail);
      }
      if (response.ok) {
        localStorage.setItem('user', JSON.stringify(json));
        
      }
      
    }
    catch(e){
      console.log(e);
      setError(e.message);
    }
    finally{
      setIsLoading(false);
    }
    
  };
  return { login, isLoading, error };
};
