import React from 'react';
import { useSelector } from 'react-redux';
import {
  LikePost,
  LikePostRequest,
  LikePostFailure,
} from '../store/actions/PostActions';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useUser } from './useUser';

export const useLikePostsContext = () => {
  const { user } = useUser();
  const dispatch = useDispatch();

  const handleLike = async post_id => {
    dispatch(LikePostRequest());
    try {
      const response = await axios.post(
        `/api/like/${post_id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        },
      );
      console.log('like response data', response.data);
      dispatch(LikePost(response.data));
    } catch (error) {
      console.log(error);
      dispatch(LikePostFailure(error.message));
    }
  };

  return { handleLike };
};

// export default useLikePostsContext;
