import React from 'react'
import styles from './styles/Devteam1.module.css'
import { FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { FaGithub } from "react-icons/fa"
import { FaFacebook } from "react-icons/fa"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { useContext } from 'react';
import NotifContext from '../context/NotifContext'
import styleNotif from './styles/Feed.module.css'
import NotifContainer from '../components/Notifs/NotifContainer'
import Footers from '../components/Footer';

function Devteam()
{
  const { status, togglePlayPause } = useContext(NotifContext);
  console.log(status)
    return (
        <><div className={styles.meetteam}>
    <div className={status? styleNotif.active: styleNotif.notactive}>
      {/* <NotifContainer /> */}
    </div>
    <div className={styles.teamTitle}>
            <h2 data-text="MEET OUR TEAM">MEET OUR TEAM</h2>
            <div className={styles.line1}>Meet our Dedicated Team working tirelessly to reminisce about your college memories!</div>
        </div>
        


        
        <div className={styles.container1}>
  <div className="row">
      <div className="col-12 col-lg-6">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.oc1}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
          <p>Prerna Agarwal</p>
            <span>SARC Overall Co-ordinator, 2023-24</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://www.linkedin.com/in/prerna-agrawal-b57818221/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/prernagrawal_07/"><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
          <p>Prerna Agarwal</p>
            <span>SARC Overall Co-ordinator, 2023-24</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://www.linkedin.com/in/prerna-agrawal-b57818221/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/prernagrawal_07/"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="col-12 col-lg-6">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.oc2}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
          <p>Aastha Patel</p>
            <span>SARC Overall Co-ordinator, 2023-24</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://www.linkedin.com/in/aastha-patel-048609207"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/12_khushii/"><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
          <p>Aastha Patel</p>
            <span>SARC Overall Co-ordinator, 2023-24
            </span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://www.linkedin.com/in/aastha-patel-048609207"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/12_khushii/"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
        
        <div className={styles.team_name}>
          <span className={styles.team_name_text}>
            WEB TEAM
          </span></div>

        <div className={styles.container1}>
  <div className="row">
      <div className="col-12 col-lg-6">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.akash}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Akash Banger</p>
            <span>SARC Web CTM 2023-24</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/akash-banger"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/akash-banger"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/akash.banger/"><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Akash Banger</p>
            <span>SARC Web CTM 2023-24</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/akash-banger"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/akash-banger"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/akash.banger/"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="col-12 col-lg-6">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.pranita}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Pranita Randive</p>
            <span>SARC Web CTM 2023-24</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/PranitaR12"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/pranita-randive-001581244"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/pranitarandive12/"><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Pranita Randive</p>
            <span>SARC Web CTM 2023-24</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/PranitaR12"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/pranita-randive-001581244"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/pranitarandive12/"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>


    <div className={styles.container1}>
  <div className="row">
      <div className="col-12 col-lg-6">
        <div className={styles.team}>
        <div className={`${styles.team__item} ${styles.akshat}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Akshat Jain</p>
            <span>SARC Web CTM 2024-25</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/akshat1423"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/akshat-jain-069822250/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/akshatj._/"><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Akshat Jain</p>
            <span>SARC Web CTM 2024-25</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/akshat1423"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/akshat-jain-069822250/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/akshatj._/"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="col-12 col-lg-6">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.ashwani}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Ashwani Dubey</p>
            <span>SARC Web CTM 2024-25</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/Ashwanidubeyiitb"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/ashwani-dubey-3b2a81258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/kanhahihu?igsh=MW5xeHZoNW1vbmZlaw=="><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Ashwani Dubey</p>
            <span>SARC Web CTM 2024-25</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/Ashwanidubeyiitb"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/ashwani-dubey-3b2a81258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/kanhahihu?igsh=MW5xeHZoNW1vbmZlaw=="><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>



    {/* <div className={styles.container2}>
  <div className="row">
      <div className="col-12 col-lg-4">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.akshat}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Akshat Jain</p>
            <span>SARC Web CTM 2024-25</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/akshat1423"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/akshat-jain-069822250/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/akshatj._/"><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Akshat Jain</p>
            <span>SARC Web CTM 2024-25</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/akshat1423"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/akshat-jain-069822250/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/akshatj._/"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="col-12 col-lg-4">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.pranita}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Pranita Randive</p>
            <span>SARC Web CTM 2023-24</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/PranitaR12"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/pranita-randive-001581244"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/pranitarandive12?igshid=ZGUzMzM3NWJiOQ=="><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Pranita Randive</p>
            <span>SARC Web CTM 2023-24</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/PranitaR12"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/pranita-randive-001581244"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/pranitarandive12?igshid=ZGUzMzM3NWJiOQ=="><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div> */}
    {/* <div className="col-12 col-lg-4">
        <div className={styles.team}>
      <div className={`${styles.team__item} ${styles.shivesh}`} >
        <div className={styles.team__text}>
          <div className={styles.team__title}>
            <p>Shivesh Gupta</p>
            <span>SARC Web Co-ordinator 2022-23</span>
          </div>
          <div className={styles.team__social}>
                      <a href="https://github.com/shiveshcodes"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/shivesh-gupta-iitb"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/sagacious_shiv?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.textMobile}>
          <div className={styles.titleMobile}>
            <p>Shivesh Gupta</p>
            <span>SARC Web Co-ordinator 2022-23</span>
          </div>
          <div className={styles.socialMobile}>
                      <a href="https://github.com/shiveshcodes"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/shivesh-gupta-iitb"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/sagacious_shiv?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>

        
        <div className={styles.container3}>
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.nilabha}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Nilabha Saha</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                      <a href=""><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/nilabha-saha-056b8a225"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/nilabhasaha13?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Nilabha Saha</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                      <a href=""><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/nilabha-saha-056b8a225"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/nilabhasaha13?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.darshan}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Darshan Makwana</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                      <a href=""><FaGithub /></a>
                      <a href="http://www.linkedin.com/in/darshan-makwana-282480228"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/darshanmakwana434?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Darshan Makwana</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                      <a href=""><FaGithub /></a>
                      <a href="http://www.linkedin.com/in/darshan-makwana-282480228"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/darshanmakwana434?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.ansh}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Ansh Preet</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                      <a href=""><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/ansh-preet-851077236"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/anshpreet022?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Ansh Preet</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                      <a href=""><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/ansh-preet-851077236"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/anshpreet022?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.shreetosh}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Shreetosh Shelar</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                      <a href=""><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/shreetosh-shelar-61a036242"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/shree03_shelar?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Shreetosh Shelar</p>
                      <span>SARC Web Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                      <a href=""><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/shreetosh-shelar-61a036242"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/shree03_shelar?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles.team_name}>
          <span className={styles.team_name_text}>
            DESIGN TEAM
          </span></div>
        <div className={styles.container1}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.himanshu}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Himanshu Nagar</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/himanshnagar45"><FaLinkedinIn /></a>
                      <a href="http://instagram.com/_himansh45"><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                    <p>Himanshu Nagar</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/himanshnagar45"><FaLinkedinIn /></a>
                      <a href="http://instagram.com/_himansh45"><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.jay}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                    <p>Jay Arora</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/jay-arora-8272b4242?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BFPXf3DEOQEmKTiABMOJiHA%3D%3D"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/jay._.arora?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                    <p>Jay Arora</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/jay-arora-8272b4242?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BFPXf3DEOQEmKTiABMOJiHA%3D%3D"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/jay._.arora?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.team_name}>
          <span className={styles.team_name_text}>
            MEDIA & PR TEAM
          </span></div>
        <div className={styles.container1}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.priyanshi}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Priyanshi Garg</p>
                      <span>SARC Media & PR CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/priyanshi-garg-a15813226/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/priyanshi_garg_/"><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                    <p>Priyanshi Garg</p>
                      <span>SARC Media & PR CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/priyanshi-garg-a15813226/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/priyanshi_garg_/"><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.vaibhavi}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                    <p>Vaibhavi Shinde</p>
                      <span>SARC Media & PR CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/vaibhavi-shinde-1a6988225/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/vaibhavi_shinde2003/"><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                    <p>Vaibhavi Shinde</p>
                      <span>SARC Media & PR CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/vaibhavi-shinde-1a6988225/"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/vaibhavi_shinde2003/"><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <div className={styles.container4}>
          <div className="row">
            <div className="col-12 col-lg-2">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.siddhant}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Siddhant Chourasia</p>
                      <span>SARC Design Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                     
                      <a href="https://www.linkedin.com/in/siddhant-chourasia-851647223"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/siddhant3.6?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Siddhant Chourasia</p>
                      <span>SARC Design Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                      
                      <a href="https://www.linkedin.com/in/siddhant-chourasia-851647223"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/siddhant3.6?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.himanshu}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Himanshu Nagar</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/himanshnagar45"><FaLinkedinIn /></a>
                      <a href="http://instagram.com/_himansh45"><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Himanshu Nagar</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.socialMobile}>
                      
                      <a href="https://www.linkedin.com/in/himanshnagar45"><FaLinkedinIn /></a>
                      <a href="http://instagram.com/_himansh45"><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.jay}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Jay Arora</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/jay-arora-8272b4242?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BFPXf3DEOQEmKTiABMOJiHA%3D%3D"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/jay._.arora?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Jay Arora</p>
                      <span>SARC Design CTM 2023-24</span>
                    </div>
                    <div className={styles.socialMobile}>
                      
                      <a href="https://www.linkedin.com/in/jay-arora-8272b4242?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BFPXf3DEOQEmKTiABMOJiHA%3D%3D"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/jay._.arora?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.om}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Om Unhale</p>
                      <span>SARC Design Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                     
                      <a href="https://www.linkedin.com/in/om-unhale-3360aa202"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/om_unhale_123?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Om Unhale</p>
                      <span>SARC Design Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                    
                      <a href="https://www.linkedin.com/in/om-unhale-3360aa202"><FaLinkedinIn /></a>
                      <a href="https://instagram.com/om_unhale_123?igshid=NTc4MTIwNjQ2YQ=="><FaInstagram /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className={styles.team}>
                <div className={`${styles.team__item} ${styles.dhruv}`} >
                  <div className={styles.team__text}>
                    <div className={styles.team__title}>
                      <p>Dhruv Patel</p>
                      <span>SARC Design Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.team__social}>
                      
                      <a href="https://www.linkedin.com/in/dhruv-patel-8a5a74222"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/invites/contact/?i=1e7mr2v5haze0&utm_content=3nw4boc"><FaInstagram /></a>
                    </div>
                  </div>
                  <div className={styles.textMobile}>
                    <div className={styles.titleMobile}>
                      <p>Dhruv Patel</p>
                      <span>SARC Design Co-ordinator 2022-23</span>
                    </div>
                    <div className={styles.socialMobile}>
                      
                      <a href="https://www.linkedin.com/in/dhruv-patel-8a5a74222"><FaLinkedinIn /></a>
                      <a href="https://www.instagram.com/invites/contact/?i=1e7mr2v5haze0&utm_content=3nw4boc"><FaInstagram /></a>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}

    <div className={styles.line2}> 
          <div className={styles.contactus}>For any issues, contact:</div> 
    Akshat Jain, Web CTM | SARC 2024-25  (7990565567)<br />
    Ashwani Dubey, Web CTM | SARC 2024-25  (6263173987)<br />
    Akash Banger, Web CTM | SARC 2023-24  (9814417989)<br />
    Pranita Randive, Web CTM | SARC 2023-24  (8010503085)<br />
    
    </div>
      </div >
   
    <Footers />
  
</>
    )
}

export default Devteam
