import { useLogout } from "./useLogout";
export const useUser = () => {
  const { logout } = useLogout();
  var user = null

  const getuser = async () => {
    if (localStorage.getItem('user')) {

      user = JSON.parse(localStorage.getItem('user'));
    }
    else {
      logout();
    }
  }
  getuser();

  return { user };
};
