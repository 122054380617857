import react from 'react';
import axios from 'axios';
import { useUser } from '../../../hooks/useUser';
import { useEffect, useState } from 'react';
import './Styles/Comment.css';

import CommentHeader from './CommentHeader';
import CommentFooter from './CommentFooter';
import { useProfile } from '../../../hooks/useProfile';

// const [editing, setEditing] = useState(false);

function Caption({ caption }) {

  const { profile, loading, error } = useProfile();

  const convertMentionsToLinks = text => {
    const mentionRegex = /@(\w+(\s\w+)*)\((\d+)\)/g;

    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const mention = match[0]; // Full mention including @ and ()
      const username = match[1]; // Username with spaces
      const id = match[3]; // ID within ()

      // Add the text before the mention
      parts.push(text.slice(lastIndex, match.index));

      // Add the link
      parts.push(
        <a className="tagLink" key={match.index} href={(id === profile.user)?('/profile'):(`/profile/${id}`)}>
          {mention}
        </a>,
      );

      lastIndex = mentionRegex.lastIndex;
    }

    // Add the remaining text after the last mention
    parts.push(text.slice(lastIndex));

    return parts;
  };

  const convertedCaption = convertMentionsToLinks(caption);

  return <p>{convertedCaption}</p>;
}

const Comment = ({
  comment,
  currentUserID,
  handleChildStateChange,
  handleDeleteComment,
}) => {
  const [likes, setLikes] = useState(comment.like_count);
  const { user } = useUser();
  const [error, setError] = useState(null);

  const likeComment = comment_id => {
    try{
      axios
      .post(
        '/api/comments/like_impression_comment/' +
          comment_id +
          '/',
        null,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        },
      )
      .then(response => {
        console.log('Response from Comments API liked', response.data);
        setLikes(response.data['like_count']);
        return response.data;
      })
      .then(data => {
        console.log('setting Impressions Comments');
        setLikes(data.like_count);
        // setComment(data);
        // console.log("comments", comment)
      });
    }
    catch(error){
      setError(error);
    }
    handleChildStateChange();
  };

  return (
    <>
      <div className="comment-container">
        <div className="comment">
          <div className="comment--body">
            <CommentHeader
              handleDeleteComment={handleDeleteComment}
              comment={comment}
            />
            <div className="commentAndLikeContainer">
              <div className="comment-content">
                <Caption caption={comment.text} />
              </div>
              <CommentFooter
                currentUserID={currentUserID}
                likes={likes}
                likeComment={() => likeComment(comment.id)}
                likedBy={comment.liked_by}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;
