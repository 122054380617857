import { react, useState } from 'react';
import '../styles/Filter.css';
import { useContext } from 'react';
import SortContext from '../../context/SortContext';

function Filter() {
  const { selectedClubs, selectedHostels, selectedDepartments } =
    useContext(SortContext);
  const departments = [
    { name: 'Aerospace Engineering', value: 'Aerospace Engg', id: 0 },
    { name: 'Animation', value: 'Animation', id: 1 },
    { name: 'Application Software Centre', value: 'ASC', id: 2 },
    { name: 'Applied Geophysics', value: 'Applied Geophysics', id: 3 },
    { name: 'Applied Statistics and Informatics', value: 'ASI', id: 4 },
    { name: 'Biomedical Engineering', value: 'Biomedical Engineering', id: 5 },
    {
      name: 'Biosciences and Bioengineering',
      value: 'Biosciences and Engineering',
      id: 6,
    },
    { name: 'Biotechnology', value: 'Biotechnology', id: 7 },
    {
      name: 'Centre for Aerospace Systems Design and Engineering',
      value: 'CASDE',
      id: 8,
    },
    {
      name: 'Centre for Distance Engineering Education Programme',
      value: 'CDEEP',
      id: 9,
    },
    {
      name: 'Centre for Environmental Science and Engineering',
      value: 'CESE',
      id: 10,
    },
    {
      name: 'Centre for Formal Design and Verification of Software',
      value: 'CFDVS',
      id: 11,
    },
    {
      name: 'Centre for Research in Nanotechnology and Science',
      value: 'CRNS',
      id: 12,
    },
    {
      name: 'Centre for Technology Alternatives for Rural Areas',
      value: 'CTARA',
      id: 13,
    },
    { name: 'Centre for Urban Science and Engineering', value: 'CUSE', id: 14 },
    {
      name: 'Centre of Studies in Resources Engineering',
      value: 'CSRE',
      id: 15,
    },
    { name: 'Chemical Engineering', value: 'Chemical Engg', id: 16 },
    { name: 'Chemistry', value: 'Chemistry', id: 17 },
    { name: 'Civil Engineering', value: 'Civil Engg', id: 18 },
    { name: 'Climate Studies', value: 'Climate Studies', id: 19 },
    { name: 'Computer Centre', value: 'CC', id: 20 },
    { name: 'Computer Science & Engineering', value: 'CSE', id: 21 },
    { name: 'Continuing Education Programme', value: 'CEP', id: 22 },
    {
      name: 'Corrosion Science and Engineering',
      value: 'Corrosion Science & Engg',
      id: 23,
    },
    { name: 'Desai Sethi Centre for Entrepreneurship', value: 'DSCE', id: 24 },
    { name: 'Earth Sciences', value: 'Earth Sciences', id: 25 },
    { name: 'Educational Technology', value: 'Educational Technology', id: 26 },
    { name: 'Electrical Engineering', value: 'EE', id: 27 },
    {
      name: 'Energy Science and Engineering',
      value: 'Energy Science and Engg',
      id: 28,
    },
    { name: 'Economics (HSS)', value: 'Economics', id: 29 },
    { name: 'Engineering Physics', value: 'Engineering Physics', id: 30 },
    { name: 'Humanities & Social Science', value: 'HSS', id: 31 },
    { name: 'IITB-Monash Research Academy', value: 'IITB-Monash', id: 32 },
    { name: 'Industrial Design Centre', value: 'IDC', id: 33 },
    {
      name: 'Industrial Engineering and Operations Research',
      value: 'IEOR',
      id: 34,
    },
    { name: 'Industrial Management', value: 'Industrial Management', id: 35 },
    { name: 'Interaction Design', value: 'Interaction Design', id: 36 },
    {
      name: 'Kanwal Rekhi School of Information Technology',
      value: 'KRSIT',
      id: 37,
    },
    { name: 'Material Science', value: 'Material Science', id: 38 },
    {
      name: 'Materials, Manufacturing and Modelling',
      value: 'MMM',
      id: 39,
    },
    { name: 'Mathematics', value: 'Mathematics', id: 40 },
    { name: 'Mechanical Engineering', value: 'Mechanical Engg', id: 41 },
    {
      name: 'Metallurgical Engineering & Materials Science',
      value: 'MEMS',
      id: 42,
    },
    {
      name: 'Mobility and Vehicle Design',
      value: 'Mobility and Vehicle Design',
      id: 43,
    },
    {
      name: 'National Centre for Aerospace Innovation and Research',
      value: 'NCAIR',
      id: 44,
    },
    { name: 'National Centre for Mathematics', value: 'NCM', id: 45 },
    { name: 'Physical Education', value: 'Physical Education', id: 46 },
    { name: 'Physics', value: 'Physics', id: 47 },
    {
      name: 'Physics, Material Science',
      value: 'Physics, Material Science',
      id: 48,
    },
    { name: 'Preparatory Course', value: 'Preparatory Course', id: 49 },
    {
      name: 'Reliability Engineering',
      value: 'Reliability Engineering',
      id: 50,
    },
    {
      name: 'Shailesh J. Mehta School of Management',
      value: 'SJM-SOM',
      id: 51,
    },
    {
      name: 'Sophisticated Analytical Instrument Facility',
      value: 'SAIF',
      id: 52,
    },
    { name: 'Systems and Control Engineering', value: 'SysCon Engg', id: 53 },
    {
      name: 'Tata Center for Technology and Design',
      value: 'Tata Center',
      id: 54,
    },
    { name: 'Technology and Development', value: 'Tech and Dev ', id: 55 },
    { name: 'Visual Communication', value: 'Visual Communication', id: 56 },
    {
      name: 'Wadhwani Research Centre for Bioengineering',
      value: 'Wadhwani Research Centre',
      id: 57,
    },
    {
      name: 'Centre for Policy Studies',
      value: 'Centre for Policy Studies',
      id: 58,
    },
  ];

  const hostels = [
    { nm: 'hostel_1', value: 'Hostel 1', id: 59 },
    { nm: 'hostel_2', value: 'Hostel 2', id: 60 },
    { nm: 'hostel_3', value: 'Hostel 3', id: 61 },
    { nm: 'hostel_4', value: 'Hostel 4', id: 62 },
    { nm: 'hostel_5', value: 'Hostel 5', id: 63 },
    { nm: 'hostel_6', value: 'Hostel 6', id: 64 },
    { nm: 'hostel_7', value: 'Hostel 7', id: 65 },
    { nm: 'hostel_8', value: 'Hostel 8', id: 66 },
    { nm: 'hostel_9', value: 'Hostel 9', id: 67 },
    { nm: 'hostel_10', value: 'Hostel 10', id: 68 },
    { nm: 'hostel_11', value: 'Hostel 11', id: 69 },
    { nm: 'hostel_12', value: 'Hostel 12', id: 70 },
    { nm: 'hostel_13', value: 'Hostel 13', id: 71 },
    { nm: 'hostel_14', value: 'Hostel 14', id: 72 },
    { nm: 'hostel_15', value: 'Hostel 15', id: 73 },
    { nm: 'hostel_16', value: 'Hostel 16', id: 74 },
    { nm: 'hostel_17', value: 'Hostel 17', id: 75 },
    { nm: 'hostel_18', value: 'Hostel 18', id: 76 },
    { nm: 'hostel_tansa', value: 'Hostel Tansa', id: 77 },
    { nm: 'qip', value: 'QIP', id: 78 },
  ];

  const clubIBs = [
    { name: 'None', id: 79 },
    { name: 'AUV', id: 80 },
    { name: 'AZeotropy', id: 81 },
    { name: 'Aakaar', id: 82 },
    { name: 'Aavhan', id: 83 },
    { name: 'Abhyuday', id: 84 },
    { name: 'Academic Council', id: 85 },
    { name: 'Adventure Club', id: 86 },
    { name: 'Aeromodelling Club', id: 87 },
    { name: 'Analytics Club', id: 88 },
    { name: 'Aquatics', id: 89 },
    { name: 'Astronomy Club', id: 90 },
    { name: 'Athletics', id: 91 },
    { name: 'Badminton', id: 92 },
    { name: 'Basketball', id: 93 },
    { name: 'BioTech Club', id: 94 },
    { name: 'Board Games', id: 95 },
    { name: 'Chess', id: 96 },
    { name: 'Consult Club', id: 97 },
    { name: 'Comedy Club', id: 98 },
    { name: 'Cricket', id: 99 },
    { name: 'Culinary Club', id: 100 },
    { name: 'Department Council', id: 101 },
    { name: 'Design Club', id: 102 },
    { name: 'E-Cell', id: 103 },
    { name: 'Electronics and Robotics Club', id: 104 },
    { name: 'Finance Club', id: 105 },
    { name: 'Football Club', id: 106 },
    { name: 'Fourthwall ', id: 107 },
    { name: 'GRA', id: 108 },
    { name: 'Hockey', id: 109 },
    { name: 'Hostel Affairs Council', id: 110 },
    { name: 'Hostel Council', id: 111 },
    { name: 'Hult Prize Competition', id: 112 },
    { name: 'IITB Racing ', id: 113 },
    { name: 'IITB Student Satellite Program', id: 114 },
    { name: 'ISCP', id: 115 },
    { name: 'InSync', id: 116 },
    { name: 'Innovation Cell', id: 117 },
    { name: 'Insight', id: 118 },
    { name: 'Institute Cultural Council', id: 119 },
    { name: 'Institute Sports Council', id: 120 },
    { name: 'Institute Technical Council', id: 121 },
    { name: 'Kho-Kho', id: 122 },
    { name: 'Lawn Tennis', id: 123 },
    { name: 'LifeStyle Club', id: 124 },
    { name: 'Lit Club', id: 125 },
    { name: 'Litzkrieg', id: 126 },
    { name: 'Mars Society India', id: 127 },
    { name: 'Maths and Physics Club', id: 128 },
    { name: 'Mood Indigo', id: 129 },
    { name: 'Music Club', id: 130 },
    { name: 'NCC', id: 131 },
    { name: 'NSS', id: 132 },
    { name: 'PT cell', id: 133 },
    { name: 'Pixels', id: 134 },
    { name: 'Radiance ', id: 135 },
    { name: 'Rakshak IITB ', id: 136 },
    { name: 'Rang', id: 137 },
    { name: 'Robobtics Club', id: 137 },
    { name: 'Roots', id: 138 },
    { name: 'SARC', id: 139 },
    { name: 'SMP', id: 140 },
    { name: 'STAB', id: 141 },
    { name: 'Saathi', id: 142 },
    { name: 'Saaz', id: 143 },
    { name: 'ShARE', id: 144 },
    { name: 'Silverscreen', id: 145 },
    { name: 'Squash', id: 146 },
    { name: 'Staccato', id: 147 },
    { name: 'Sustainability Cell IITB', id: 148 },
    { name: 'Table Tennis', id: 149 },
    { name: 'Team Shunya', id: 150 },
    { name: 'Techfest', id: 151 },
    { name: 'Tinkerers Lab', id: 152 },
    { name: 'Vaani', id: 153 },
    { name: 'Volleyball', id: 154 },
    { name: 'WeSpeak', id: 155 },
    { name: 'Web and Coding Club', id: 156 },
    { name: 'Weightlifting', id: 157 },
    { name: 'devcom', id: 158 },
    { name: 'Exofly', id: 159 },
    { name: 'UMIC', id: 160 },
    { name: 'PGAC', id: 161 },
    { name: 'EnPoWER', id: 162 },
    { name: 'PlacementLog', id: 163 },
    { name: 'Career Cell', id: 164 },
    { name: 'CSec', id: 165 },
    { name: 'Mars Rover Team', id: 166 },
    { name: 'UGAC', id: 167 },
    { name: 'Zephyr', id: 168 },
    { name: 'Humanoid', id: 169 },
    { name: 'SSS', id: 170 },
    { name: 'Symphony', id: 171 },
    { name: 'Kritikka', id: 172 },
    { name: 'DAV', id: 173 },
    { name: 'IIT Rocket Team', id: 174 },
    { name: 'BioXClub', id: 175 },
    { name: 'ERC', id: 176 },
    { name: 'Hyperloop', id: 177 },
    { name: 'IIT-BBC', id: 178 },
    { name: 'Literati', id: 179 },
    { name: 'ELIT', id: 180 },
    { name: 'Internship blog', id: 181 },
    { name: 'Chemistry Club', id: 182 },
    { name: 'Enactus', id: 183 },
    { name: 'Energy and Sustainability Club', id: 184 },
    {name: 'SPART', id: 185 },
  ];

  let array1 = [];
  let array2 = [];
  let array3 = [];

  departments.forEach(element => {
    array1[element.id] = {
      name: element.name,
      value: element.value,
      isChecked: false,
    };
  });

  hostels.forEach(element => {
    array2[element.id - 59] = {
      name: element.nm,
      value: element.value,
      isChecked: false,
    };
  });

  clubIBs.forEach(element => {
    array3[element.id - 79] = {
      name: element.name,
      value: element.value,
      isChecked: false,
    };
  });

  const [checkedboxesDept, setCheckedBoxesDept] = useState(array1);
  const [checkedboxesHostel, setCheckedBoxesHostel] = useState(array2);
  const [checkedboxesClubIBs, setCheckedBoxesClubIBs] = useState(array3);

  function handleCheckboxDeptChange(id) {
    if (checkedboxesDept[id].isChecked) {
      const temp = [...checkedboxesDept];
      temp[id].isChecked = false;
      setCheckedBoxesDept(temp);
    } else {
      const temp = [...checkedboxesDept];
      temp[id].isChecked = true;
      setCheckedBoxesDept(temp);
    }
    selectedDepartments(
      checkedboxesDept.filter(dept => dept.isChecked === true),
    );
  }

  function handleCheckboxHostelChange(id) {
    // console.log('ok');
    if (checkedboxesHostel[id - 59].isChecked) {
      const temp = [...checkedboxesHostel];
      temp[id - 59].isChecked = false;
      setCheckedBoxesHostel(temp);
    } else {
      const temp = [...checkedboxesHostel];
      temp[id - 59].isChecked = true;
      setCheckedBoxesHostel(temp);
    }
    selectedHostels(
      checkedboxesHostel.filter(hostel => hostel.isChecked === true),
    );
  }

  function handleCheckboxClubIBsChange(id) {
    // console.log('ok');
    if (checkedboxesClubIBs[id - 79].isChecked) {
      const temp = [...checkedboxesClubIBs];
      temp[id - 79].isChecked = false;
      setCheckedBoxesClubIBs(temp);
    } else {
      const temp = [...checkedboxesClubIBs];
      temp[id - 79].isChecked = true;
      setCheckedBoxesClubIBs(temp);
    }
    selectedClubs(
      checkedboxesClubIBs.filter(clubs => clubs.isChecked === true),
    );
  }

  return (
    <>
      <h2 className="headings">Department</h2>
      <div
        id="type"
        style={{
          height: '16vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          border: 'solid white 1px',
        }}>
        <div className="bump">
          {departments.map(dept => {
            return (
              <div className="box1" key={dept.id}>
                <input
                  onChange={() => handleCheckboxDeptChange(dept.id)}
                  type="checkbox"
                  id={dept.id}
                  checked={checkedboxesDept[dept.id].isChecked}
                />
                <label htmlFor={dept.id} className="check-box" />
                <h4>{dept.value}</h4>
              </div>
            );
          })}
        </div>
      </div>

      <h2 className="headings">Hostel</h2>
      <div
        id="type"
        style={{
          height: '16vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          border: 'solid white 1px',
        }}>
        <div className="bump">
          {hostels.map(hostel => {
            return (
              <div className="box1" key={hostel.id}>
                <input
                  onChange={() => handleCheckboxHostelChange(hostel.id)}
                  type="checkbox"
                  id={hostel.id}
                  checked={checkedboxesHostel[hostel.id - 59].isChecked}
                />
                <label htmlFor={hostel.id} className="check-box" />
                <h4>{hostel.value}</h4>
              </div>
            );
          })}
        </div>
      </div>

      <h2 className="headings">Club/IBs</h2>
      <div
        id="type"
        style={{
          height: '16vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          border: 'solid white 1px',
        }}>
        <div className="bump">
          {clubIBs.map(club => {
            return (
              <div className="box1" key={club.id}>
                <input
                  onChange={() => handleCheckboxClubIBsChange(club.id)}
                  type="checkbox"
                  id={club.id}
                  checked={checkedboxesClubIBs[club.id - 79].isChecked}
                />
                <label htmlFor={club.id} className="check-box" />
                <h4>{club.name}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Filter;
