const posts = {
  posts: [],
  loading: false,
  error: null,
};

const postReducer = (state = posts, action) => {
  switch (action.type) {
    case 'FETCH_POSTS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_POSTS':
      // console.log('GOING TO UPDATE STATE', state.posts, action.payload);
      return {
        ...state,
        posts: action.payload,
        loading: false,
        error: null,
      };

    // No need of the following case as we are not using it
    // case 'FETCH_POSTS_SCROLL':
    //   console.log("GOING TO UPDATE STATE", state.posts, action.payload)
    //   return {
    //     ...state,
    //     // posts: action.payload,
    //     posts: [...state.posts, ...action.payload],
    //     loading: false,
    //     error: null,
    //     page: state.page + 1
    //   };

    case 'FETCH_POSTS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
        page: state.page,
      };

    default:
      return state;
  }
};

export default postReducer;
