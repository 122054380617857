import styles from '../styles/HeaderImpression.module.css'

function HeaderImpression(){
    return(
        <>

            <div className={styles.Title}>
                <h2 style={{zIndex: "0"}} data-text="SNAPSHOTS">SNAPSHOTS</h2>
                <div className={styles.line2}> Share the joy of your most memorable moments during your institute stay with your fellow graduating class. Remember, even the briefest of moments can leave a lasting impression, so share your photos and help them reminisce. And if you're feeling witty, why not add a humorous and clever yearbook quote to the mix? </div>
            </div>
        </>
    )
}

export default HeaderImpression;