import React from 'react';
import styles from '../styles/DisplayNotif.module.css';

function DisplayNotif(props) {
  console.log('hello');
  console.log(props.notif.by_profile)
  return (
    <div className={styles.NotifWrapContainer}>
      <div className={styles.NotifContent}>
      <img
        src={
            (props.notif.details === "You were tagged in an anonymous post" ||
            props.notif.details === "Someone wrote for you anonymously.")
            ? 'https://avatars.githubusercontent.com/u/16786985?v=4'
            : 'https://yearbook.sarc-iitb.org' + props.notif.by_profile.profile_image
  }
  alt="profile pic"
  className={styles.ProfilePic}
/>
        <div className={styles.NotifText}>{props.notif.details}</div>
      </div>
    </div>
  );
}

export default DisplayNotif;
