import React from 'react';
import './styles/Footer.css';
import sarclogo from '../assets/sarclogo.png';

const Footer = () => {

  const handleLocationClick = () => {
    window.open('https://maps.app.goo.gl/ZKp4vxWRyfpx5fLD9', '_blank');
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Cookie"
        rel="stylesheet"
        type="text/css"
      />
      <footer className="footer-distributed">
        <div className="footer-left">
          <img src={sarclogo}></img>
          <p className="footer-links">
            <a href="/">Home</a>|<a href="/feed">Feed</a>|<a href="/polls">Polls</a>|<a href="/impression">Snapshots</a>|
            <a href="/team">Team</a>
          </p>
          <p className="footer-company-name">
            Copyright © 2024 <strong>SARC</strong> All rights reserved
          </p>
        </div>

        <div className="footer-center">
          <div className="location-container" onClick={handleLocationClick}>
            <i className="fa fa-map-marker" />
            <p>
              <span>SARC Room, IITB</span>
            </p>
          </div>

          <div className='fa-phone1'>
            <i className="fa fa-phone" />
            <p>Akshat Jain:<br></br> +91 7990565567<br></br>Ashwani Dubey <br></br>+91 6263173987 </p>
          </div>
          <div>
            <i className="fa fa-envelope" />
            <p>
              <a href="mailto:sarc-iitb@gmail.com">web@sarc-iitb.org</a>
            </p>
          </div>
        </div>

        <div className="footer-center1">
          <div className="location-container" onClick={handleLocationClick}>
            <i className="fa fa-map-marker" />
            <p>
              <span>SARC Room, IITB</span>
            </p>
          </div>

          <div>
          <i className="fa fa-phone" /><br></br>
            <p>Akshat Jain:<br></br> +91 7990565567<br></br>Ashwani Dubey <br></br>+91 6263173987 </p>
          </div>
          <div>
            <i className="fa fa-envelope" /><br></br>
            <p>
              <a href="mailto:sarc-iitb@gmail.com">web@sarc-iitb.org</a>
            </p>
          </div>
        </div>



        <div className="footer-right">
          <p className="footer-company-about">
            <span>SARC💙</span>
            Stay Connected beyond your college years with SARC and keep the good
            times rolling! From campus life to post-grad success, we have got
            you covered.
          </p>
          <div className="footer-icons">
            <a href="https://www.facebook.com/SARC.IITB/">
              <i className="fa fa-facebook" />
            </a>
            <a href="https://www.instagram.com/sarc_iitb/">
              <i className="fa fa-instagram" />
            </a>
            <a href="https://in.linkedin.com/company/student-alumni-relations-cell">
              <i className="fa fa-linkedin" />
            </a>
            <a href="https://www.youtube.com/c/SARCIITBombay">
              <i className="fa fa-youtube" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
