export const fetchPosts = data => ({
  type: 'FETCH_POSTS',
  payload: data,
});

export const fetchPostsRequest = () => ({
  type: 'FETCH_POSTS_REQUEST',
});

export const fetchPostsScroll = data => ({
  type: 'FETCH_POSTS_SCROLL',
  payload: data,
});

export const fetchPostsFailure = error => ({
  type: 'FETCH_POSTS_FAILURE',
  payload: error,
});

export const uploadPost = (data) => ({
  type: 'UPLOAD_POST',
  payload: {
    post: data,
  },
});

export const uploadPostRequest = () => ({
  type: 'UPLOAD_POST_REQUEST',
});

export const uploadPostFailure = error => ({
  type: 'UPLOAD_POST_FAILURE',
  payload: error,
});

export const deletePost = post_id => ({
  type: 'DELETE_POST',
  payload: post_id,
});

export const deletePostRequest = () => ({
  type: 'DELETE_POST_REQUEST',
});

export const deletePostFailure = error => ({
  type: 'DELETE_POST_FAILURE',
  payload: error,
});

export const updatePost = (data) => ({
  type: 'UPDATE_POST',
  payload: {
    post: data,
  },
});

export const updatePostRequest = () => ({
  type: 'UPDATE_POST_REQUEST',
});

export const updatePostFailure = error => ({
  type: 'UPDATE_POST_FAILURE',
  payload: error,
});

export const LikePost = post_id => ({
  type: 'LIKE_POST',
  payload: {
    post_id: post_id,
  },
});

export const LikePostRequest = () => ({
  type: 'LIKE_POST_REQUEST',
});

export const LikePostFailure = error => ({
  type: 'LIKE_POST_FAILURE',
  payload: error,
});

export const fetchPersonalizedPosts = data => ({
  type: 'PERSONALIZE_POST',
  payload: data,
});

export const fetchPersonalizedPostRequest = () => ({
  type: 'PERSONALIZE_POST_REQUEST',
});

export const fetchPersonalizedPostFailure = error => ({
  type: 'PERSONALIZE_POST_FAILURE',
  payload: error,
});