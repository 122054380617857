import { useReducer } from 'react';
import { createContext } from 'react';

export const PostsContext = createContext();

export const postsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_POSTS':
      return {
        posts: action.payload,
      };
    case 'SET_POST':
      console.log(state.posts);
      let idx = state.posts.findIndex(post => post.id === action.payload.id);
      state.posts[idx] = action.payload;
      return {
        posts: state.posts,
      };
    case 'CREATE_POSTS':
      return {
        posts: [action.payload, ...state.posts],
      };
    case 'DELETE_POST':
      return {
        posts: state.posts.filter(
          p => p.written_for !== action.payload.written_for,
        ),
      };
    default:
      return state;
  }
};

export const PostsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(postsReducer, {
    posts: null,
  });

  return (
    <PostsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </PostsContext.Provider>
  );
};
