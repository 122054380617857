import React from 'react';
import Nav from '../components/Nav';
import styled from 'styled-components';
import SideBar from '../components/Feed/SideBar';
import RightBar from '../components/Feed/RightBar';
import DisplayNotif from '../components/Notifs/DisplayNotif';
import OurPosts from '../components/Feed/OurPosts';
import styles from './styles/Feed.module.css';
import { useContext } from 'react';
import NotifContext from '../context/NotifContext';
import styleNotif from './styles/Feed.module.css';
import NotifContainer from '../components/Notifs/NotifContainer';
import { zIndex } from 'material-ui/styles';

function MyFeed({userID}) {
    const { status, togglePlayPause } = useContext(NotifContext);
    return (
        <>
            <div className={styles.ChildContainer}>
                {/* <SideBar /> */}
                <OurPosts userID={userID} />
                {/* <RightBar /> */}
            </div>
        </>
    );
}

export default MyFeed;
