import React, { useEffect, useState} from 'react';
// import ImageUploader from 'react-image-upload';
import ImageUploading from 'react-images-uploading';
import s from './styles/Profile.module.css';
import Profilebutton from '../components/mycomponents/Profilebutton';
import face from '../assets/facebook.png';
import what from '../assets/whatsapp.png';
import insta from '../assets/instagram.png';
import FeedContainer from '../components/Feed/FeedContainer.jsx';
import { useProfile } from '../hooks/useProfile';
import { useUser } from '../hooks/useUser';
import { json } from 'react-router-dom';
import { Link } from 'react-router-dom';
import verified from '../assets/verified.png'

import Tab1Content from './myfeed';
import Tab2Content from './OthersFeed';
import Tab3Content from './MySnapshots';
import Tab4Content from './TaggedSnapshots';
import { FaWhatsapp, FaFacebook, FaInstagram, FaCopy } from 'react-icons/fa';
import { useContext} from 'react';
import NotifContext from '../context/NotifContext';
import styleNotif from './styles/Feed.module.css';
import NotifContainer from '../components/Notifs/NotifContainer';
import Swal from 'sweetalert2';

const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}


const Profile = () => {

  const { profile } = useProfile();
  const { user } = useUser();
  const [images, setImages] = useState([]); 

  const { status, togglePlayPause } = useContext(NotifContext);


  const shareOnWhatsApp = () => {
    // Profile link
    const profileLink = `https://yearbook.sarc-iitb.org/profile/${profile.user}`;

    // Redirect to WhatsApp share link with custom message including the profile link
    const message = encodeURIComponent(
      `Check out my profile at Yearbook 2024 by SARC | IIT Bombay ${profileLink}`,
    );
    const link = `https://api.whatsapp.com/send?text=${message}`;
    window.open(link, '_blank');
  };

  const shareOnFacebook = () => {
    var profileLink = encodeURIComponent(
      `https://yearbook.sarc-iitb.org/profile/${profile.user}`,
    );
    var message = encodeURIComponent(
      'Check out my profile at Yearbook 2024 by SARC | IIT Bombay',
    );

    var url =
      'https://www.facebook.com/sharer/sharer.php?u=' +
      profileLink +
      '&quote=' +
      message;
    window.open(url, '_blank');
  };

  function shareOnInstagram() {
    Swal.fire({
      title: 'Below is the link of your profile',
      text: `https://yearbook.sarc-iitb.org/profile/${profile.user}`,
      icon: 'info',
      confirmButtonText: 'OK',
    });
  }
  

  const handleGalleryClick = (e) => {
    // console.log(e.target.parentNode.getAttribute('data_attr'))
    const inp = document.getElementById(`${e.target.parentNode.getAttribute('data_attr')}`);
    inp.click();
  }
  
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      uploadImage(e.target.id, e.target.files[0])
    }
  }
  
  const uploadImage = async (name, file) => {
    const formData = new FormData();
    formData.append(name, file);
    const res = await fetch(
      '/api/authenticate/profile/gallery/',
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${user.access}`,
        }
      }
    );
    const data = await res.json();
    setImages(data);
    return data;
  }
  


  useEffect(()=>{
    
    const fetchLinks = async () => {
      const res = await fetch('/api/authenticate/profile/gallery/',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access}`
        }
      })
      const data = await res.json();
      setImages(data);
    }

    fetchLinks()
      
  }, [setImages])
  
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };


  return (
    <>  


<div
         style={{ zIndex: '99' }}
         className={status ? styleNotif.active : styleNotif.notactive}>
         {/* <NotifContainer /> */}
       </div>
    
    <div className='profilemain'>
      <input
        type="file"
        id="img1"
        className={s.hidden_input}
        onChange={handleImageUpload}
      ></input>
      <input
        type="file"
        id="img2"
        className={s.hidden_input}
        onChange={handleImageUpload}
      ></input>
      <input
        type="file"
        id="img3"
        className={s.hidden_input}
        onChange={handleImageUpload}
      ></input>
      <input
        type="file"
        id="img4"
        className={s.hidden_input}
        onChange={handleImageUpload}
      ></input>
      <input
        type="file"
        id="cover"
        className={s.hidden_input}
        onChange={handleImageUpload}
      ></input>

      
      <div className={s.cover_img} data_attr="cover">
         <div class={s.covershit} onClick={handleGalleryClick}>Edit</div>
        <img src={'https://yearbook.sarc-iitb.org'+images['cover']} alt="" />
        <div className={s.profile_img}>
          <img src={'https://yearbook.sarc-iitb.org'+profile.profile_image} alt="profile" />
        </div>
      </div>
      <div className={s.profile_body}>
      <div className={s.profile_head}>
            <div className={s.profile_info}>
              <p style={{color: "#865dff", fontSize: "150%", display: "flex", alignItems:"center"}}>
              {profile.name}
              {profile.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}
               {/* <span style={{color:"pink"}}>({profile.career})</span> */}
               </p>
              {!profile.is_ib && <p>
                {' '}
                {profile.degree} | {profile.department} ({profile.join_year+"-"+profile.graduation_year}) | {'Hostel ' + profile.hostel.split('_')[1]}
              </p>}
              <p style={{margin: "5px" ,backgroundColor: "rgba(0,0,0,0.3)", padding: "5px", paddingLeft: '20px', paddingRight: '20px', borderRadius: "10px"}}>{profile.tagline}</p>
            </div>
            {!profile.is_ib && <div className={s.profile_info}>
            {profile.nickname && <p><span style={{color: "#865Dff"}}>NickName:</span> {profile.nickname}</p>}
              <p><span style={{color: "#865Dff"}}>Wish {profile.nickname} on:</span> {" " + (profile.dob.split('-')[2] - 0) + " " + months[((profile.dob.split('-')[1])-1)]} </p>
              <p><span style={{color: "#865Dff"}}>Career:</span> {profile.career} </p>
              
            </div>}
            <div className={s.btnscontainer}>
              <div>
                <Link to={'/editprofile'}>
                  <button className={s.btn}>Edit Profile</button>
                </Link>
              </div>
            </div>
          </div>
        
       <div className={s.socialcontainer}>
          <span className={s.socialshare}>Picture Gallery</span>
        </div>
        <div className={s.gallery_images}>
          <div className={s.gallery_img}  data_attr="img1">
            <img src={'https://yearbook.sarc-iitb.org'+images['img1']} alt="" />
            <div onClick={handleGalleryClick}>Edit</div>
          </div>
          <div className={s.gallery_img}  data_attr="img2">
            <img src={'https://yearbook.sarc-iitb.org'+images['img2']} alt="" />
            <div onClick={handleGalleryClick}>Edit</div>
          </div>
          <div className={s.gallery_img}  data_attr="img3" >
            <img src={'https://yearbook.sarc-iitb.org'+images['img3']} alt="" />
            <div onClick={handleGalleryClick}>Edit</div>
          </div>
          <div className={s.gallery_img}  data_attr="img4">
            <img src={'https://yearbook.sarc-iitb.org'+images['img4']} alt="" />
            <div onClick={handleGalleryClick}>Edit</div>
          </div>
        </div>

        <div className={s.lineContainer}>
          <div className={s.line}></div>
          <div className={s.socialcontainer}>
            <span className={s.socialshare}>Share with your friends </span>
          </div>
          <div className={s.socialIcons}>
            <FaWhatsapp onClick={shareOnWhatsApp} className={s.socialIcon} />
            <FaFacebook onClick={shareOnFacebook} className={s.socialIcon} />
            <FaCopy onClick={shareOnInstagram} className={s.socialIcon} />
          </div>
        </div>

        <div className={s.tabContainer}>
          <button
            className={activeTab === 1 ? s.activeTab : s.tabButton}
            onClick={() => handleTabClick(1)}
          >
            My Posts
          </button>
          <button
            className={activeTab === 2 ? s.activeTab : s.tabButton}
            onClick={() => handleTabClick(2)}
          >
            Posts for me
          </button>
          <button
            className={activeTab === 3 ? s.activeTab : s.tabButton}
            onClick={() => handleTabClick(3)}
          >
            My Snapshots
          </button>
          {/* <button
            className={activeTab === 4 ? s.activeTab : s.tabButton}
            onClick={() => handleTabClick(4)}
          >
            Tagged Snapshots
          </button> */}
        </div>

        <div className={s.tabContent}>
          {activeTab === 1 && <Tab1Content />}
          {activeTab === 2 && <Tab2Content />}
          {activeTab === 3 && <Tab3Content />}
          {/* {activeTab === 4 && <Tab4Content />} */}
        </div>
      </div>
    
     
      
      </div>
    </>
  );
};

export default Profile;






