import React from 'react';
import Nav from '../components/Nav';
import styled from 'styled-components';
import SideBar from '../components/Feed/SideBar';
import RightBar from '../components/Feed/RightBar';
import PollsContainer from '../components/Polls/PollsContainer';
import styles from './styles/Polls.module.css';
import { useContext, useEffect } from 'react';

function Polls() {
  return (
    <>
      <div className={styles.ParentContainer}>
        <div className={styles.ChildContainer}>
          <SideBar />
          <PollsContainer />
        </div>
        <div className={styles.ChildContainer1}>
          <PollsContainer />
        </div>
      </div>
    </>
  );
}

export default Polls;
