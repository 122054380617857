import React, { useEffect, useState } from 'react';
import './styles/EditProfile.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { colourOptions } from '../extradata.ts';
import { Multiselect } from 'multiselect-react-dropdown';
import { func } from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import MultiSelect from 'react-select';
import useEditProfile from '../hooks/useEditProfile';
import defaultimg from '../assets/default.jpg';
// import {useHistory} from 'react-router-dom';
import { useCurrentUser } from '../hooks/useCurrentUser';
import Swal from 'sweetalert2';

const EditProfile = () => {
  const currentUserData = useCurrentUser();
  if (currentUserData && currentUserData.currentUser) {
    var currentUser = currentUserData.currentUser;
  }
  const { handleSubmit, error, loading, formData } = useEditProfile();
  const [url, setUrl] = useState(defaultimg);
  const [image, setImage] = useState(false);
  const [initialValues, setInitialValues] = useState(formData);
  useEffect(() => {
    if (formData) {
      setUrl(`${formData.profile_image}`);
      setInitialValues({
        ...formData,
        dob: new Date(formData.dob),
        activities: formData.activities.map(activity => {
          return IB_OPTIONS.find(option => option.value === activity);
        }),
      });

      // if (formData) {
      //   initialValues = {
      //     ...formData,
      //     dob: new Date(formData.dob),
      //   };

      // if (formData) {
      //   initialValues.activities = formData.activities.map(activity => {
      //     return IB_OPTIONS.find(option => option.value === activity);
      //   })
    }
  }, [formData, loading]);

  const handleImageEdit = () => {
    document.getElementById('image').click();
  };

  const handleFileChange = async (event, form) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async () => {
        if (file.type === 'image/heic') {
          Swal.fire({
            icon: 'error',
            title: 'Invalid File',
            text: 'Only JPG, JPEG, PNG, or WEBP files are allowed.',
            customClass: {
              container: 'my-swal-container',
              title: 'my-swal-title',
              text: 'my-swal-text',
            },
          }).then(result => {
            window.location.reload();
          });
          return;
        }

        let imageData = reader.result;
        const image = new Image();
        image.src = imageData;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxWidth = 800;
          const maxHeight = 800;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            async compressedBlob => {
              // Convert to JPEG format
              const jpegBlob = await new Promise(resolve => {
                canvas.toBlob(
                  blob => {
                    resolve(blob);
                  },
                  'image/jpeg',
                  0.8,
                );
              });

              const compressedFile = new File([jpegBlob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });

              const url = URL.createObjectURL(compressedFile); // Create a valid URL
              setUrl(url);
              form.setFieldValue('profile_image', compressedFile);
              setImage(true);
            },
            'image/jpeg',
            0.6, // Compression quality (0.6 represents 60% quality)
          );
        };
      };

      reader.readAsDataURL(file);
    }
  };

  if (currentUser === undefined) {
    return <></>;
  }

  return (
    <>
      <div className="editprofilemain">
        {loading && currentUser === undefined ? (
          <p>Loading...</p>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={e => {
              console.log(e);
              handleSubmit(e, image);
              // window.location.reload();
            }}
          >
            <Form>
              <div className="edit-form">
                <div className="profilehead">
                  <div className="proimg">
                    <img src={url} alt=""></img>
                    <div onClick={handleImageEdit}>Edit</div>
                  </div>

                  <Field name="image">
                    {({ field, form }) => (
                      <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={event => handleFileChange(event, form)}
                      ></input>
                    )}
                  </Field>
                  <div class="profilehead-text">
                    <p>{currentUser.username.split('@')[0]}</p>
                    <label htmlFor="tagline">Tagline</label>
                    <Field type="text" name="tagline" />
                  </div>
                </div>
                <div className="profilebody">
                  <div className="section-break">
                    <p className="section-header">Personal Information</p>
                    <div className="section-body">
                      <div className="inputbox">
                        <label htmlFor="name">Name</label>
                        <Field type="text" name="name" required />
                      </div>
                      <div className="inputbox">
                        <label htmlFor="nickname">NickName</label>
                        <Field type="text" name="nickname" />
                        <label htmlFor="dob">DOB</label>
                        <Field name="dob" required>
                          {({ field, form }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={date =>
                                form.setFieldValue(field.name, date)
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className="inputbox">
                        <label htmlFor="email">LDAP Email</label>
                        <Field type="email" name="email" required />
                        <label htmlFor="gender">Gender</label>
                        <MySelect name="gender" options={GENDER} required />
                      </div>
                      <div className="inputbox">
                        <label htmlFor="personal_email">Personal Email</label>
                        <Field
                          className="extracurri"
                          type="email"
                          name="personal_email"
                          required
                        />
                        <label htmlFor="career">Career</label>
                        <MySelect name="career" options={CAREER} required />
                      </div>
                      <div className="inputbox">
                        <label htmlFor="address">Home Address</label>
                        <Field name="address" required>
                          {({ field, form }) => (
                            <textarea
                              type="text"
                              value={field.value}
                              onChange={event =>
                                form.setFieldValue(
                                  field.name,
                                  event.target.value,
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="section-break">
                    <p className="section-header">Hostel</p>
                    <div className="section-body">
                      <div className="inputbox">
                        <label htmlFor="hostel">Hostel</label>
                        <MySelect
                          name="hostel"
                          options={HOSTEL_OPTIONS}
                          required
                        />
                        <label htmlFor="room_no">Room No.</label>
                        <Field type="text" name="room_no" required />
                      </div>
                    </div>
                  </div>
                  <div className="section-break">
                    <p className="section-header">Academic</p>
                    <div className="section-body">
                      <div className="inputbox">
                        <label htmlFor="department">Department</label>
                        <MySelect
                          name="department"
                          options={DEPARTMENT_OPTIONS}
                          required
                        />
                      </div>
                      <div className="inputbox">
                        <label htmlFor="program">Program</label>
                        <MySelect
                          name="program"
                          options={PROGRAM_OPTIONS}
                          required
                        />
                        <label htmlFor="degree">Degree</label>
                        <MySelect
                          name="degree"
                          options={DEGREE_OPTIONS}
                          required
                        />
                      </div>
                      <div className="inputbox">
                        <label htmlFor="join_year">Joining Year</label>
                        <Field type="number" name="join_year" required />
                        <label htmlFor="graduation_year">Graduation Year</label>
                        <Field type="number" name="graduation_year" required />
                      </div>
                    </div>
                  </div>
                  <div className="section-break">
                    <p className="section-header">Extracurricular</p>
                    <div className="section-body">
                      <div className="inputbox">
                        <div className="extracurri">
                          <label htmlFor="activities">Clubs</label>
                          <Field name="activities">
                            {({ field, form }) => (
                              <MultiSelect
                                id="activities"
                                name="activities"
                                isMulti={true}
                                options={IB_OPTIONS}
                                value={field.value}
                                onChange={selectedOptions => {
                                  form.setFieldValue(
                                    field.name,
                                    selectedOptions,
                                  );
                                }}
                                styles={{
                                  container: provided => ({
                                    ...provided,
                                    height: '20%',
                                  }),
                                  control: provided => ({
                                    ...provided,
                                  }),
                                  option: provided => ({
                                    ...provided,
                                    color: 'black',
                                    '&:hover': {
                                      backgroundColor:
                                        'rgba(134, 93, 255, 0.7)',
                                      color: 'white',
                                    },
                                  }),
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </>
  );
};

export default EditProfile;

function MySelect({ options, ...props }) {
  const [field] = useField(props);

  return (
    <>
      <select {...field} {...props}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
}

const CAREER = [
  { value: '', label: 'Choose your career' },
  { label: 'Student', value: 'Student' },
  { label: 'Higher Studies', value: 'Higher studies' },
  { label: 'Software Engineering', value: 'Software' },
  { label: 'AI/ML', value: 'AI/ML' },
  { label: 'Core Engineering', value: 'Core' },
  { label: 'Entrepreneurship', value: 'Entrepreneurship' },
  { label: 'Analytics', value: 'Analytics' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Other', value: 'Other' },
];

const GENDER = [
  { value: '', label: 'Choose your gender' },
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Other', value: 'O' },
  { label: 'Prefer not to say', value: "P"}
];

// const IB_OPTIONS = [
//   { label: 'MoodI', value: 1 },
//   { label: 'Techfest', value: 2 },
//   { label: 'Ecell', value: 3 },
// ];

const IB_OPTIONS = [
  { label: 'None', value: 1 },
  { label: 'AUV', value: 2 },
  { label: 'AZeotropy', value: 3 },
  { label: 'Aakaar', value: 4 },
  { label: 'Aavhan', value: 5 },
  { label: 'Abhyuday', value: 6 },
  { label: 'Academic Council', value: 7 },
  { label: 'Adventure Club', value: 8 },
  { label: 'Aeromodelling Club', value: 9 },
  { label: 'Analytics Club', value: 10 },
  { label: 'Aquatics', value: 11 },
  { label: 'Astronomy Club', value: 12 },
  { label: 'Athletics', value: 13 },
  { label: 'Badminton', value: 14 },
  { label: 'Basketball', value: 15 },
  { label: 'BioTech Club', value: 16 },
  { label: 'Board Games', value: 17 },
  { label: 'Chess', value: 18 },
  { label: 'Consult Club', value: 19 },
  { label: 'Comedy Club', value: 20 },
  { label: 'Cricket', value: 21 },
  { label: 'Culinary Club', value: 22 },
  { label: 'Department Council', value: 23 },
  { label: 'Design Club', value: 24 },
  { label: 'E-Cell', value: 25 },
  { label: 'Electronics and Robotics Club', value: 26 },
  { label: 'Finance Club', value: 27 },
  { label: 'Football Club', value: 28 },
  { label: 'Fourthwall', value: 29 },
  { label: 'GRA', value: 30 },
  { label: 'Hockey', value: 31 },
  { label: 'Hostel Affairs Council', value: 32 },
  { label: 'Hostel Council', value: 33 },
  { label: 'Hult Prize Competition', value: 34 },
  { label: 'IITB Racing', value: 35 },
  { label: 'IITB Student Satellite Program', value: 36 },
  { label: 'ISCP', value: 37 },
  { label: 'InSync', value: 38 },
  { label: 'Innovation Cell', value: 39 },
  { label: 'Insight', value: 40 },
  { label: 'Institute Cultural Council', value: 41 },
  { label: 'Institute Sports Council', value: 42 },
  { label: 'Institute Technical Council', value: 43 },
  { label: 'Kho-Kho', value: 44 },
  { label: 'Lawn Tennis', value: 45 },
  { label: 'StyleUp Club', value: 46 },
  { label: 'Lit Club', value: 47 },
  { label: 'Litzkrieg', value: 48 },
  { label: 'Mars Society India', value: 49 },
  { label: 'Maths and Physics Club', value: 50 },
  { label: 'Mood Indigo', value: 51 },
  { label: 'Music Club', value: 52 },
  { label: 'NCC', value: 53 },
  { label: 'NSS', value: 54 },
  { label: 'PT cell', value: 55 },
  { label: 'Pixels', value: 56 },
  { label: 'Radiance', value: 57 },
  { label: 'Rakshak IITB', value: 58 },
  { label: 'Rang', value: 59 },
  { label: 'Robobtics Club', value: 60 },
  { label: 'Roots', value: 61 },
  { label: 'SARC', value: 62 },
  { label: 'SMP', value: 63 },
  { label: 'STAB', value: 64 },
  { label: 'Saathi', value: 65 },
  { label: 'Saaz', value: 66 },
  { label: 'ShARE', value: 67 },
  { label: 'Silverscreen', value: 68 },
  { label: 'Squash', value: 69 },
  { label: 'Staccato', value: 70 },
  { label: 'Sustainability Cell IITB', value: 71 },
  { label: 'Table Tennis', value: 72 },
  { label: 'Team Shunya', value: 73 },
  { label: 'Techfest', value: 74 },
  { label: 'Tinkerers Lab', value: 75 },
  { label: 'Vaani', value: 76 },
  { label: 'Volleyball', value: 77 },
  { label: 'WeSpeak', value: 78 },
  { label: 'Web and Coding Club', value: 79 },
  { label: 'Weightlifting', value: 80 },
  { label: 'Devcom', value: 81 },
  { label: 'Exofly', value: 82 },
  { label: 'UMIC', value: 83 },
  { label: 'PGAC', value: 84 },
  { label: 'EnPoWER', value: 85 },
  { label: 'PlacementLog', value: 86 },
  { label: 'Career Cell', value: 87 },
  { label: 'CSec', value: 88 },
  { label: 'Mars Rover Team', value: 89 },
  { label: 'UGAC', value: 90 },
  { label: 'Zephyr', value: 91 },
  { label: 'Humanoid', value: 92 },
  { label: 'SSS', value: 93 },
  { label: 'Symphony', value: 94 },
  { label: 'Kritikka', value: 95 },
  { label: 'DAV', value: 96 },
  { label: 'IIT Rocket Team', value: 97 },
  { label: 'BioXClub', value: 98 },
  { label: 'ERC', value: 99 },
  { label: 'Hyperloop', value: 100 },
  { label: 'IIT-BBC', value: 101 },
  { label: 'Literati', value: 102 },
  { label: 'ELIT', value: 103 },
  { label: 'Internship blog', value: 104 },
  { label: 'Chemistry Club', value: 105 },
  { label: 'Enactus', value: 106 },
  {label: 'Energy and Sustainability Club', value: 107},
  {label: 'SPART', value: 108 },
];

const HOSTEL_OPTIONS = [
  { value: '', label: 'Choose your hostel' },
  { value: 'hostel_1', label: 'Hostel 1' },
  { value: 'hostel_2', label: 'Hostel 2' },
  { value: 'hostel_3', label: 'Hostel 3' },
  { value: 'hostel_4', label: 'Hostel 4' },
  { value: 'hostel_5', label: 'Hostel 5' },
  { value: 'hostel_6', label: 'Hostel 6' },
  { value: 'hostel_7', label: 'Hostel 7' },
  { value: 'hostel_8', label: 'Hostel 8' },
  { value: 'hostel_9', label: 'Hostel 9' },
  { value: 'hostel_10', label: 'Hostel 10' },
  { value: 'hostel_11', label: 'Hostel 11' },
  { value: 'hostel_12', label: 'Hostel 12' },
  { value: 'hostel_13', label: 'Hostel 13' },
  { value: 'hostel_14', label: 'Hostel 14' },
  { value: 'hostel_15', label: 'Hostel 15' },
  { value: 'hostel_16', label: 'Hostel 16' },
  { value: 'hostel_17', label: 'Hostel 17' },
  { value: 'hostel_18', label: 'Hostel 18' },
  { value: 'hostel_tansa', label: 'Hostel Tansa' },
  { value: 'qip', label: 'QIP' },
];

const DEGREE_OPTIONS = [
  { value: '', label: 'Choose your Degree' },
  { value: 'FYBS', label: 'Four Year BS' },
  { value: 'BTECH', label: 'Bachelor of Technology' },
  { value: 'MTECH', label: 'Master of Technology' },
  { value: 'DD', label: 'B.Tech. + M.Tech. Dual Degree' },
  { value: 'MSC', label: 'Master of Science' },
  { value: 'PHD', label: 'Doctor of Philosophy' },
  { value: 'BDES', label: 'Bachelor of Design' },
  { value: 'MDES', label: 'Master of Design' },
  { value: 'MPHIL', label: 'Master of Philosophy' },
  { value: 'MMG', label: 'Master of Management' },
  { value: 'MSEx', label: 'M.S. (Exit Degree)' },
  { value: 'MtechEx', label: 'Master of Technology (Exit Degree)' },
  { value: 'MtechPhDDD', label: 'M.Tech. + Ph.D. Dual Degree' },
  { value: 'PC', label: 'Preparatory Course' },
  { value: 'VS', label: 'Visiting Student' },
  { value: 'MPhilEx', label: 'Master of Philosophy (Exit Degree)' },
  { value: 'MScEx', label: 'Master of Science (Exit Degree)' },
  { value: 'MScMTechDD', label: 'M.Sc. + M.Tech. Dual Degree' },
  { value: 'MScPhDDD', label: 'M.Sc. + Ph.D. Dual Degree' },
  { value: 'MPhilPhDDD', label: 'M.Phil. + Ph.D. Dual Degree' },
  { value: 'EMBA', label: 'Executive MBA' },
  { value: 'IMTECH', label: 'Integrated M.Tech.' },
  { value: 'MSCBR', label: 'Master of Science By Research' },
  { value: 'TYMSC', label: 'Two Year M.Sc.' },
  { value: 'FYIMSC', label: 'Five Year Integrated M.Sc.' },
  { value: 'DIIT', label: 'D.I.I.T.' },
  { value: 'DIITEx', label: 'D.I.T.T. (Exit Degree)' },
];

const PROGRAM_OPTIONS = [
  { value: '', label: 'Choose your program' },
  { value: 'ug', label: 'Undergraduate' },
  { value: 'dd', label: 'Dual Degree' },
  { value: 'pg', label: 'Postgraduate' },
  { value: 'idddp', label: 'Inter-Disciplinary Dual Degree' },
];

const DEPARTMENT_OPTIONS = [
  { value: '', label: 'Choose your department' },
  { value: 'Aerospace Engineering', label: 'Aerospace Engineering' },
  { value: 'Animation', label: 'Animation' },
  {
    value: 'Application Software Centre',
    label: 'Application Software Centre',
  },
  { value: 'Applied Geophysics', label: 'Applied Geophysics' },
  {
    value: 'Applied Statistics and Informatics',
    label: 'Applied Statistics and Informatics',
  },
  { value: 'Biomedical Engineering', label: 'Biomedical Engineering' },
  {
    value: 'Biosciences and Bioengineering',
    label: 'Biosciences and Bioengineering',
  },
  { value: 'Biotechnology', label: 'Biotechnology' },
  {
    value: 'Centre for Aerospace Systems Design and Engineering',
    label: 'Centre for Aerospace Systems Design and Engineering',
  },
  {
    value: 'Centre for Distance Engineering Education Programme',
    label: 'Centre for Distance Engineering Education Programme',
  },
  {
    value: 'Environmental Science and Engineering',
    label: 'Environmental Science and Engineering',
  },
  {
    value: 'Centre for Formal Design and Verification of Software',
    label: 'Centre for Formal Design and Verification of Software',
  },
  {
    value: 'Centre for Research in Nanotechnology and Science',
    label: 'Centre for Research in Nanotechnology and Science',
  },
  {
    value: 'Centre for Technology Alternatives for Rural Areas',
    label: 'Centre for Technology Alternatives for Rural Areas',
  },
  {
    value: 'Centre for Urban Science and Engineering',
    label: 'Centre for Urban Science and Engineering',
  },
  {
    value: 'Centre of Studies in Resources Engineering',
    label: 'Centre of Studies in Resources Engineering',
  },
  { value: 'Chemical Engineering', label: 'Chemical Engineering' },
  { value: 'Chemistry', label: 'Chemistry' },
  { value: 'Civil Engineering', label: 'Civil Engineering' },
  { value: 'Climate Studies', label: 'Climate Studies' },
  { value: 'Computer Centre', label: 'Computer Centre' },
  {
    value: 'Computer Science & Engineering',
    label: 'Computer Science & Engineering',
  },
  {
    value: 'Continuing Education Programme',
    label: 'Continuing Education Programme',
  },
  {
    value: 'Corrosion Science and Engineering',
    label: 'Corrosion Science and Engineering',
  },
  {
    value: 'Desai Sethi Centre for Entrepreneurship',
    label: 'Desai Sethi Centre for Entrepreneurship',
  },
  { value: 'Earth Sciences', label: 'Earth Sciences' },
  { value: 'Educational Technology', label: 'Educational Technology' },
  { value: 'Electrical Engineering', label: 'Electrical Engineering' },
  {
    value: 'Energy Science and Engineering',
    label: 'Energy Science and Engineering',
  },
  { value: 'Economics', label: 'Economics' },
  { value: 'Engineering Physics', label: 'Engineering Physics' },
  {
    value: 'Humanities & Social Science',
    label: 'Humanities & Social Science',
  },
  {
    value: 'IITB-Monash Research Academy',
    label: 'IITB-Monash Research Academy',
  },
  { value: 'Industrial Design Centre', label: 'Industrial Design Centre' },
  {
    value: 'Industrial Engineering and Operations Research',
    label: 'Industrial Engineering and Operations Research',
  },
  { value: 'Industrial Management', label: 'Industrial Management' },
  { value: 'Interaction Design', label: 'Interaction Design' },
  {
    value: 'Kanwal Rekhi School of Information Technology',
    label: 'Kanwal Rekhi School of Information Technology',
  },
  { value: 'Material Science', label: 'Material Science' },
  {
    value: 'Materials, Manufacturing and Modelling',
    label: 'Materials, Manufacturing and Modelling',
  },
  { value: 'Mathematics', label: 'Mathematics' },
  { value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
  {
    value: 'Metallurgical Engineering & Materials Science',
    label: 'Metallurgical Engineering & Materials Science',
  },
  {
    label: 'Mobility and Vehicle Design',
    value: 'Mobility and Vehicle Design',
  },
  {
    label: 'National Centre for Aerospace Innovation and Research',
    value: 'National Centre for Aerospace Innovation and Research',
  },
  {
    label: 'National Centre for Mathematics',
    value: 'National Centre for Mathematics',
  },
  { label: 'Physical Education', value: 'Physical Education' },
  { label: 'Physics', value: 'Physics' },
  { label: 'Physics, Material Science', value: 'Physics, Material Science' },
  { label: 'Preparatory Course', value: 'Preparatory Course' },
  { label: 'Reliability Engineering', value: 'Reliability Engineering' },
  {
    label: 'Shailesh J. Mehta School of Management',
    value: 'Shailesh J. Mehta School of Management',
  },
  {
    label: 'Sophisticated Analytical Instrument Facility',
    value: 'Sophisticated Analytical Instrument Facility',
  },
  {
    label: 'Systems and Control Engineering',
    value: 'Systems and Control Engineering',
  },
  {
    label: 'Tata Center for Technology and Design',
    value: 'Tata Center for Technology and Design',
  },
  {
    label: 'Technology and Development',
    value: 'Technology and Development',
  },
  { label: 'Visual Communication', value: 'Visual Communication' },
  {
    label: 'Wadhwani Research Centre for Bioengineering',
    value: 'Wadhwani Research Centre for Bioengineering',
  },
  { label: 'Centre for Policy Studies', value: 'Centre for Policy Studies' },
];
