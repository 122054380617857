import React from 'react';
import Nav from '../components/Nav';
import styled from 'styled-components';
import SideBar from '../components/Feed/SideBar';
import RightBar from '../components/Feed/RightBar';
import { useContext } from 'react';
import NotifContext from '../context/NotifContext';
import PersonalFeedContainer from '../components/Feed/personalFeedContainer';
import NotifContainer from '../components/Notifs/NotifContainer';
import styleNotif from './styles/Feed.module.css';
import styles from './styles/Feed.module.css';
function PersonalizedFeed() {

  const { status, togglePlayPause } = useContext(NotifContext);

  return (
    <>
    <div style={{zIndex: "99"}} className={status ? styleNotif.active : styleNotif.notactive}>
        {/* <NotifContainer /> */}
      </div>
      <div className={styles.ParentContainer}>
        <SideBar />
        <PersonalFeedContainer />
        <RightBar />
      </div>
    </>
  );
}

export default PersonalizedFeed;
