import React, { useState, useEffect } from 'react';
import '../styles/WritePost.css';
import redArrow from '../../assets/redArrow.png';
import { useUploadPosts } from '../../hooks/useUploadPosts';
import { useProfile } from '../../hooks/useProfile';
import { useNavigate } from 'react-router-dom';
import { useAnyUserProfile } from '../../hooks/useAnyUserProfile';
import axios from 'axios';
import { MentionsInput, Mention } from 'react-mentions';
import verified from '../../assets/verified.png'


const WritePostForAnyone = ({
  handleWritePost,
  forId,
  forName,
  forDept,
  handleChildStateChange,
}) => {
  
  function handleWriteClick() {
    handleWritePost();
  }

  
  
  // Add navigate from useNavigate() if navigation is needed
  // const navigate = useNavigate();
  

  let navigate = useNavigate();

  function goToFeed() {
    navigate('/feed');
  }

  // const forProfileData = useAnyUserProfile(forId);

  // if (forProfileData && forProfileData.profile) {
  //   var forProfile = forProfileData.profile;
  // }

  const [textValue, setTextValue] = useState('');
  const [tagFlag, setTagFlag] = useState(false);
  const [query, setQuery] = useState('');
  const [tagindex, setTagIndex] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileImage, setProfileImage] = useState("https://yearbook.sarc-iitb.org/api/Impression_Images/user_82/profile.jpg");
  const [department, setDepartment] = useState("Department");
  const [sendingname, setSendingName] = useState("Name");
  const [sendingid, setSendingId] = useState(82);

  const {
    textContent,
    handleChange,
    handleSubmit,
    isAnonymous,
    handleCheckboxChange,
  } = useUploadPosts(sendingid);


const handleSelectProfile = (profile) => {
  setSelectedProfile(profile);
  setSendingId(profile.id);
  setProfileImage(profile.profile_image);
  setDepartment(profile.department);
  setSendingName(profile.display)
  setSearchInput(profile.display);
  console.log(profile)
  setSearchResults([]);
 
  // navigate(`/profile/${profile.id}`); // Uncomment if navigation is needed
};

const handleSearchChange = async (event) => {
  const inputValue = event.target.value;
  setSearchInput(inputValue);
  
  if (!inputValue.trim()) {
    setSearchResults([]);
    return;
  }

  // Debounce the API call if needed
  try {
    const response = await axios.post('https://yearbook.sarc-iitb.org/api/search/search/', {
      query: {
        multi_match: {
          fields: ['name', 'hostel', 'department', 'degree', 'program'],
          query: inputValue,
          fuzziness: 'AUTO',
          type: 'best_fields',
        },
      },
    });
    const hits = response.data.hits.hits.map(hit => ({
      id: hit._source.id,
      display: hit._source.name,
      department: hit._source.department,
      profile_image: hit._source.profile_image,
      hostel: hit._source.hostel,
    }));
    setSearchResults(hits);
  } catch (error) {
    console.error('Error searching users:', error);
  }
};

const [imageUrl, setImageUrl] = useState('');

useEffect(() => {
  const baseUrl = `https://yearbook.sarc-iitb.org/api/Impression_Images/user_${sendingid}`;
  const checkImage = async (format) => {
    try {
      const url = `${baseUrl}/profile.${format}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setImageUrl(url);
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return false;
      }
      console.error('Error fetching image:', error);
    }
    return false;
  };

  const fetchImage = async () => {
    const foundJpg = await checkImage('jpg');
    if (!foundJpg) {
      await checkImage('png');
    }
    if (!foundJpg) {
      await checkImage('jpeg');
    }
    if (!foundJpg) {
      await checkImage('JPG');
    }
    if (!foundJpg) {
      await checkImage('PNG');
    }
    if (!foundJpg) {
      await checkImage('JPEG');
    }
  };

  fetchImage();
}, [sendingid]);


  const handleTextChange = async event => {

  
    // const value = event.target.value || '';
    // const inputValue = value === '@' ? '@' : value;
    // setTextValue(inputValue);
    setTextValue(event.target.value);
    // console.log("evbent", event.target.value)
    const regex = /@(\S+)/g;
    const matches = [...event.target.value.matchAll(regex)];
    const queries1 = matches.map(match => match[1]);
    const searchTerm = queries1[queries1.length - 1];
    // console.log('queries1', queries1[queries1.length - 1]);
    // console.log('Tag flag here', tagFlag);
    //Below code is for searching users using elastic search
    if (tagFlag) {
      // console.log('searchTerm', searchTerm);
      // console.log('In searchUsers');

      axios.post('https://yearbook.sarc-iitb.org/api/search/search/', {
        query: {
          multi_match: {
            fields: ['name', 'hostel', 'department', 'degree', 'program'],
            query: searchTerm,
            fuzziness: 'AUTO',
            type: 'best_fields',
          },
        },
      })
        .then(response => {
          const foundUsers = response.data.hits.hits.map(hit => hit._source);
          console.log('foundUsers now', foundUsers);

          const test = [];
          for (let i = 0; i < foundUsers.length; i++) {
            console.log('foundUsers[i].name', foundUsers[i].id);
            test.push({
              id: foundUsers[i].id,
              display: foundUsers[i].name,
              department: foundUsers[i].department,
              profile_image: foundUsers[i].profile_image,
              hostel: foundUsers[i].hostel,
            });
          }
          console.log('test', test);
          if (test.length > 0) {
            setUsers(test);
            // console.log(test, 'here are the users');
          }
        })
        .catch(error => {
          console.error('Error searching users:', error);
        });

    }
    if (tagFlag) {
      setQuery(
        event.target.value.substring(
          tagindex + 1,
          event.target.value.length - 1,
        ),
      );
      // console.log('query', query);
    }
    if (event.target.value[event.target.value.length - 1] === ' ') {
      // console.log('In space');
      setTimeout(() => {
        setTagFlag(false);
        setQuery('');
        setTagIndex(0);
      }, 0);
    }
    if (event.target.value[event.target.value.length - 1] === '@') {
      // console.log('In @');
      setTimeout(() => {
        setTagFlag(true);
        setTagIndex(event.target.value.length - 1);
        setQuery(
          event.target.value.substring(
            tagindex + 1,
            event.target.value.length - 1,
          ),
        );
      }, 0);
    }

  };

  const [users, setUsers] = useState([
    {
      display: 'Please Wait, Results are being loaded!!',
      id: '1',
    },
  ]);


  const ProfileData = useProfile();
  const profile = ProfileData.profile;

  // if (!forProfile) {
  //   return <></>;
  // }


  return (
    <>
      {' '}
      <div className="body">
        <div className="main-container">
          <div className="write-post-header">
            <h3>Write Post</h3>
            
            <button onClick={handleWriteClick} className="close-btn">
              Close
            </button>
          </div>
          <div className="search-container" style={{display: "flex",
    justifyContent: "center",
    margin: "20px 1px -10px 0"}}>
             
             <input
 type="text"
 value={searchInput}
 onChange={handleSearchChange}
 placeholder="Search for a profile..."
 className="search-input"
 style={{border: "solid 2px black",     padding: "6px"
 }}

/>
{searchResults.length > 0 && (
<ul style={{
 position: 'absolute',
 width: '100%',
 background: 'white',
 border: '1px solid #ccc',
 zIndex: 1000,
 maxHeight: '300px', 
 top: "7rem",
 overflowY: 'auto',
 marginTop: '2px', // gives some s pace between the input box and the results
 padding: '0', // removes default padding
 listStyleType: 'none', // removes default list styling
 }}>
 {searchResults.slice(0, 6).map(profile => (
   <li key={profile.id} onClick={() => handleSelectProfile(profile)}
     style={{
       padding: '8px',
       cursor: 'pointer',
       borderBottom: '1px solid #eee', // separates each item visually
     }}>
     {`${profile.display} - ${profile.department}, ${profile.hostel}`}
   </li>
 ))}
</ul>
)}


</div>
          <div className="write-post-main">
            <div className="write-post-main-left">
              <img
                src={
                  isAnonymous
                    ? 'https://avatars.githubusercontent.com/u/16786985?v=4'
                    : 'https://yearbook.sarc-iitb.org' + profile.profile_image
                }
                alt="profile pic"
                className="profile-picture"
              />
              <div className="write-post-main-text">
                {isAnonymous ? (
                  <>
                    <p>Anonymous User</p>
                  </>
                ) : (
                  <>
                    <h3 style={{display: 'flex', alignItems: "center"}}>{profile.name}{profile.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}</h3>
                    <p>{profile.department}</p>
                  </>
                )}
              </div>
            </div>

            <div className="write-post-main-middle">
              <img src={redArrow} alt="" />
            </div>

            <div className="write-post-main-right">
              <img
                src={imageUrl}
                alt="loading..."
                className="profile-picture"
              />
              <div className="write-post-main-text">
                {/* <h3 style={{display: "flex", alignItems: "center"}}> */}
                  
                  {/* <MentionsInput
                  placeholder="Write post (Use '@')"
                  value={searchInput}
                  markup="@__display__(__id__)"
                  onChange={(e) => { handleSearchChange(e); handleChange(e.target.value) }}
                  style={{ color: '#865DFF', height: '100%', width: '100%'}}
                  a11ySuggestionsListLabel={
                    'Choose amng the following suggestions'
                  }>
                    
                  <Mention
                    data={users}
                    markup="@__display__(__id__)"
                  />
                </MentionsInput></h3> */}
               
          <p>{sendingname}</p>
                <p>{department}</p>
              </div>
            </div>
          </div>

          <div className="writing-area">
            {/* <form onSubmit={handleSubmit} id="wrapper">
              <input
                name="text"
                value={values.for_id || ''}
                onChange={handleChange}
                style={{ color: 'white', width: '20%', padding: '20px' }}
                type="text"
                placeholder="write user id"
              />

              <textarea
                value={values.post || ''}
                onChange={handleChange}
                placeholder="Enter something here"
                name="post"
                type="text"
                rows={4}
                style={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  resize: 'none',
                  height: 160,
                }}
              />


              <button type="submit" id="button">
                Submit
              </button>
            </form> */}

            <form
              onSubmit={() => {
                goToFeed();
                handleSubmit();
                // handleChildStateChange();
              }}
              id="wrapper">
              {/* <input
                type="text"
                name="for_id"
                style={{
                  display: 'none',
                  color: 'white',
                  width: '20%',
                  padding: '20px',
                }}
                value={values.for_id || ''}
                placeholder="UserID"
              /> */}

              <div
                id="text"
                type="text"
                name="post"
                rows={4}
                style={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  resize: 'none',
                  height: 160,
                }}>
                <MentionsInput
                  placeholder="Write post (Use '@' for mention)"
                  value={textValue}
                  markup="@__display__(__id__)"
                  onChange={(e) => { handleTextChange(e); handleChange(e.target.value) }}
                  style={{ color: '#865DFF', height: '100%' }}
                  a11ySuggestionsListLabel={
                    'Choose amng the following suggestions'
                  }>
                  <Mention
                    data={users}
                    markup="@__display__(__id__)"
                  />
                </MentionsInput>
              </div>

              <label
                style={{
                  width: '20%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1%',
                }}>
                <input
                  className="anony"
                  type="checkbox"

                  checked={isAnonymous}
                  onChange={handleCheckboxChange}
                />
                <span id="checkbox-text">Post Anonymously</span>
              </label>

              <button id="button" type="submit">
                Submit
              </button>
            </form>
          </div>

          <div className="write-post-footer"></div>
        </div>
      </div>
    </>
  );
};

export default WritePostForAnyone;
