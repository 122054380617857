import { useState, useEffect } from 'react';
import { useUser } from './useUser';
import { useProfile } from './useProfile';
import Swal from 'sweetalert2';

const useEditProfile = () => {
  const { user } = useUser();
  const { profile, loading, err } = useProfile();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    tagline: '',
    name: '',
    nickname: '',
    dob: null,
    email: '',
    personal_email: '',
    address: '',
    hostel: '',
    room_no: '',
    department: '',
    program: '',
    degree: '',
    join_year: '',
    graduation_year: '',
    activities: [],
    gender: '',
    career: '',
    profile_image: '/default.jpg',
  });

  useEffect(() => {
    if (profile) {
      setFormData(profile);
    }
  }, [loading, profile]);

  // useEffect(() => {
  //   // Function to fetch data from the API
  //   setIsLoading(true);
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         'http://localhost:8000/api/authenticate/profile/',
  //         {
  //           method: 'GET',
  //           headers: { Authorization: `Bearer ${user.access}` },
  //         },
  //       );
  //       const jsonData = await response
  //         .json()
  //         .then(jsonData => {
  //           console.log('json data', jsonData);
  //           localStorage.setItem('profile', JSON.stringify(jsonData));
  //           setFormData(jsonData);

  //         })
  //         .then(() => {
  //           console.log('Form data is now', formData);
  //         });
  //     } catch (error) {
  //       setError('An error occurred. Please try again later.');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData(); // Call the fetchData function when the component mounts
  // }, [setFormData]);

  const handleSubmit = async (e, image) => {
    try {
      // Display loading state
      setIsLoading(true);

      const method = profile ? 'PUT' : 'POST';
      var formData = new FormData();
      for (const [key, value] of Object.entries(e)) {
        if (key === 'dob') {
          formData.append(key, new Date(value).toISOString().slice(0, 10));
        } else if (key === 'activities') {
          // console.log(JSON.stringify(value.map(activity => activity.value)))
          value.map(activity => formData.append(key, activity.value));
        } else if (key == 'profile_image' && !image) {
        } else if (
          key == 'cover' ||
          key == 'img1' ||
          key == 'img2' ||
          key == 'img3' ||
          key == 'img4'
        ) {
        } else {
          formData.append(key, value);
        }
      }
      formData.forEach((value, key) => console.log(key, value));
      // Simulate an API call or perform actual API request
      const response = await fetch('/api/authenticate/profile/', {
        method: method,
        body: formData,
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
      });


      if(response.status === 200 || response.status === 201){

        Swal.fire({
          title: 'Profile Submitted Successfully',
          text: 'You can navigate to other pages now. If you are unable to see your changes hit ctrl + shift + R (Hard refresh)',
          width: 600,
          icon:'success',
          padding: '3em',
          background: '#fff',
          backdrop: `
            rgba(0,0,123,0.4)
            url("https://media.tenor.com/-AyTtMgs2mMAAAAi/nyan-cat-nyan.gif")
            left bottom 
            no-repeat
          `
        }).then(result => {
          if(result.isConfirmed) {
            window.location.reload();
          }
        })
        }else{
          Swal.fire({
            title: 'Something went wrong',
            text: 'Please contact Web Team',
            width: 600,
            icon:'error',
            padding: '3em',
          }).then(result => {
            if(result.isConfirmed) {
              window.location.href = "/team"
            }
          })
        }
  

      // Reset form and clear error state
      setError(null);

      // Display success message or perform other actions
    } catch (error) {
      // Set error state
      setError('An error occurred. Please try again later.');
      console.error(error);
    } finally {
      // Hide loading state
      localStorage.removeItem('profile');
      setIsLoading(false);
    }
  };

  return {
    formData,
    error,
    isLoading,
    handleSubmit,
  };
};

export default useEditProfile;
