import React, { useState } from 'react';
import styles from '../mycomponents/styles/Poll.module.css';
import { usePollsContext } from '../../hooks/usePollsContext';
import { useAuthContext } from '../../hooks/useAuthContext';

const options = [
  { name: 'Option A', votes: 0 },
  { name: 'Option B', votes: 0 },
  { name: 'Option C', votes: 0 },
  { name: 'Option D', votes: 0 },
];

const Polling = props => {
  // const { dispatch } = usePollsContext();

  const { user } = useAuthContext();
  const [isHovered, setHovered] = useState(false);
  // const [votes, setVotes] = useState(options);
  const votes = [...props.choices];
  const [options, setOptions] = useState(votes);

  // const handleVote = (index) => {
  //     const newVotes = [...votes];
  //     newVotes[index].votes++;
  // };

  const handleVote = async choice_id => {
    if (!user) {
      return;
    }

    const response = await fetch(
      '/api/polls/choice/' + choice_id + '/',
      {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.access}`,
        },
      },
    );
    const json = await response.json();
    const newOptions = [...options];

    newOptions.filter(option => option.id === choice_id)[0].votes =
      json['votes'];
    console.log(newOptions.filter(option => option.id === choice_id)[0].votes);
    setOptions(newOptions);
    console.log(options);

    // if(response.ok){
    //     dispatch({type:"SET_POLL", payload: json})
    // }
  };

  const totalVotes = options.reduce((acc, curr) => acc + curr.votes, 0);

  return (
    <>
      <div className={styles.container}>
        <p className='question'>{props.poll.question}</p>

        {options.map((option, index) => (
          <>
            <div className={styles.option} key={index}>
              <button
                className={styles.glowhover}
                onClick={() => handleVote(option.id)}>
                Vote
              </button>
              <div className={styles.name}>{option.choice_text}</div>
              <div className={styles.bar} onClick={() => handleVote(option.id)}>
                <div
                  className={styles.fill}
                  style={{
                    width: `${(option.votes / totalVotes) * 100}%`,
                    transition: 'width 0.1s ease-in-out',
                  }}></div>
              </div>
              <div className={styles.votes}>{option.votes} votes</div>
            </div>
            <div className={styles.option1} key={index}></div>
            <button
              className={styles.glowhover1}
              onClick={() => handleVote(option.id)}>
            </button>
            <div className={styles.name1}>{option.choice_text}</div>
            <div className={styles.votes1}>{option.votes} </div>
            <div className={styles.bar1} onClick={() => handleVote(option.id)}>
              <div
                className={styles.fill1}
                style={{
                  width: `${(option.votes / totalVotes) * 100}%`,
                  transition: 'width 0.1s ease-in-out',
                }}></div>
            </div>
            {/* <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={`${styles.blackBox} ${isHovered ? styles.visible : ''}`}
              >
                {option.voted_by.map((user, index) => (
                  <div key="index">{user.name}</div> k
                ))
                }
              </div> */}


          </>
        ))}
      </div>
    </>
  );
};

export default Polling;