import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';

export const useProfile = () => {

  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')))
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    async function fetchdata() {
      if (!profile) {
        setLoading(true);
        try {
          const response = await fetch(
            '/api/authenticate/verifyprofile/',
            {
              method: 'GET',
              headers: { Authorization: `Bearer ${user.access}` },
            },
          );
          if (response.ok) {
            const json = await response.json();
            localStorage.setItem('profile', JSON.stringify(json));
            setProfile(json);
          } else {
            
            setError(response.statusText);
          }
        } catch(error) {
          setError('Something went wrong');
        } finally {
          setLoading(false);
        }
      }
    }
    fetchdata();
  }, []);

  return { profile, loading, error };
};