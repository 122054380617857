// import React, { useState } from 'react';
// import Nav from '../components/Nav';
// import styled from 'styled-components';
// import SideBar from '../components/Feed/SideBar';
// import s from './styles/OthersProfile.module.css';
// import RightBar from '../components/Feed/RightBar';
// import DisplayNotif from '../components/Notifs/DisplayNotif';
// import FeedContainer from '../components/Feed/FeedContainer';
// import styles from './styles/Feed.module.css';
// import { useContext } from 'react';
// import NotifContext from '../context/NotifContext';
// import styleNotif from './styles/Feed.module.css';
// import NotifContainer from '../components/Notifs/NotifContainer';
// import { zIndex } from 'material-ui/styles';
// import SwitchButton from './SwitchButton';
// import PersonalFeedContainer from '../components/Feed/personalFeedContainer';
// import WritePostForAnyone from '../components/Feed/WritePostForAnyone';
// import { SortProvider } from '../context/SortContext';  

// import Filter from '../components/Feed/Filter';

// function Feed() {
//   const { status, togglePlayPause } = useContext(NotifContext);
//   const [showInstiFeed, setShowInstiFeed] = useState(true);
//   const [isWritingPost, setIsWritingPost] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const [isWriting, setIsWriting] = useState(false);

//   function handleWritePost() {
//     setIsWriting(!isWriting);
//   }
  
//   const handleButtonClick = () => {
//     setShowPopup(true);
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   function switchFeed(){
//     setShowInstiFeed(!showInstiFeed)
//   }
//   return (
//     <>
//       <div
//         style={{ zIndex: '99' }}
//         className={status ? styleNotif.active : styleNotif.notactive}>
//         {/* <NotifContainer /> */}
//       </div>
//       <SortProvider>
//       <div className={styles.ParentContainer}>
//         <SideBar />
//         <div className={`${styles.mainContainer} ${!showInstiFeed ? styles.mainContainerSubChange : ''}`} >
//           <div className={styles.btnContainer}>

//           {isWriting && (
//         <WritePostForAnyone
//           handleChildStateChange={handleChildStateChange}
//           forId={71}
//           forName={"Akshat Jain"}
//           forDept={"Naya Department"}
//           handleWritePost={handleWritePost}
//         />
//       )}

//         <div>
//                 <button onClick={handleWritePost}  style={{marginTop: "1rem"}} className={s.btn}>Write Post For Someone</button>
//               </div>

//           <div class="deadline-line"
//                 style={{
//                   backgroundColor: "#f0f0f0",
//                   padding: "10px",
//                   borderRadius: "5px",
//                   marginTop: "10px",

//                 }}
//               >
//                 <span
//                   style={{
//                     color: "#865dff",
//                     fontSize: "18px",
//                     fontWeight: "bold",
//                   }}

//                 >To get more info about physical yearbook 
//                 <a 
//                   href="https://docs.google.com/document/d/11920dmFDkZRZV0lYMtKk50ymHSlZX0ysopcIDsd6GOo/edit#heading=h.p49bq9v5ffba" 
//                   style={{ 
//                     textDecoration: "none", 
//                     color: "blue",
//                     // animation: "colorChange 2s infinite"
//                   }}
//                 >
//                   Click Here
//                 </a> || <span style={{color:"red"}}> Final Deadline is 30th June 2024 11:59pm </span> </span>
//             </div>

            
      
//             <SwitchButton switchFeed={switchFeed} buttonValue={!showInstiFeed? "Go to Insti Feed":"Go to Personalized Feed"}/>
//           </div>
//           {showInstiFeed && <FeedContainer/>}
//           {!showInstiFeed && <PersonalFeedContainer/>}
//         </div>
//         {!showInstiFeed && <RightBar />}
//         </div>
//         <div className={styles.ParentContainer1}>
         
//           <div className={styles.mainContainer1}>
//             <div className={styles.btnContainer1}>


//                 <button onClick={handleWritePost} style={{marginTop: "-8.5rem", marginBottom: "6rem"}} className={s.btn}>Write Post For Someone</button>
     
              
//               <SwitchButton switchFeed={switchFeed} buttonValue={!showInstiFeed ? "Go to Insti Feed" : "Go to Personalized Feed"} />
//               {showPopup && (
//                 <div className="popup1">
//                   <button className="close-button1" onClick={closePopup}>
//                     Close
//                   </button>
//                   <div className="popup-content1">
//                     <Filter/>
//                   </div>
//                 </div>
//               )}
//               <button className="circular-button1" onClick={handleButtonClick}>Filters</button>
//               {showPopup && (
//                 <div className="popup">
//                   <button className="close-button" onClick={closePopup}>
//                     Close
//                   </button>
//                   <div className="popup-content">
//                     <Filter/>
//                   </div>
//                 </div>
//               )}

// {isWriting && (
//               <WritePostForAnyone mar
//               handleChildStateChange={handleChildStateChange}k
//               forId={71}
//               forName={"Akshat Jain"}
//               forDept={"Naya Department"}
//               handleWritePost={handleWritePost}
//               />
//               )} 
        




//             </div >

            



//             {showInstiFeed && <FeedContainer/>}
//           {!showInstiFeed && <PersonalFeedContainer/>}
            
//           </div>
          
//         </div>
//       </SortProvider>
//     </>
//   );
// }

// export default Feed;




import React, { useState, useContext } from 'react';
import Nav from '../components/Nav';
import styled from 'styled-components';
import SideBar from '../components/Feed/SideBar';
import s from './styles/OthersProfile.module.css';
import RightBar from '../components/Feed/RightBar';
import DisplayNotif from '../components/Notifs/DisplayNotif';
import FeedContainer from '../components/Feed/FeedContainer';
import styles from './styles/Feed.module.css';
import NotifContext from '../context/NotifContext';
import styleNotif from './styles/Feed.module.css';
import NotifContainer from '../components/Notifs/NotifContainer';
import { zIndex } from 'material-ui/styles';
import SwitchButton from './SwitchButton';
import PersonalFeedContainer from '../components/Feed/personalFeedContainer';
import WritePostForAnyone from '../components/Feed/WritePostForAnyone';
import { SortProvider } from '../context/SortContext';
import Filter from '../components/Feed/Filter';

const keyframesStyle = `
  @keyframes colorChange {
    0% { color: yellow; }
    25% { color: blue; }
    50% { color: purple; }
    75% { color: pink; }
    100% { color: yellow; }
  }
`;

function Feed() {
  const { status, togglePlayPause } = useContext(NotifContext);
  const [showInstiFeed, setShowInstiFeed] = useState(true);
  const [isWritingPost, setIsWritingPost] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isWriting, setIsWriting] = useState(false);

  function handleWritePost() {
    setIsWriting(!isWriting);
  }

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  function switchFeed() {
    setShowInstiFeed(!showInstiFeed);
  }

  return (
    <>
      <style>{keyframesStyle}</style>
      <div
        style={{ zIndex: '99' }}
        className={status ? styleNotif.active : styleNotif.notactive}
      >
        {/* <NotifContainer /> */}
      </div>
      <SortProvider>
        <div className={styles.ParentContainer}>
          <SideBar />
          <div className={`${styles.mainContainer} ${!showInstiFeed ? styles.mainContainerSubChange : ''}`}>
            <div className={styles.btnContainer}>

              {isWriting && (
                <WritePostForAnyone
                  handleChildStateChange={() => {}}
                  forId={71}
                  forName={"Akshat Jain"}
                  forDept={"Naya Department"}
                  handleWritePost={handleWritePost}
                />
              )}

              {/* <div>
                <button onClick={handleWritePost} style={{ marginTop: "1rem" }} className={s.btn}>Write Post For Someone</button>
              </div> */}

              <div className="deadline-line"
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    color: "#865dff",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Register for your own Physical Yearbook 
                  <a
                    href="https://yearbook-offline-sarc.vercel.app/"
                    style={{
                      textDecoration: "none",
                      color: "yellow",
                      animation: "colorChange 2s infinite"
                    }}
                  >
                    -Click Here 
                  </a>
                  <span style={{ color: "red" }}>|| This is Read Only Mode :) </span>
                </span>
              </div>

              <SwitchButton switchFeed={switchFeed} buttonValue={!showInstiFeed ? "Go to Insti Feed" : "Go to Personalized Feed"} />
            </div>
            {showInstiFeed && <FeedContainer />}
            {!showInstiFeed && <PersonalFeedContainer />}
          </div>
          {!showInstiFeed && <RightBar />}
        </div>
        <div className={styles.ParentContainer1}>
          <div className={styles.mainContainer1}>
            <div className={styles.btnContainer1}>
              {/* <button onClick={handleWritePost} style={{ marginTop: "-8.5rem", marginBottom: "6rem" }} className={s.btn}>Write Post For Someone</button> */}
              <SwitchButton switchFeed={switchFeed} buttonValue={!showInstiFeed ? "Go to Insti Feed" : "Go to Personalized Feed"} />
              {showPopup && (
                <div className="popup1">
                  <button className="close-button1" onClick={closePopup}>
                    Close
                  </button>
                  <div className="popup-content1">
                    <Filter />
                  </div>
                </div>
              )}
              <button className="circular-button1" onClick={handleButtonClick}>Filters</button>
              {showPopup && (
                <div className="popup">
                  <button className="close-button" onClick={closePopup}>
                    Close
                  </button>
                  <div className="popup-content">
                    <Filter />
                  </div>
                </div>
              )}
              {isWriting && (
                <WritePostForAnyone
                  handleChildStateChange={() => {}}
                  forId={71}
                  forName={"Akshat Jain"}
                  forDept={"Naya Department"}
                  handleWritePost={handleWritePost}
                />
              )}
            </div>
            {showInstiFeed && <FeedContainer />}
            {!showInstiFeed && <PersonalFeedContainer />}
          </div>
        </div>
      </SortProvider>
    </>
  );
}

export default Feed;
