import React, { useEffect } from 'react';
import styles from './styles/Login.module.css';
import { Link, Navigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import { useState } from 'react';
import { useLogin } from '../hooks/useLogin';
import LoginRegister from '../components/LoginRegister';
import { useProfile } from '../hooks/useProfile';
import styles2 from '../components/styles/LoginSignup.module.css';
import { useForgetPassword } from '../hooks/useForgetPassword';

const ForgetPassword = () => {
  const [credentials, setCredentials] = useState({
    password: '',
    password2: '',
  });

  const key = useParams()

  const { changePassword, error, isLoading } = useForgetPassword();
  const passwordCheck = credentials['password'] === credentials['password2'];

  function handleChange(e) {
    var { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(credentials["password"], key.key)
    await changePassword(credentials['password'], key.key);
  }

  return (
    <>
      {!isLoading && (
        <div className={styles.logbg}>
          <p className={styles.welcome}>SARC PRESENTS</p>
          <h1 className={styles.year}>Yearbook</h1>
          <p style={{ color: 'white', margin: '0.2%' }}>Forget Password</p>
          <div>
            <form className={styles2.form} onSubmit={handleSubmit}>
              <div className={styles2.inputset}>
                <input
                  name="password"
                  type="password"
                  className={styles.inp}
                  placeholder="Password"
                  onChange={handleChange}></input>
              </div>

              <div className={styles2.inputset}>
                <input
                  name="password2"
                  type="password"
                  className={styles.inp}
                  placeholder="Re-Enter Password"
                  onChange={handleChange}></input>
              </div>

              <div className={styles2.inputset}>
                {/* <button type='submit' className={styles2.btn} onClick={(e) => {e.preventDefault();}}>Verify</button> */}
                {
                  <button
                    type="submit"
                    disabled={isLoading || !passwordCheck}
                    className={styles2.Btn}>
                    Change Password
                  </button>
                }
              </div>

              {!passwordCheck && (
                <div className={styles2.inputset}>
                  <div>Passwords Do Not Match</div>
                </div>
              )}

            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgetPassword;
