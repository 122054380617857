const likesPost = {
  // post_id: 0,
  // liked_by: null,
  // likes: 0,
  info: null,
  loading: false,
  error: null,
};

const likePostReducer = (state = likesPost, action) => {
  switch (action.type) {
    case 'LIKE_POST_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'LIKE_POST':
      return {
        ...state,
        // post_id: action.payload.id,
        // liked_by: action.payload.likes,
        // likes: action.payload.like_count,
        info: action.payload,
        loading: false,
        error: null,
      };
    case 'LIKE_POST_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default likePostReducer;
