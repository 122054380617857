import React from 'react';
import styles from '../styles/RightBar.module.css';
import { FcLike } from 'react-icons/fc';
import { BiTime } from 'react-icons/bi';
import Filter from './Filter';
import { useContext } from 'react';
import SortContext from '../../context/SortContext';


function RightBar() {
  const { status, togglePlayPause } = useContext(SortContext);
  return (
    <div className={styles.RightBar}>
      <div className={styles.SortWrapper}>
        <div className={styles.wrapperhead}>
          Sort By
        </div>
        <div>
          <div className={styles.choice} onClick={() => togglePlayPause(true)}>
            <FcLike size="20px" />
            Sort by Likes
          </div>
          <div className={styles.choice} onClick={() => togglePlayPause(false)}>
            <BiTime size="20px" />
            Sort by Time
          </div>
        </div>
      </div>
      <Filter/>
    </div>
  );
}

export default RightBar;
