import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/FeedContainer.module.css';
import DisplayPost from './DisplayPost';
import styled from 'styled-components';
// import { usePosts } from '../../hooks/useFetchPosts';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUser } from '../../hooks/useUser';
import { useSelector } from 'react-redux';

import { usePersonalizePosts } from '../../hooks/useFetchPersonalizePosts';
import { useScrollPosts } from '../../hooks/useScrollPosts';
import { useContext } from 'react';
import SortContext from '../../context/SortContext';

function PersonalFeedContainer() {
  // const [posts, setPosts] = useState([]);
  // const [page, setPage] = useState(1);
  // const { user } = useUser();

  //Below is the previous version of code trying to implement infinite scroll with pagination, uncomment to see

  // console.log("posts");

  // const fetchPosts = () => {
  //   axios.get(`/api/allposts/?page=${page}`,
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${user.access}`
  //         }
  //       })
  //     .then(response => {
  //       setPosts(prevPosts => [...prevPosts, ...response.data]);
  //       setPage(page => page + 1);
  //     })
  //     .catch(error => console.log(error));
  // };

  // useEffect(() => {
  //   fetchPosts();
  // }, []);

  // Code using redux by Nikhil😎
  const { status, togglePlayPause, departments, hostels, clubs } =
    useContext(SortContext);
  usePersonalizePosts(status, departments, hostels, clubs);
  const { handleScroll } = useScrollPosts();
  console.log(useSelector(state => state));
  const { posts, loading, error } = useSelector(state => state.personalizePost);
  const [posts1, setPosts1] = useState(posts);
  // console.log(posts);
  const [uploaded, setUploaded] = useState(0);

  function handleChildStateChange() {
    setUploaded(uploaded + 1);
  }

  useEffect(() => {
    setPosts1(posts);
  }, [status, handleScroll, departments, hostels, clubs, uploaded]);


  // console.log(posts, "hrer are the posts just see htem")





  return (
    <div className={styles.FeedContainer}>
      <div className={styles.Title}>
        <h2
          style={{ zIndex: '0', fontSize: '60px' }}
          data-text="PERSONALIZED FEED">
          PERSONALIZED FEED
        </h2>
        <div className={styles.line1}>
          The Insti feed section is a treasure trove of sentimental messages and
          hilarious photos that will take you on a journey down memory lane.
          Relive the precious moments of your college years, stay connected with
          your beloved batchmates, and share your own updates with ease. Don't
          let those memories fade away - with the Insti feed section, they'll
          stay alive and vibrant forever!
        </div>
      </div>
      {/* <InfiniteScroll
      dataLength={posts.length} //This is important field to render the next data
      next = {useFetchPosts()}
      hasMore={true}
      loader={<h4>Loading...</h4>}
      endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>Yay! You have seen it all</b>
    </p>
  }
    >
      {/* {posts} */}
      {/* {posts.map(post => (
        <DisplayPost key={post.id} post={post} />
      ))}
    </InfiniteScroll> */}


    
    

      <div>
        {loading && <div className={styles.loader}></div>}
        {/* {console.log(posts)} */}
        {posts && posts.map(post => <DisplayPost handleChildStateChange={handleChildStateChange} key={post.id} post={post} />)}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}>
         {!loading && <button onClick={handleScroll} className={styles.nextButton}>
            Next Page {'->'}
          </button>}
        </div>
      </div>
    </div>
  );
}
export default PersonalFeedContainer;
