import React from 'react';
import styled from 'styled-components';
function PostButtons({ text, onClick }) {
  return <Button onClick={onClick}>{text}</Button>;
}

const Button = styled.button`
  background-color: green;
  padding: 10px 20px;
  border-radius: 5px;
`;

export default PostButtons;
