import { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

export const useForgetPassword = () => {

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);


  const changePassword = async (password, key) => {

    setIsLoading(true);

        axios.post('/api/authenticate/forgetpassword/',
        {   
            key: key,
            password: password
          }
        )

        .then((response) =>{
          Swal.fire({
            title: 'Password Changed successfully',
            text: 'You can login now',
            width: 600,
            icon:'success',
            padding: '3em',
            background: '#fff',
            backdrop: `
              rgba(0,0,123,0.4)
              url("https://media.tenor.com/-AyTtMgs2mMAAAAi/nyan-cat-nyan.gif")
              left bottom 
              no-repeat
            `
          }).then(result => {
            if (result.isConfirmed) {
                window.location.href = '/login'
            }
          })
          setError(false);
        })

        
        .catch((error) => {
          Swal.fire({
            title: 'Something went wrong',
            text: 'Please contact the developers.',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(result => {
            if (result.isConfirmed) {
                window.location.href = '/team'
            }
          });
            setError(true);
        })
   
  };
  return { changePassword, isLoading, error };
};
