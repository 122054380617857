import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';
import {
  uploadPost,
  uploadPostRequest,
  uploadPostFailure,
} from '../store/actions/PostActions';
import { useDispatch } from 'react-redux';
var profanity = require('profanity-hindi');


export const useUploadPosts = (for_id, post) => {
  console.log('In upload Posts');
  const { user } = useUser();
  const dispatch = useDispatch();
  const [textContent, setTextContent] = useState('')
  const [isAnonymous, setIsAnonymous] = useState(false);
  
  const handleCheckboxChange = e => {
    setIsAnonymous(e.target.checked) 
    console.log(isAnonymous)
  }

  const handleChange = value =>{
    setTextContent(value)
  }

  const handleSubmit = async e => {
    // e.preventDefault();
    dispatch(uploadPostRequest());

    // if(textContent){
    //   var masking = profanity.maskBadWords(textContent);
    // }else{
    //   masking = ' ';
    // }

    try {
      const response = await axios.post(
        '/api/posts/',
        {
          written_for: Number(for_id),
          content: textContent,
          is_anonymous: Boolean(isAnonymous)
        },
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
            'Content-Type': 'application/json',
          },
        },
      );
      dispatch(uploadPost(response.data));
    } catch (error) {
      console.log(error);
      dispatch(uploadPostFailure(error.message));
    }
  };

  return { textContent, handleChange, handleSubmit, isAnonymous, handleCheckboxChange };
};
