import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';
import { func } from 'prop-types';

export const useAnyUserProfile = id => {
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {user} = useUser()


  useEffect(() => {
    async function fetchdata() {
      setLoading(true);
      const response = await fetch(
        '/api/authenticate/profile/' + id,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${user.access}` },
        },
      );
      var json;
      try {
        json = await response.json();
      } catch (error) {
        console.error('Error Parsing Json', error);
      }

      if (response.ok) {
        setProfile(json);
      } else {
        setError(error);
      }
      setLoading(false);
    }
    fetchdata();
  }, [id]);

  return { profile, loading, error };
};
