// import React, { useState } from 'react';
// import Nav from '../components/Nav';
// import styled from 'styled-components';
// import SideBar from '../components/Impressions/SideBar';
// import RightBar from '../components/Impressions/RightBar';
// import FeedContainer from '../components/Impressions/FeedContainer';
// import styles from './styles/Impression.module.css';
// import Navbar from '../components/Nav1';
// import UploadImpression from '../components/Impressions/UploadImpression';
// import UploadImpressionButton from '../components/Impressions/UploadImpressionButton';
// import { useContext } from 'react';
// import NotifContext from '../context/NotifContext';
// import styleNotif from './styles/Feed.module.css';
// import NotifContainer from '../components/Notifs/NotifContainer';
// import SwitchButton from '../components/Impressions/UploadImpressionButton';
// import { func } from 'prop-types';

// function Impression() {
//   const [uploadState, setuploadState] = React.useState(0);
//   const [isUpload, setisUpload] = useState(false);
//   const { status, togglePlayPause } = useContext(NotifContext);
//   const [page, setPage] = useState(1);
//   const [sortStatus, setSortStatus] = useState(false)

//   function handlePageChange(){
//     setPage(page + 1);
//   }

//   const handleChildStateChange = newState => {
//     console.log('CHNGNG STATE');
//     setuploadState(uploadState + 1);
//   };

//   function handleUploadImpressionClick() {
//     setisUpload(!isUpload);
//   }

//   function handleCloseButton() {
//     setisUpload(!isUpload);
//   }

//   function changeStatus(newStatus){
//     setSortStatus(newStatus);
//     handleChildStateChange();
//   }

//   return (
//     <>
    
//       <div style={{zIndex: "99"}} className={status ? styleNotif.active : styleNotif.notactive}>
//         {/* <NotifContainer /> */}
//       </div>
//       <div className={styles.ParentContainer}>
//         <SideBar />
//         {isUpload && (
//           <UploadImpression
//             handleCloseButton={handleCloseButton}
//             onChildStateChange={handleChildStateChange}
//           />
//         )}
//         <div className={styles.mainContainer}>
//           {/* <UploadImpressionButton
//             handleUploadImpressionClick={handleUploadImpressionClick}
//           /> */}
//           <UploadImpressionButton handleUploadImpressionClick={handleUploadImpressionClick} buttonValue={"Upload SnapShot"}/>
//           <FeedContainer sortStatus={sortStatus} handleChildStateChange={handleChildStateChange} handlePageChange={handlePageChange} key={uploadState} page={page} />
//         </div>
//         <RightBar changeStatus={changeStatus} />
//       </div>

//       <div className={styles.ParentContainer1}>
      
//         {isUpload && (
//           <UploadImpression
//             handleCloseButton={handleCloseButton}
//             onChildStateChange={handleChildStateChange}
//           />
//         )}
//         <div className={styles.mainContainer1}>
//           {/* <UploadImpressionButton
//             handleUploadImpressionClick={handleUploadImpressionClick}
//           /> */}
//           <UploadImpressionButton handleUploadImpressionClick={handleUploadImpressionClick} buttonValue={"Upload SnapShot"} />
//           <FeedContainer handleChildStateChange={handleChildStateChange} key={uploadState} />
//         </div>
//       </div>
//     </>
//   );
// }

// export default Impression;


import React, { useState, useContext } from 'react';
import SideBar from '../components/Impressions/SideBar';
import RightBar from '../components/Impressions/RightBar';
import FeedContainer from '../components/Impressions/FeedContainer';
import styles from './styles/Impression.module.css';
import UploadImpression from '../components/Impressions/UploadImpression';
import UploadImpressionButton from '../components/Impressions/UploadImpressionButton';
import NotifContext from '../context/NotifContext';
import styleNotif from './styles/Feed.module.css';

function Impression() {
  const [uploadState, setUploadState] = useState(0);
  const [isUpload, setIsUpload] = useState(false);
  const { status } = useContext(NotifContext);
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState(false);

  function handlePageChange() {
    setPage(page + 1);
  }

  const handleChildStateChange = () => {
    setUploadState(uploadState + 1);
  };

  function handleUploadImpressionClick() {
    setIsUpload(!isUpload);
  }

  function handleCloseButton() {
    setIsUpload(!isUpload);
  }

  function changeStatus(newStatus) {
    setSortStatus(newStatus);
    handleChildStateChange();
  }

  return (
    <>
      <div style={{ zIndex: "99" }} className={status ? styleNotif.active : styleNotif.notactive}>
        {/* <NotifContainer /> */}
      </div>
      <div className={styles.ParentContainer}>
        <SideBar />
        <div className={styles.mainContainer}>
          {/* <UploadImpressionButton handleUploadImpressionClick={handleUploadImpressionClick} buttonValue={"Upload SnapShot"} /> */}
          <FeedContainer sortStatus={sortStatus} handleChildStateChange={handleChildStateChange} handlePageChange={handlePageChange} key={uploadState} page={page} />
        </div>
        <RightBar changeStatus={changeStatus} />
      </div>
      {isUpload && (
        <UploadImpression
          handleCloseButton={handleCloseButton}
          onChildStateChange={handleChildStateChange}
        />
      )}
    </>
  );
}

export default Impression;

