import React, { createContext, useContext, useState } from 'react';

const SortContext = createContext({
  status: false,
  togglePlayPause: () => {},
  departments: [],
  selectedDepartments: () => {},
  hostels: [],
  selectedHostels: () => {},
  clubs: [],
  selectedClubs: () => {},
});

export const SortProvider = (props) => {
  const [status, setStatus] = useState(false);
  const togglePlayPause = (value) => {setStatus(value);}
  const [departments, setDepartments] = useState([]);
  const selectedDepartments = (value) => {setDepartments(value);}
  const [hostels, setHostels] = useState([]);
  const selectedHostels = (value) => {setHostels(value);}
  const [clubs, setClubs] = useState([]);
  const selectedClubs = (value) => {setClubs(value);}
  return (
    <SortContext.Provider value={{
      status,
      togglePlayPause,
      departments,
      selectedDepartments,
      hostels,
      selectedHostels,
      clubs,
      selectedClubs
    }}>
      {props.children}
    </SortContext.Provider>
  );
};

// const useNotifContext = () => {
//   const context = useContext(NotifContext);

//   if (context === undefined) {
//     throw new Error("useTheme must be used within a ThemeProvider");
//   }
//   return context;
// };

export default SortContext;