import React, { useState } from 'react';
import styles from '../styles/DisplayPost.module.css';
import styled from 'styled-components';
import PostButtons from './PostButtons';
import { FcLike, FcLikePlaceholder } from 'react-icons/fc';
import { BsShare, BsPen } from 'react-icons/bs';
import { FiShare } from 'react-icons/fi';
import { TfiWrite } from 'react-icons/tfi';
import { usePostsContext } from '../../hooks/usePostsContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import feedArrow from '../../assets/feedArrow.png';
import PostComment from './PostComment';
import usePostsDelete from '../../hooks/usePostsDelete';
import { useUser } from '../../hooks/useUser';
import { useProfile } from '../../hooks/useProfile';
import { useLikePostsContext } from '../../hooks/useLikePostsContext';
import { useSelector } from 'react-redux';
import WritePost from './WritePost';
import EditPost from './EditPost';
import { ReactComponent as IconDelete } from '../Impressions/Comments/Assets/images/icon-delete.svg';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import Swal from 'sweetalert2';
import { useAnyUserProfile } from '../../hooks/useAnyUserProfile';
import { Link } from 'react-router-dom';
import verified from '../../assets/verified.png'


function Caption({ caption }) {

  const profile = useProfile();

  const convertMentionsToLinks = text => {
    const mentionRegex = /@(\w+(\s\w+)*)\((\d+)\)/g;

    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const mention = match[0]; // Full mention including @ and ()
      const username = match[1]; // Username with spaces
      const id = match[3]; // ID within ()

      // Add the text before the mention
      parts.push(text.slice(lastIndex, match.index));

      // Add the link
      parts.push(
        <a className={styles.tagLink} key={match.index} href={(id === profile.user)?('/profile'):(`/profile/${id}`)}>
          {mention}
        </a>,
      );

      lastIndex = mentionRegex.lastIndex;
    }

    // Add the remaining text after the last mention
    parts.push(text.slice(lastIndex));

    return parts;
  };

  const convertedCaption = convertMentionsToLinks(caption);

  return <p>{convertedCaption}</p>;
}

function DisplayPost(post, handleChildStateChange, testOurPosts) {
  const currentUser = useUser();

  if (currentUser && currentUser.currentUser) {
    var currentUserID = currentUser.currentUser.id;
  }

  const profileData = useProfile()

  if(profileData && profileData.profile){
    var profile = profileData.profile
  }

  // const { dispatch } = usePostsContext();
  // const { user } = useAuthContext();

  // const handleLike = async () => {
  //   if (!user) {
  //     return;
  //   }

  //   const response = await fetch(
  //     '/api/like/' + props.post.id + '/',
  //     {
  //       method: 'POST',
  //       body: JSON.stringify({}),
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${user.access}`,
  //       },
  //     },
  //   );
  //   const json = await response.json();

  //   if (response.ok) {
  //     dispatch({ type: 'SET_POST', payload: json });
  //   }
  // };

  // const handleDelete = async () => {
  //   if (!user) {
  //     return;
  //   }

  //   const response = await fetch('/api/posts/', {
  //     method: 'DELETE',
  //     body: JSON.stringify({ written_for: props.post.written_for }),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${user.access}`,
  //     },
  //   });

  //   if (response.ok) {
  //     dispatch({
  //       type: 'DELETE_POST',
  //       payload: { written_for: props.post.written_for },
  //     });
  //   }
  // };

  // const currentUser = useCurrentUser();

  // if (currentUser && currentUser.currentUser) {
  //   var currentUserID = currentUser.currentUser.id;
  // }

  

  const [forId, setforId] = useState('');
  const [forName, setforName] = useState('');
  const [forDept, setforDept] = useState('');
  const [isWriting, setIsWriting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  var liked = false;
  const { handleDelete } = usePostsDelete();
  const { handleLike } = useLikePostsContext();
  const [clicked, setClicked] = useState(false);

  function handleWritePost(name, id, dept) {
    setforId(id);
    setforName(name);
    setforDept(dept);
    setIsWriting(!isWriting);
  }

  function handleDeleteClicked(postID) {
    Swal.fire({
      title: 'Are you sure to delete this post?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      heightAuto: 'false',
    }).then(result => {
      if (result.isConfirmed) {
        handleDelete(postID);
        Swal.fire('Deleted!', 'Your Post has been deleted.', 'success');
      }
    });
  }
  function handleEditPost() {
    setIsEditing(!isEditing);
  }


  if(profile === undefined) {
    return <></>
  }

  if(post.post.is_anonymous && testOurPosts){
    return <></>
  }

  return (
    <>
      {isEditing && (
        <EditPost
          handleChildStateChange={handleChildStateChange}
          post={post.post}
          handleEditPost={handleEditPost}
        />
      )}
      {isWriting && (
        <WritePost
          handleChildStateChange={handleChildStateChange}
          forId={forId}
          forName={forName}
          forDept={forDept}
          handleWritePost={handleWritePost}
        />
      )}
      <div id={post.post.id} style={{ marginTop: '5%' }} className={styles.PostContainerWrap}>
        <div className={styles.PostContainer}>
          <div className={styles.PostHeader}>
            <div className={styles.PostHeaderLeft}>
              <img
                src={
                  post.post.is_anonymous
                    ? 'https://avatars.githubusercontent.com/u/16786985?v=4'
                    : 'https://yearbook.sarc-iitb.org' +
                      post.post.written_by_profile.profile_image
                }
                alt="profile pic"
                className={styles.ProfilePic}
              />
              <div className={styles.PostHeaderLeftText}>
                {/* {console.log('The post here is', post)} */}
                {!post.post.is_anonymous ? (
                  <>
                    <Link
                      className={styles.profileLink}
                      to={(post.post.written_by_profile.user === profile.user)?('/profile'):(`/profile/${post.post.written_by_profile.user}`)}>
                      <h3 style={{display: "flex", alignItems: "center"}}>{post.post.written_by}{post.post.written_by_profile.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}</h3>
                      {!post.post.written_by_profile.is_ib && <p>
                        {post.post.written_by_dept} |{' '}
                        {post.post.written_by_year}
                      </p>}
                    </Link>
                  </>
                ) : (
                  <h3>Anonymous User</h3>
                )}
              </div>
            </div>
            <div className={styles.PostHeaderMiddle}>
              {' '}
              <img
                style={{ marginLeft: '35%', width: '15%', height: '5%' }}
                src={feedArrow}
                alt=""
              />{' '}
            </div>
            <div className={styles.PostHeaderRight}>
              <img
                style={{ marginRight: '10%' }}
                src={
                  'https://yearbook.sarc-iitb.org' +
                  post.post.written_for_profile.profile_image
                }
                alt="profile pic"
                className={styles.ProfilePic}
              />
             
              <Link
                className={styles.profileLink}
                style={{ padding: "0.5rem" }}
                to={(post.post.written_for_profile.user === profile.user)?('/profile'):(`/profile/${post.post.written_for_profile.user}`)}>
                <div
                  style={{ textAlign: 'right' }}
                  className={styles.PostHeaderRightText}>
                  <h3>{post.post.written_for_profile.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}{post.post.written_for}</h3>
                  {!post.post.written_for_profile.is_ib && <p>
                    {post.post.written_for_dept} | {post.post.written_for_year}
                    {/* {post.post.written_for_hostel} */}
                  </p>}
                </div>
              </Link>
            </div>
          </div>

          <div className={styles.PostContent}>
            <Caption caption={post.post.content} />
          </div>

          <div className={styles.PostFooter}>
            <div className={styles.PostFooterLeft} style={{ width: '20%' }}>
              <div style={{ position: 'relative' }}>
                <button
                  onMouseEnter={() => setClicked(true)}
                  onMouseLeave={() => setClicked(false)}
                  className={styles.whoLikedBtn}
                  onClick={() => handleLike(post.post.id)}>
                  {post.post.likes.filter(like => like.user == profile.user)
                    .length !== 0 ? (
                    <>
                      <FcLike size={'20px'} className={styles.iconsfc} />
                    </>
                  ) : (
                    <>
                      <FcLikePlaceholder
                        size={'20px'}
                        className={styles.iconsfc}
                      />
                    </>
                  )}
                  {post.post.like_count}
                </button>
                {clicked && (
                  <div className={styles.likedList}>
                    {post.post.likes.map(like => (
                      <div className={styles.likedNames} key={like.id}>
                        {like.name}{' '}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {(profile.user === post.post.written_by_id) && (
                <button
                  onClick={() => handleDeleteClicked(post.post.id)}
                  className={styles.deletePostBtn}>
                  <IconDelete />
                </button>
              )}
              {profile.user === post.post.written_by_id && (
                <button
                  onClick={() => handleEditPost()}
                  className={styles.editPostBtn}
                  style={{ fontSize: '80%' }}>
                  <BsPen /> Edit
                </button>
              )}
            </div>

            {/* <div className={styles.PostFooterRight} style={{ width: '20%' }}>
              {profile.user === post.post.written_by_id ||
              post.post.is_anonymous ? (
                <>
                  <div style={{ width: '50%' }}></div>
                </>
              ) : (
                <button
                  onClick={() =>
                    handleWritePost(
                      post.post.written_by.split(' ')[0],
                      post.post.written_by_id,
                      post.post.written_by_dept,
                    )
                  }
                  style={{ alignItems: 'center', fontSize: '100%' }}>
                  <BsPen style={{ marginTop: '2%', marginRight: '5px' }} />
                  For {post.post.written_by.split(' ')[0]}
                </button>
              )}
              <button
                onClick={() =>
                  handleWritePost(
                    post.post.written_for.split(' ')[0],
                    post.post.written_for_id,
                    post.post.written_for_dept,
                  )
                }
                style={{ alignItems: 'center', fontSize: '100%' }}>
                <BsPen style={{ marginTop: '2%', marginRight: '5px' }} />
                For {post.post.written_for.split(' ')[0]}
              </button>
            </div> */}
          </div>

          {/*           
          <h2>
            {post.post.comments.map(comment => {
              return (
                <div>
                  <h2>
                    {comment.text} written by {comment.written_by} has{' '}
                    {comment.like_count} likes
                  </h2>
                </div>
              );
            })}
          </h2> */}

          {/* <PostComment post={post.post}></PostComment> */}
          {/*In the below line ensure that user details are saved when profile is filled and show delete button accordingly */}
          {/* {post.post.written_by == user.name || post.post.written_for == user.name ? <button onClick={() => handleDelete(post.post.id)}>Delete</button>: null} */}
          {/* <h2> The post has been liked by following ppl: </h2>
          <h2>
            {post.post.likes.map(liked_by => {
              return (
                <div>
                  <h2>
                    {liked_by.name} | {liked_by.department}
                  </h2>
                </div>
              );
            })}
          </h2> */}
        </div>
      </div>
    </>
  );
}

export default DisplayPost;
