import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './useUser';
import {
  fetchPosts,
  fetchPostsRequest,
  fetchPostsFailure,
  deletePost,
  likePost,
} from '../store/actions/PostActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const useFetchPosts = (status, departments, hostels, clubs) => {
  const { post_id, loading, error } = useSelector(state => state.deletePost);
  const { info, loadingLikes, errorLikes } = useSelector(
    state => state.likesPost,
  );
  const { updatedPost, loadingUpdate, errorUpdate } = useSelector(
    state => state.updatedPost,
  );
  const {result, uploaded, loadingUpload, errorUpload} = useSelector(state => state.uploadPost)
  console.log(
    'page',
    useSelector(state => state.scrollPosts.page),
  );
  const { page } = useSelector(state => state.scrollPosts);
  console.log('The actual value and type of page', page, typeof page);
  const { user } = useUser();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPostsRequest());
      try {
        const response = await axios.get(
          '/api/allposts',
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
            params: {
              page: Number(page),
              status: Boolean(status),
              departments: JSON.stringify(departments),
              hostels: JSON.stringify(hostels),
              clubs: JSON.stringify(clubs),
            },
          },
        );
        dispatch(fetchPosts(response.data));
      } catch (error) {
        dispatch(fetchPostsFailure(error.message));
      }
    };

    fetchData();
  }, [dispatch, loading, info, page, updatedPost, result, status, departments, hostels, clubs]);
};
