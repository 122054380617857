import React, { useState, useEffect } from 'react';
import { useUser } from '../../hooks/useUser';
import styles from '../styles/DisplayImpression.module.css';
import styled from 'styled-components';
import PostButtons from './PostButtons';
import axios from 'axios';
import Comments from './Comments/Comments';
import WritePost from '../Feed/WritePost';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { ReactComponent as IconDelete } from './Comments/Assets/images/icon-delete.svg';
import { Link } from 'react-router-dom';
import verified from '../../assets/verified.png'


import Swal from 'sweetalert2';
import { useAnyUserProfile } from '../../hooks/useAnyUserProfile';

import {
  FcLike,
  FcLikePlaceholder,
  FcComments,
  FcUpload,
} from 'react-icons/fc';
import { BsShare, BsPen } from 'react-icons/bs';
import { FiShare } from 'react-icons/fi';
import { TfiWrite } from 'react-icons/tfi';
import { usePostsContext } from '../../hooks/usePostsContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import feedArrow from '../../assets/feedArrow.png';
import ImpressionComment from './ImpressionComment';
import UploadComment from './UploadComment';
import { useProfile } from '../../hooks/useProfile';

function Caption({ caption }) {

  const { profile, loading, error } = useProfile();

  const convertMentionsToLinks = (text) => {
    const mentionRegex = /@(\w+(\s\w+)*)\((\d+)\)/g;

    let parts = [];
    let lastIndex = 0;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const mention = match[0]; // Full mention including @ and ()
      const username = match[1]; // Username with spaces
      const id = match[3]; // ID within ()

      // Add the text before the mention
      parts.push(text.slice(lastIndex, match.index));

      // Add the link
      parts.push(
        <a className={styles.tagLink} key={match.index} href={(id === profile.user)?('/profile'):(`/profile/${id}`)}>
          {mention}
        </a>
      );

      lastIndex = mentionRegex.lastIndex;
    }

    // Add the remaining text after the last mention
    parts.push(text.slice(lastIndex));

    return parts;
  };

  const convertedCaption = convertMentionsToLinks(caption);

  return <p>{convertedCaption}</p>;
}

const DisplayImpression = ({ key, impression, handleChildStateChange }) => {


  const currentUser = useProfile();
  const currentUserID  = currentUser.profile.user;

  const { profile, loading, errorr } = useProfile();

  

  const { user } = useUser();
  // console.log('USER ACCESS', user.access);
  const [clicked, setClicked] = React.useState(false);
  const [likes, setLikes] = React.useState(impression.like_count);
  const [likedBy, setLikedBy] = React.useState(impression.liked_by);
  // console.log('In display impression');
  const [liked, setLiked] = React.useState(false);
  const [error, setError] = React.useState(null);
  const regex = /@([^()]+)\((\d+)\)/g;
  const linkTemplate = 'http://localhost:3000/profile/';

  let match;
  // while ((match = regex.exec(impression.content)) !== null) {
  //   const mention = match[0]; // Full mention including @ and ()
  //   const username = match[1]; // Username without @ and ()
  //   const id = match[2]; // ID within ()
  //   const link = `${linkTemplate}${id}`;
  //   const linkText = `<a href="${link}">${mention}</a>`;
  // impression.content = impression.content.replace(mention, linkText);
  // }



  const like = () => {
    setLiked(!liked);
    axios
      .post(
        '/api/impression/' + impression.id + '/like/',
        null,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => {
        setLikes(response.data.like_count);
        setLikedBy(response.data.liked_by);
        // console.log('Response from Like API', response);
      })
      .catch(error => {
        console.log(error);
      });
  };


  const deleteImpression = () => {
    axios
      .delete('/api/impression/' + impression.id + '/', {
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
      })
      .then(response => {
        console.log('Response from Delete API', response);
        setError(response.data['error']);
        return response.data
      })
      .then(data =>{
        handleChildStateChange()
        // console.log(data)
      })
      .catch(error => {
        console.log(error);
      });
  };

  // console.log(impression.liked_by[0]);

  const [isWrite, setIsWrite] = useState(false);

  function handleWritePost(id) {
    setIsWrite(!isWrite);
  }

  function handleDeleteClicked(postID) {
    Swal.fire({
      title: 'Are you sure to delete this SnapShot?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      heightAuto: 'false',
    }).then(result => {
      if (result.isConfirmed) {
        deleteImpression();
        Swal.fire('Deleted!', 'Your SnapShot has been deleted.', 'success');
      }
    });
  }



  return (
    <>
      {isWrite && (
        <WritePost
          handleWritePost={handleWritePost}
          forName={impression.written_by.name}
          forId={impression.user}
          forDept={impression.written_by.department}
        />
      )}
      <div style={{ marginTop: '5%' }} className={styles.PostContainerWrap}>
        <div className={styles.PostContainer}>
          <div className={styles.PostHeader}>
          <Link className={styles.profileLink} to={(impression.written_by.user === currentUserID)?('/profile'):(`/profile/${impression.written_by.user}`)}>
            <div className={styles.PostHeaderLeft}>
              <img
                src={'https://yearbook.sarc-iitb.org' + impression.written_by.profile_image}
                alt="profile pic"
                className={styles.ProfilePic}
              />
              <div className={styles.PostHeaderLeftText}>
                <h3 style={{display: "flex", alignItems: "center"}}>{impression.written_by.name}
                {impression.written_by.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}
                </h3>
                {!impression.written_by.is_ib && <p>
                  {impression.written_by.department} |{' '}
                  {impression.written_by.graduation_year} | Hostel{' '}
                  {impression.written_by.hostel.split('_')[1]}
                </p>}
              </div>
            </div>
          </Link>
            <div className={styles.postHeaderRight}>
              {currentUserID == impression.user && (
                <button
                  onClick={handleDeleteClicked}
                  className={styles.postHeaderRightBtn}
                >
                  <IconDelete />
                </button>
              )}
            </div>
          </div>

          <div className={styles.caption}>{impression.content && <Caption caption={impression.content}/>}</div>

          <div className={styles.PostContent}>
            <img
              style={{
                maxHeight: '50vw',
                // height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              src={'https://yearbook.sarc-iitb.org' + impression.imgfile}
              alt=""
            />
          </div>

          <div className={styles.PostFooter}>
            <div className={styles.PostFooterLeft}>
              <div style={{ position: 'relative' }}>
                <button
                  className={styles.whoLikedBtn}
                  onClick={like}
                  onMouseLeave={() => setClicked(false)}
                  onMouseEnter={() => setClicked(true)}>
                  {((likedBy.filter((like) => like.user == currentUserID)).length !== 0)?(<><FcLike size={'20px'} className={styles.iconsfc} /></>):(<><FcLikePlaceholder size={'20px'} className={styles.iconsfc} /></>)} {likes}
                </button>
                {clicked && (
                  <div className={styles.likedList}>
                    {likedBy.map(like => (
                      <div className={styles.likedNames} key={like.id}>
                        {like.name}{' '}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.PostFooterRight}>
              <button
                onClick={() => handleWritePost(impression.user)}
                className={styles.PostFooterRightBtn}
              >
                <BsPen size={'18px'} className={styles.iconsfc} />
                Write
              </button>
            </div>

            {/* <div className={styles.PostFooterRight}>

                <button className={styles.PostFooterRightBtn}
                >
                  <FcComments className={styles.iconsfc}/>Comment
                </button>
            </div> */}

            {/* <ImpressionComment impressionId={impression.id} />
            <button onClick={deleteImpression}>Delete</button>
            <UploadComment impressionId={impression.id} />
            {error && <div>{error}</div>} */}
          </div>

          {<Comments currentUserID={currentUserID} impressionId={impression.id}/>}
        </div>
      </div>
    </>
  );
};

export default DisplayImpression;
