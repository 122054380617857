import React from 'react';
import PropTypes from 'prop-types';
import './styles/Navbar.css'
import sarclogo from '../assets/sarclogo.png'
import {IoIosNotifications} from "react-icons/io"
import { useContext } from 'react';
import NotifContext from '../context/NotifContext'



function Nav(props) {
  const { status, togglePlayPause } = useContext(NotifContext);
  const logged_out_nav = (
    <ul>
      {/* <li onClick={() => props.display_form('login')}>login</li>
      <li onClick={() => props.display_form('signup')}>signup</li> */}
      <li> THis is a navbar</li>
    </ul>
  );

  const logged_in_nav = (
    <ul>
      {/* <li onClick={props.handle_logout}>logout</li> */}
      <li> THis is a navbar (Loggedin)</li>
    </ul>
  );

  return (<div className='MainNavbar'>
    <img src={sarclogo} alt="" className='navlogo' />
    <input className="searchbar" type="text" />
    {/* <button onClick={togglePlayPause}> */}
    <IoIosNotifications onClick={togglePlayPause} size="64px"/>
    {/* </button> */}
  </div>);
  
  }

export default Nav;

// Nav.propTypes = {
//   logged_in: PropTypes.bool.isRequired,
//   display_form: PropTypes.func.isRequired,
//   handle_logout: PropTypes.func.isRequired,
// };
