const uploadPostResult = {
  result: null,
  uploaded: false,
  loading: false,
  error: null,
};

const uploadPostReducer = (state = uploadPostResult, action) => {
  switch (action.type) {
    case 'UPLOAD_POST_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
        uploaded: false,
      };

    case 'UPLOAD_POST':
      return {
        ...state,
        result: action.payload,
        loading: false,
        uploaded: true,
        error: null,
      };

    case 'UPLOAD_POST_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default uploadPostReducer;
