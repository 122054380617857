import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUser } from './useUser';
import {
  deletePost,
  deletePostRequest,
  deletePostFailure,
} from '../store/actions/PostActions';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const usePostsDelete = () => {
  console.log('In delete posts hook');
  const { user } = useUser();
  const dispatch = useDispatch();

  const handleDelete = async post_id => {
    dispatch(deletePostRequest());
    try {
      const response = await axios.delete(
        `/api/posts/${post_id}/`,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        },
      );
      dispatch(deletePost(response.data));
    } catch (error) {
      console.log(error);
      dispatch(deletePostFailure(error.message));
    }
  };

  return { handleDelete };
};

export default usePostsDelete;
