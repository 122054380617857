import React, { useState, useEffect } from 'react';
import '../styles/WritePost.css';
import redArrow from '../../assets/redArrow.png';
import { useUpdatePost } from '../../hooks/useUpdatePost';
import { useProfile } from '../../hooks/useProfile';
import axios from 'axios';
import { useAnyUserProfile } from '../../hooks/useAnyUserProfile';
import { MentionsInput, Mention } from 'react-mentions';
import verified from '../../assets/verified.png'


const WritePost = ({ post, handleEditPost, handleChildStateChange }) => {
  const {
    textContent,
    handleChange,
    handleSubmit,
    isAnonymous,
    handleCheckboxChange,
  } = useUpdatePost(post);

  function handleEditClick() {
    handleEditPost();
  }

  var userprofile = useProfile();

  // console.log(userprofile);

  const ProfileData = useProfile();
  const profile = ProfileData.profile;

  const forProfileData = useAnyUserProfile(post.written_for_id);

  if (forProfileData && forProfileData.profile) {
    var forProfile = forProfileData.profile;
  }

  const [textValue, setTextValue] = useState(post.content);
  const [tagFlag, setTagFlag] = useState(false);
  const [query, setQuery] = useState('');
  const [tagindex, setTagIndex] = useState(0);

  const handleTextChange = async event => {
    // const value = event.target.value || '';
    // const inputValue = value === '@' ? '@' : value;
    // setTextValue(inputValue);
    setTextValue(event.target.value);
    // console.log("evbent", event.target.value)
    const regex = /@(\S+)/g;
    const matches = [...event.target.value.matchAll(regex)];
    const queries1 = matches.map(match => match[1]);
    const searchTerm = queries1[queries1.length - 1];
    // console.log('queries1', queries1[queries1.length - 1]);
    // console.log('Tag flag here', tagFlag);
    //Below code is for searching users using elastic search
    if (tagFlag) {
      // console.log('searchTerm', searchTerm);
      // console.log('In searchUsers');

      axios.post('https://yearbook.sarc-iitb.org/api/search/search/', {
        query: {
          multi_match: {
            fields: ['name', 'hostel', 'department', 'degree', 'program'],
            query: searchTerm,
            fuzziness: 'AUTO',
            type: 'best_fields',
          },
        },
      })
        .then(response => {
          const foundUsers = response.data.hits.hits.map(hit => hit._source);
          // console.log('foundUsers now', foundUsers);

          const test = [];
          for (let i = 0; i < foundUsers.length; i++) {
            // console.log('foundUsers[i].name', foundUsers[i].id);
            test.push({
              id: foundUsers[i].id,
              display: foundUsers[i].name,
              department: foundUsers[i].department,
              profile_image: foundUsers[i].profile_image,
              hostel: foundUsers[i].hostel,
            });
          }
          // console.log('test', test);
          if (test.length > 0) {
            setUsers(test);
            // console.log(test, 'here are the users');
          }
        })
        .catch(error => {
          console.error('Error searching users:', error);
        });

    }
    if (tagFlag) {
      setQuery(
        event.target.value.substring(
          tagindex + 1,
          event.target.value.length - 1,
        ),
      );
      // console.log('query', query);
    }
    if (event.target.value[event.target.value.length - 1] === ' ') {
      // console.log('In space');
      setTimeout(() => {
        setTagFlag(false);
        setQuery('');
        setTagIndex(0);
      }, 0);
    }
    if (event.target.value[event.target.value.length - 1] === '@') {
      // console.log('In @');
      setTimeout(() => {
        setTagFlag(true);
        setTagIndex(event.target.value.length - 1);
        setQuery(
          event.target.value.substring(
            tagindex + 1,
            event.target.value.length - 1,
          ),
        );
      }, 0);
    }
  };

  const [users, setUsers] = useState([
    {
      display: 'Please Wait, Results are being loaded!!',
      id: '1',
    },
  ]);

  if (!forProfile) {
    return <></>;
  }

  return (
    <>
      {' '}
      <div className="body">
        <div className="main-container">
          <div className="write-post-header">
            <h3>Edit Post</h3>
            <button onClick={handleEditClick} className="close-btn">
              Close
            </button>
          </div>

          <div className="write-post-main">
            <div className="write-post-main-left">
              <img
                src={
                  isAnonymous
                    ? 'https://avatars.githubusercontent.com/u/16786985?v=4'
                    : 'https://yearbook.sarc-iitb.org' + profile.profile_image
                }
                alt="profile pic"
                className="profile-picture"
              />
              <div className="write-post-main-text">
                {isAnonymous ? (
                  <>
                    <p>Anonymous User</p>
                  </>
                ) : (
                  <>
                    <h3 style={{display: 'flex', alignItems: "center"}}>{post.written_by}{post.written_by_profile.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}</h3>
                    <p>{post.written_by_dept}</p>
                  </>
                )}
              </div>
            </div>

            <div className="write-post-main-middle">
              <img src={redArrow} alt="" />
            </div>

            <div className="write-post-main-right">
              <img
                src={
                  'https://yearbook.sarc-iitb.org' +
                  forProfile.profile_image
                }
                alt="profile pic"
                className="profile-picture"
              />
              <div className="write-post-main-text">
                <h3 style={{display: 'flex', alignItems: "center"}}>{post.written_for_profile.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}{post.written_for}</h3>
                <p>{post.written_for_dept}</p>
              </div>
            </div>
          </div>

          <div className="writing-area">
            {/* <form onSubmit={handleSubmit} id="wrapper">
              <input
                name="text"
                value={values.for_id || ''}
                onChange={handleChange}
                style={{ color: 'white', width: '20%', padding: '20px' }}
                type="text"
                placeholder="write user id"
              />

              <textarea
                value={values.post || ''}
                onChange={handleChange}
                placeholder="Enter something here"
                name="post"
                type="text"
                rows={4}
                style={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  resize: 'none',
                  height: 160,
                }}
              />


              <button type="submit" id="button">
                Submit
              </button>
            </form> */}

            <form
              onSubmit={() => {
                handleSubmit();
                handleChildStateChange();
              }}
              id="wrapper"
            >
              {/*                 
              <input
                type="text"
                name="for_id"
                onChange={handleChange}
                style={{
                  display: 'none',
                  color: 'white',
                  width: '20%',
                  padding: '20px',
                }}
                value={values.for_id || ''}
                placeholder="UserID"
              /> */}

              <div
                id="text"
                type="text"
                name="post"
                rows={4}
                style={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  resize: 'none',
                  height: 160,
                }}
              >
                <MentionsInput
                  placeholder="Add a Comment (Use '@' for mention and '&' for emojis)"
                  value={textValue}
                  markup="@__display__(__id__)"
                  onChange={e => {
                    handleTextChange(e);
                    handleChange(e.target.value);
                  }}
                  style={{ color: '#865DFF', height: '100%' }}
                  a11ySuggestionsListLabel={
                    'Choose amng the following suggestions'
                  }
                >
                  <Mention
                    style={{ color: 'black' }}
                    data={users}
                    markup="@__display__(__id__)"
                  />
                </MentionsInput>
              </div>

              <label
                style={{
                  width: '20%',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '1%',
                }}
              >
                <input
                  className="anony"
                  type="checkbox"
                  checked={isAnonymous}
                  onChange={handleCheckboxChange}
                />
                <span id="checkbox-text">Post Anonymously</span>
              </label>

              <button id="button" type="submit">
                Submit
              </button>
            </form>
          </div>

          <div className="write-post-footer"></div>
        </div>
      </div>
    </>
  );
};

export default WritePost;
