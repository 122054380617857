import React, { useState } from 'react';
import Nav from '../components/Nav';
import styled from 'styled-components';
import SideBar from '../components/Feed/SideBar';
import RightBar from '../components/Impressions/RightBar';
import FeedContainer from '../components/Impressions/FeedContainer';
import styles from './styles/Impression.module.css';
import Navbar from '../components/Nav1';
import UploadImpression from '../components/Impressions/UploadImpression';
import UploadImpressionButton from '../components/Impressions/UploadImpressionButton';
import { useContext } from 'react';
import NotifContext from '../context/NotifContext';
import styleNotif from './styles/Feed.module.css';
import NotifContainer from '../components/Notifs/NotifContainer';
import SwitchButton from '../components/Impressions/UploadImpressionButton';
import { func } from 'prop-types';
import TaggedImpressions from '../components/Impressions/TaggedImpressions';

function TaggedSnapshots({userID}) {
    const [uploadState, setuploadState] = React.useState(0);
    const [isUpload, setisUpload] = useState(false);
    const { status, togglePlayPause } = useContext(NotifContext);

    const handleChildStateChange = newState => {
        console.log('CHNGNG STATE');
        setuploadState(uploadState + 1);
    };

    function handleUploadImpressionClick() {
        setisUpload(!isUpload);
    }

    function handleCloseButton() {
        setisUpload(!isUpload);
    }

    return (
        <>
            <div className={styles.ParentContainer}>
                <TaggedImpressions userID={userID}/>
            </div>
        </>
    );
}

export default TaggedSnapshots;