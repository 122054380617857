// import React from 'react';
// import Login from './pages/Login';
// import Profile from './pages/Profile';
// import HomePage from './pages/Homepage';
// import Navbar from './components/Nav1';
// import Signup from './pages/Signup';import Popup from './pages/EditProfile';
// import Feed from './pages/Feed';
// import UploadPost from '../src/components/Feed/UploadPost';
// // import PostForm from './components/PostForm';
// import '@fontsource/baloo-bhai-2';
// import '@fontsource/inter';
// import Logout from './pages/Logout';
// import { useProfile} from './hooks/useProfile';
// import { useUser } from './hooks/useUser';
// import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
// import PersonalizedFeed from './pages/PersonalizedFeed';
// import Polls from './pages/Polls';
// import Impression from './pages/Impression'
// import axios from 'axios';
// import UserRestrictedRoute from './components/UserRestrictedRoute';
// import ProfileRestrictedRoute from './components/ProfileRestrictedRoute';
// import VerifyEmailView from './pages/VerifyEmailView';
// import Footer from './components/Footer'
// import Devteam from './pages/Devteam';
// import OthersProfile from './pages/OthersProfile';
// import SendForgetPasswordEmail from './pages/SendForgetPasswordEmail';
// import ForgetPassword from './pages/ForgetPassword';
// import DeadlineGone from './pages/deadlineGone';


// function App() {
//   return (
//     <>
//       <Navbar />
//       <Routes>
//         <Route path="/" element={<HomePage />} />
//         <Route path="/login" element={<Login />} />
//         {/* <Route path="/" element={<DeadlineGone />} /> */}
//         <Route path="/team" element={<Devteam />} exact="true"/>
//         <Route path="/team" element={<Devteam />} />
//         <Route path="/signup" element={<Signup />} />
//         <Route path="/logout" element={<Logout />} exact="true"/>
//         <Route path="/verify-email/:key" element={<VerifyEmailView />} />
//         <Route path="/team" element={<Devteam />} exact="true"/>
//         <Route path="/sendforgetPasswordemail" element={<SendForgetPasswordEmail />} exact="true"/>
//         <Route path="/forgetpassword/:key" element={<ForgetPassword />}/>
        
//         <Route element={<UserRestrictedRoute/>}>
//           <Route path="/editprofile" element={<Popup />} exact="true"/>        
//         </Route>

//         <Route element={<ProfileRestrictedRoute/>}>
//           <Route path="/feed" element={<Feed />} exact="true"/>
//           <Route path="/personalizedfeed" element={<PersonalizedFeed />} exact="true"/>
//           <Route path="/profile" element={<><Profile /><Footer /></>} exact="true"/>
//           <Route path="/impression" element={<Impression />} exact="true"/>
//           <Route path="/polls" element={<><Polls /><Footer /></>} exact="true"/>
//           <Route path="/uploadPost" element={<UploadPost />} exact="true"/>
//           {/* <Route path="/team" element={<Devteam />} exact="true"/> */}
//           <Route path="/profile/:id" element={<><OthersProfile /><Footer/></>} exact="true"/>
//         </Route>
//       </Routes>
//     </>
//   );
// }

// export default App;


import React from 'react';
import Login from './pages/Login';
import Profile from './pages/Profile';
import HomePage from './pages/Homepage';
import Navbar from './components/Nav1';
import Signup from './pages/Signup';import Popup from './pages/EditProfile';
import Feed from './pages/Feed';
import UploadPost from '../src/components/Feed/UploadPost';
// import PostForm from './components/PostForm';
import '@fontsource/baloo-bhai-2';
import '@fontsource/inter';
import Logout from './pages/Logout';
import { useProfile} from './hooks/useProfile';
import { useUser } from './hooks/useUser';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import PersonalizedFeed from './pages/PersonalizedFeed';
import Polls from './pages/Polls';
import Impression from './pages/Impression'
import axios from 'axios';
import UserRestrictedRoute from './components/UserRestrictedRoute';
import ProfileRestrictedRoute from './components/ProfileRestrictedRoute';
import VerifyEmailView from './pages/VerifyEmailView';
import Footer from './components/Footer'
import Devteam from './pages/Devteam';
import OthersProfile from './pages/OthersProfile';
import SendForgetPasswordEmail from './pages/SendForgetPasswordEmail';
import ForgetPassword from './pages/ForgetPassword';
import DeadlineGone from './pages/deadlineGone';
import Privacy from './pages/Privacy';
import DeleteMe from './pages/DeleteUser';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path= "/privacy" element={<Privacy/>} />
        <Route path= "/deletemydata" element={<DeleteMe/>} />
        {/* <Route path="/" element={<DeadlineGone />} /> */}
        <Route path="/team" element={<Devteam />} exact="true"/>
        {/*<Route path="/team" element={<Devteam />} />*/}
        <Route path="/signup" element={<Signup />} />
        <Route path="/logout" element={<Logout />} exact="true"/>
        <Route path="/verify-email/:key" element={<VerifyEmailView />} />
        {/* <Route path="/team" element={<Devteam />} exact="true"/> */}
        <Route path="/sendforgetPasswordemail" element={<SendForgetPasswordEmail />} exact="true"/>
        <Route path="/forgetpassword/:key" element={<ForgetPassword />}/>
        
        <Route element={<UserRestrictedRoute/>}>
          <Route path="/editprofile" element={<Popup />} exact="true"/>        
        </Route>

        <Route element={<ProfileRestrictedRoute/>}>
           <Route path="/feed" element={<Feed />} exact="true"/>
          <Route path="/personalizedfeed" element={<PersonalizedFeed />} exact="true"/>
          <Route path="/profile" element={<><Profile /><Footer /></>} exact="true"/>
          <Route path="/impression" element={<Impression />} exact="true"/>
          <Route path="/polls" element={<><Polls /><Footer /></>} exact="true"/>
          {/* <Route path="/uploadPost" element={<UploadPost />} exact="true"/>  */}
          {/* <Route path="/team" element={<Devteam />} exact="true"/> */}
          <Route path="/profile/:id" element={<><OthersProfile /><Footer/></>} exact="true"/> 
        </Route>
      </Routes>
    </>
  );
}

export default App;

