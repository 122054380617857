import React, { useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/PollsContainer.module.css';
import DisplayPoll from './DisplayPoll';
import styled from 'styled-components';
import { usePolls } from '../../hooks/usePolls';

function PollsContainer() {
  const { polls } = usePolls();

  const formUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSee6xz9MMty63p5jiwBqLJ66B74ZI7FXqkB0s_Wmszi9LnmQQ/viewform?embedded=true';

  const handleLinkClick = event => {
    event.preventDefault();
    window.open(formUrl);
  };

  return (
    <>
      <div className={styles.FeedContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.Title}>
            <h2 style={{ zIndex: '0' }} data-text="POLLS">
              POLLS
            </h2>

            <div className={styles.line1}>
              {' '}
              Looking for a fun and engaging way to connect with your fellow
              classmates 🤩? Look no further than the polls section of our
              yearbook! Our polls are a lighthearted and enjoyable way to share
              your opinions and learn more about your peers. So don't miss out
              on the chance to participate in this fun and interactive section
              of our yearbook!
              <br></br>
            </div>
          </div>
          <div className={styles.pollsformcontainer}>
            <span className={styles.pollsformtext}>
              Want to add a poll on the website? Fill this{' '}
              <a
                className={styles.formLink}
                style={{ color: 'orange', textDecorationLine:"underline" }}
                href={formUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}>
                {' '}
                Form
              </a>
            </span>
          </div>


          {!polls.polls && <><div className={styles.loader}></div></>}

          {polls.polls &&
            polls.polls.map(poll => (
              <DisplayPoll
                key={poll.id}
                poll={poll}
                choices={polls.choices.filter(
                  choice => choice.poll === poll.id,
                )}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default PollsContainer;
