import react from 'react';
import styles from '../styles/PopularUsers.module.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../../hooks/useUser';
import { Link } from 'react-router-dom';
import { useProfile } from '../../hooks/useProfile';
import verified from '../../assets/verified.png'


function RecommendedUsers() {
  const [recommendedUsers, setrecommendedUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { user } = useUser();


  const userProfileData = useProfile();

  if(userProfileData && userProfileData.profile){
    var profile = userProfileData.profile;
  }

  useEffect(() => {
    // console.log('recommended users loaded');
    if (!user) {
      setError('User not logged in');
      return;
    }
    // console.log('recommended users');
    if (recommendedUsers) {
      // console.log('Mat aa bhai');
      setrecommendedUsers(recommendedUsers);
      return;
    } else {
      setLoading(true);
      axios
        .get('/api/authenticate/recommendedProfiles/', {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        })
        .then(response => {
          // console.log(response, 'response here');
          return response.data;
        })
        .then(data => {
          // console.log(data, 'data from backend');
          setrecommendedUsers(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [recommendedUsers]);

  // console.log(recommendedUsers, 'working here');

  if (recommendedUsers === undefined) {
    return (
      <div className={styles.loading}>
        <h1>Loading...</h1>
      </div>
    );
  }

  if(profile === undefined) {
    return (
          <div className={styles.loading}>
            <h1>Loading...</h1>
          </div>
      );
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <h3
          style={{
            textAlign: 'center',
            marginTop: '10%',
            color: '#865DFF',
            fontSize: '23px',
            width: '100%',
          }}>
          Recommended Users
        </h3>
        <div className={styles.PostHeaderLeft}>
          <ul className={styles.userContainer}>
            {recommendedUsers.map(user => {
              return (
                <>
                <li className={styles.userListLi}>
                  <Link to={(user.user !== profile.user)?(`/profile/${user.user}`):('/profile')} className={styles.containUser}>
                    <img
                      src={'https://yearbook.sarc-iitb.org' + user.profile_image}
                      alt="profile pic"
                      className={styles.ProfilePic}
                    />
                    <div className={styles.PostHeaderLeftText}>
                      <h3 style={{ color: 'white', display: "flex", alignItems: "center" }}>{user.name}{user.is_ib && (
                  <>
                    <span style={{ marginLeft: "5px" }}>
                      <img
                        src={verified}
                        alt="Blue Tick"
                        style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                      />
                    </span>
                  </>
                )}</h3>
                      {!user.is_ib && <p style={{ color: '#865DFF' }}>{user.department}</p>}
                    </div>
                  </Link>
                </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default RecommendedUsers;
