const PersonalizePost = {
    posts: [],
    loading: false,
    error: null,
}

const personalizePostReducer = (state = PersonalizePost, action) => {
    switch (action.type) {
        case 'PERSONALIZE_POST_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'PERSONALIZE_POST':
            console.log('GOING TO UPDATE STATE', state.posts, action.payload);
            return {
                ...state,
                posts: action.payload,
                loading: false,
                error: null,
            };
        case 'PERSONALIZE_POST_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default personalizePostReducer;