import React from 'react';
import styles from '../styles/SideBar.module.css';
import axios from 'axios';
import { useState } from 'react';
import PopularUsers from '../Feed/PopularUsers';
import RecommendedUsers from '../Feed/RecommendedUsers';
function SideBar() {
  const [loading, setLoading] = useState(true);
  return (
    <div className={styles.SideBar}>

      <RecommendedUsers/>

      {/* <PopularUsers/> */}
    </div>
  );
}

// function updateUserData() {
//   var userData = localStorage.getItem('users')
//   console.log(userData)

//   if (!userData) {
//     axios.get('/authenticate/users/')
//       .then(response => {
//         localStorage.setItem('users', JSON.stringify(response.data))
//         return localStorage.getItem('users')
//       })
//       .catch(err => {
//         console.log(err)
//       })

//     return localStorage.getItem('users')
//   }
// }

export default SideBar;
