import React from 'react';
import styles from './styles/LoginSignup.module.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function LoginRegister({
  handleChange,
  handleSubmit,
  error,
  passwordcheck,
  isLoading,
  islogin,
}) {
  // const [isStudent, setIsStudent] = useState(true);

  if(error === 'Verification Email Sent, Please Check your WebMail' && !isLoading){
    Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Verification Email Sent',
        text: 'Please Check your WebMail',
        showConfirmButton: false,
        timer: 2500
    }).then(() =>{
        window.location.href = '/login';
    })
  }

  return (
    <div>
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* <div className={styles.inputset} onClick={() => setIsStudent(!isStudent)}>
                    <div className={isStudent ? styles.selectedbox : styles.selectbox}>
                        Student
                    </div>
                    <div className={isStudent ? styles.selectbox : styles.selectedbox} onClick={() => setIsStudent(!isStudent)}>
                        Alumni
                    </div>
                </div> */}

        <div className={styles.inputsetspecial}>
          <input
            name="email"
            type="text"
            className={`${styles.inp} ${styles.email}`}
            placeholder="Roll Number "
            onChange={handleChange}></input>
          <select
            name="emailtype"
            id="emailtype"
            className={`${styles.emailtype} ${styles.selectWrapper}`}
            onChange={handleChange}>
            <option value="@iitb.ac.in" default>
              @iitb.ac.in
            </option>
            <option value="@iitbombay.org">@iitbombay.org</option>
          </select>
        </div>

        <div className={styles.inputset}>
          <input
            name="password"
            type="password"
            className={styles.inp}
            placeholder="Password"
            onChange={handleChange}></input>
        </div>

        

        {islogin ? (<>
          <Link style={{marginLeft: "5%"}} to="/sendforgetPasswordemail">
              Forgot Password ?
          </Link>
        </>) : (
          <div className={styles.inputset}>
            <input
              name="password2"
              type="password"
              className={styles.inp}
              placeholder="Re-Enter Password"
              onChange={handleChange}></input>
          </div>
        )}

        {error !== 'User already exists' && (
          <div className={styles.inputset}>
            {/* <button type='submit' className={styles.btn} onClick={(e) => {e.preventDefault();}}>Verify</button> */}
            {
              <button
                type="submit"
                disabled={isLoading || !passwordcheck}
                className={styles.Btn}>
                {islogin ? 'Login' : 'Verify'}
              </button>
            }
          </div>
        )}
        {!passwordcheck && (
          <div className={styles.inputset}>
            <div>Passwords Do Not Match</div>
          </div>
        )}
        {error && (
          <div className={styles.inputset}>
            <div>{error}</div>
          </div>
        )}
        {error === 'User already exists' && (
          <>
            <Link to="/login">
              <div className={styles.inputset}>
                <button className={styles.Btn}>Login here</button>
              </div>
            </Link>
          </>
        )}
      </form>
    </div>
  );
}

export default LoginRegister;
