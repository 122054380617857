import React, { useState, useEffect } from 'react';
import './Styles/App.css';
import Comment from './Comment';
import AddComment from './AddComment';
import axios from 'axios';
import { useUser } from '../../../hooks/useUser';

const Comments = props => {
  const [comment, setComment] = React.useState([]);
  const { user } = useUser();
  const [liked, setLiked] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);

  function handleChildStateChange() {
    setLiked(!liked);
  }

  function handleDeleteComment() {
    setDeleteComment(!deleteComment);
  }

  useEffect(() => {
    axios
      .get(
        '/api/comments/impression/' +
          props.impressionId +
          '/',
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        },
      )
      .then(response => {
        console.log('Response from Comments API', response.data);
        return response.data;
      })
      .then(data => {
        console.log('setting Impressions Comments');
        setComment(data);
        console.log('comments', comment);
      });
  }, [props.impressionId, liked, addComment, deleteComment]);

  function loadComments() {
    setSeeMore(!seeMore);
  }

  function handleAddComment() {
    setAddComment(!addComment);
  }

  return (
    <div className="App main-container-comments">
      {!seeMore && comment.length != 0 && (
        <>
          <div className="view-comments-container">
            <button className="view-comments" onClick={loadComments}>
              View Comments
            </button>
          </div>
        </>
      )}

      <AddComment
        currentUserID={props.currentUserID}
        handleAddComment={handleAddComment}
        seeMore={seeMore}
        loadComments={loadComments}
        impressionId={props.impressionId}
        buttonValue={'Comment'}
      />

      {seeMore && (
        <div className="comment-list-container">
          {comment.map(comment => (
            <>
              <Comment
                handleDeleteComment={handleDeleteComment}
                handleChildStateChange={handleChildStateChange}
                currentUserID={props.currentUserID}
                comment={comment}
              />
            </>
          ))}
        </div>
      )}

      {seeMore && comment.length != 0 && (
        <>
          <div className="view-comments-container">
            <button className="view-comments" onClick={loadComments}>
              Hide Comments
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Comments;
