import React from 'react';

const Privacy = () => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', maxWidth: '800px', margin: 'auto', lineHeight: '1.6' }}>
      <h1 style={{ textAlign: 'center', color: '#ffffff' }}>Privacy Policy</h1>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>Effective Date: 01/06/2024</p>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>Welcome to Yearbook, developed by the Student Alumni Relations Cell of IIT Bombay. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the application.</p>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>1. Information We Collect</h2>
      <h3 style={{ color: '#ffffff' }}>Personal Information</h3>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>When you sign up, we collect your roll number, password, and college email address for verification purposes. When you create or update your profile, we collect additional information such as your name, profile picture, and any other details you choose to provide.</p>
      
      <h3 style={{ color: '#ffffff' }}>Usage Data</h3>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>We collect information about your interactions with the app, including posts, comments, and polls. We collect information about the pictures you upload and share on the app.</p>
      
      <h3 style={{ color: '#ffffff' }}>Device Information</h3>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>We may collect information about your device, including IP address, device type, operating system, and app version in case of any query from your side.</p>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>2. How We Use Your Information</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>We use the information we collect to:</p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Verify your identity and manage your account.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Provide, maintain, and improve our services.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Personalize your feed based on your interactions and connections.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Communicate with you about updates, new features, and other information.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Ensure the security and integrity of the app.</li>
      </ul>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>3. Sharing Your Information</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>We do not share your personal information with third parties except:</p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>With your consent.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>To comply with legal obligations.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>To protect and defend our rights and property.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>To prevent and investigate possible wrongdoing in connection with the app.</li>
      </ul>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>4. Data Security</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>We implement appropriate technical and organizational measures to protect your personal information against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.</p>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>5. Your Data Rights</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>Depending on your jurisdiction, you may have the right to:</p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Access the personal data we hold about you.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Request the correction or deletion of your personal data.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Object to or restrict our processing of your personal data.</li>
        <li style={{ textAlign: 'center', color: '#ffffff' }}>Request the transfer of your personal data to another service provider.</li>
      </ul>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>6. Third-Party Services</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to read their privacy policies before providing them with your personal information.</p>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>7. Changes to This Privacy Policy</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Effective Date" at the top. You are advised to review this privacy policy periodically for any changes.</p>
      
      <h2 style={{ color: '#ffffff', borderBottom: '2px solid #ddd', paddingBottom: '5px' }}>8. Contact Us</h2>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>If you have any questions about this privacy policy, please contact us at:</p>
      <p style={{ textAlign: 'center', color: '#ffffff' }}>
        <strong>Student Alumni Relations Cell</strong><br />
        IIT Bombay<br />
        Main Gate Rd, IIT Area, Powai, Mumbai, Maharashtra 400076<br />
        Email: <a href="mailto:sarc-iitb@gmail.com" style={{ color: '#0066cc' }}>sarc-iitb@gmail.com</a><br />
        Phone: +91 77348 44289
      </p>
    </div>
  );
};

export default Privacy;
