// Don't go over the name, it handles both posts and impressions

const scrollPosts = {
  page: 1,
};

const scrollImpressions = {
  page: 1,
};

const scrollPostReducer = (state = scrollPosts, action) => {
  switch (action.type) {
    case 'FETCH_POSTS_SCROLL':
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

const scrollPostReducerMinus = (state = scrollPosts, action) => {
  switch (action.type) {
    case 'FETCH_POSTS_SCROLL_MINUS':
      return {
        ...state,
        page: state.page - 1,
      };
    default:
      return state;
  }
};

const scrollImpressionReducer = (state = scrollImpressions, action) => {
  switch (action.type) {
    case 'FETCH_IMPRESSIONS_SCROLL':
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

export default scrollPostReducer;
// export default scrollImpressionReducer;
